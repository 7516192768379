import { Achievement, Reward, useAdminGetAchievementAndWinnersByIdMutation } from '@/graphql/generated/scheme'
import { Dialog, DialogHeader, DialogBody, DialogFooter, Button, Typography } from '@material-tailwind/react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

export default function AchievementWinnersDialog({
	achievementId,
	onClose,
}: {
	achievementId: string
	onClose: () => any
}) {
	const [open, setOpen] = useState(true)
	const [achievement, setAchievement] = useState<Achievement>()
	const [rewardMap, setRewardMap] = useState<Record<string, Reward>>({})
	const [load, setLoad] = useState<boolean>(false)

	const [getAchievementAndWinenrs, { data: dataAchievement }] = useAdminGetAchievementAndWinnersByIdMutation()

	useEffect(() => {
		if (!load) {
			setLoad(true)
			getAchievementAndWinenrs({
				variables: {
					achievementId: achievementId,
				},
			})
		}
	}, [])

	useEffect(() => {
		const achievementData = dataAchievement?.admin?.achievementById.fullAchievement
		if (achievementData) {
			setAchievement(achievementData as Achievement)
			if (achievementData.statisticsByRewards?.triggeredByRewards) {
				const map = {} as any
				achievementData.statisticsByRewards?.triggeredByRewards.forEach(x => {
					map[x!.rewardId] = x
				})
				setRewardMap(map)
			}
		} else if (load) {
			setOpen(false)
			onClose()
		}
	}, [dataAchievement])

	const columns = [
		{ display: 'User ID', key: 'userId' },
		{ display: 'Awarded Date', key: 'awardedDate' },
		{ display: 'From Reward', key: 'fromReward' },
	]

	return (
		<Dialog open={open} size="xl" handler={() => {}}>
			<DialogHeader>Winners of {achievement?.displayName}</DialogHeader>
			<DialogBody className="overflow-x-auto overflow-y-auto max-h-[80svh] p-0" divider>
				{achievement?.statisticsByRewards?.userAchievements ? (
					<table className="w-full min-w-max table-auto text-left">
						<thead>
							<tr>
								{columns.map(el => (
									<th key={el.key} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-normal leading-none opacity-70"
										>
											<span>{el.display}</span>
										</Typography>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{(achievement?.statisticsByRewards?.userAchievements ?? []).map((record, index: number) => {
								const isLast =
									index === (achievement?.statisticsByRewards?.userAchievements?.length ?? 0) - 1
								const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50'

								return (
									<tr key={record?.userInfo?.userId}>
										<td key="userId" className={classes}>
											<Typography
												variant="small"
												color="blue"
												className="font-normal cursor-pointer"
											>
												<NavLink
													target="_blank"
													to={`/dashboard/users/${record?.userInfo?.userId}`}
												>
													@{record?.userInfo?.userName}
												</NavLink>
											</Typography>
										</td>
										<td>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{format(
													new Date(record?.achievementUserInfo?.createdUtc),
													'dd MMM yyyy'
												)}
											</Typography>
										</td>
										<td>
											<Typography
												variant="small"
												color="blue"
												className="font-normal cursor-pointer"
											>
												<NavLink
													target="_blank"
													to={`/dashboard/rewards/${record?.achievementUserInfo?.claimedByRewardId}`}
												>
													{
														rewardMap[record?.achievementUserInfo?.claimedByRewardId]
															?.displayName
													}
												</NavLink>
											</Typography>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				) : (
					<></>
				)}
			</DialogBody>
			<DialogFooter>
				<Button
					variant="text"
					onClick={() => {
						setOpen(false)
						onClose()
					}}
					className="mr-1"
				>
					<span>Close</span>
				</Button>
			</DialogFooter>
		</Dialog>
	)
}
