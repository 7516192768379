import {
	UserCircleIcon,
	ArrowRightOnRectangleIcon,
	UsersIcon,
	QueueListIcon,
	CakeIcon,
} from '@heroicons/react/24/solid'
import { Pie, PieCreate, Users } from '@/pages/dashboard'
import { SignIn, Mfa } from '@/pages/auth'
import User from './pages/dashboard/user'
import WaitingList from './pages/dashboard/wating-list'
import Pies from './pages/dashboard/pies'
import Rewards from './pages/dashboard/rewards'
import RewardPage from './pages/dashboard/reward'
import UserCreate from './pages/dashboard/user-create'
import Achievements from './pages/dashboard/achievements'
import AchievementPage from './pages/dashboard/achievement'
import ExternalApplications from './pages/dashboard/external-applications'
import ExternalApplicationPage from './pages/dashboard/external-application'
import PieRewardList from './pages/dashboard/pie-rewards-list'

const icon = {
	className: 'w-5 h-5 text-inherit',
}

export const routes = [
	{
		layout: 'dashboard',
		pages: [
			{
				icon: <UsersIcon {...icon} />,
				name: 'users',
				path: '/users',
				element: <Users />,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'user',
				path: '/users/new',
				element: <UserCreate />,
				hidden: true,
			},
			{
				icon: <UserCircleIcon {...icon} />,
				name: 'user',
				path: '/users/:id',
				element: <User />,
				hidden: true,
			},
			{
				icon: <QueueListIcon {...icon} />,
				name: 'waiting list',
				path: '/waiting-list',
				element: <WaitingList />,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'pies',
				path: '/pies',
				element: <Pies />,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'pie',
				path: '/pies/new',
				element: <PieCreate />,
				hidden: true,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'pie',
				path: '/pies/:id',
				element: <Pie />,
				hidden: true,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'pie rewards list',
				path: '/pies/:pieId/rewards-list',
				element: <PieRewardList />,
				hidden: true,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'rewards',
				path: '/rewards',
				element: <Rewards />,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'reward',
				path: '/rewards/new',
				element: <RewardPage />,
				hidden: true,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'reward',
				path: '/rewards/:id',
				element: <RewardPage />,
				hidden: true,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'achievements',
				path: '/achievements',
				element: <Achievements />,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'achievement',
				path: '/achievements/new',
				element: <AchievementPage />,
				hidden: true,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'achievement',
				path: '/achievements/:id',
				element: <AchievementPage />,
				hidden: true,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'external applications',
				path: '/xapps',
				element: <ExternalApplications />,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'external application',
				path: '/xapps/new',
				element: <ExternalApplicationPage />,
				hidden: true,
			},
			{
				icon: <CakeIcon {...icon} />,
				name: 'external application',
				path: '/xapps/:id',
				element: <ExternalApplicationPage />,
				hidden: true,
			},
		],
	},
	{
		layout: 'auth',
		pages: [
			{
				icon: <ArrowRightOnRectangleIcon {...icon} />,
				name: 'sign in',
				path: '/sign-in',
				element: <SignIn />,
				hidden: true,
			},
			{
				icon: <ArrowRightOnRectangleIcon {...icon} />,
				name: 'mfa',
				path: '/mfa',
				element: <Mfa />,
				hidden: true,
			},
		],
	},
]

export default routes
