import {
	Card,
	CardBody,
	Typography,
	Button,
	Spinner,
	IconButton,
	Input,
	Alert,
	Select,
	Option,
	Textarea,
	Switch,
	Dialog,
	DialogHeader,
	DialogBody,
	DialogFooter,
} from '@material-tailwind/react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
	AchievementBadgeConditionArgsInput,
	AchievementCategory,
	LocationUnitLengthType,
	OwnSlicesConditionArgsInput,
	RedeemInviteLinkArgsInput,
	RedeemLocationInfoArgsInput,
	RedeemPromoCodeArgsInput,
	RedeemQrCodeArgsInput,
	RedeemType,
	RedeemUserContactInfoArgsInput,
	Reward,
	RewardCategory,
	RewardType,
	UploadedAssetTarget,
	WinnersChosenOption,
	WinningBoostType,
	useAchievementPresignUploadUrlMutation,
	useAdminDeleteRewardMutation,
	useAdminGetRewardLazyQuery,
	useAdminRecoverRewardMutation,
	useCheckRewardSlugAvailability2LazyQuery,
	useCreateOrUpdateAchievementMutation,
	useCreateOrUpdateRewardMutation,
	useCreateOrUpdateUploadedAssetMutation,
	useGenerateUserDocumentUploadUrlMutation,
	useRewardPresignUploadUrlMutation,
	useSetBonusAchivementsMutation,
} from '@/graphql/generated/scheme'
import isUrlHttp from 'is-url-http'
import mime from 'mime'
import urlParse from 'url-parse'
import * as uploadService from '../../services/upload'
import { slugify, toCamelCase } from '@/utils/common'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { camelCase, debounce, upperFirst } from 'lodash'
import { trueErrorMessage } from '@/utils/error'
import { PieSearchInput } from './pie-search-input'
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import DateTimePicker from 'react-datetime-picker'
import moment from 'moment'
import {
	COUNTRY_DATA,
	DEFAULT_EXTENDED_MINUTES,
	DEFAULT_INFORMATION,
	DEFAULT_MAP_COORDS,
	EXCLUDE_COUNTRIES,
	MEASURING_LENGTH_UNITS,
	MEDIA_FOLDER,
} from '@/utils/constants'
import { v4 as uuidv4 } from 'uuid'
import RPI from 'react-phone-input-2'
import useReactIpLocation from 'react-ip-details'
import { AchievementSearchInput } from './achievement-search-input'
import toast from 'react-hot-toast'
import { NumericFormat } from 'react-number-format'
import { GoogleMap, useJsApiLoader, MarkerF, CircleF, Autocomplete } from '@react-google-maps/api'

// @ts-ignore
const ReactPhoneInput = RPI.default ?? RPI
const mapLibraries = ['places']

export function RewardPage() {
	//#region vars
	const REQUIREMENTS = [
		{ name: '# of slices from your Pie', value: 'own-slices', limit: 1 },
		{ name: '# of slices from another Pie', value: 'own-slices-other', limit: 1 },
		{ name: 'Achievement', value: 'achievement-badge', limit: 0 },
	]

	const TIME_FRAME = [
		{ name: 'After end date', value: WinnersChosenOption.AfterEndDate },
		{ name: 'After start date', value: WinnersChosenOption.AfterStartDate },
	]

	const REWARD_CATEGORIES = [
		{ name: 'Invite', value: RewardCategory.Invite },
		{ name: 'Event', value: RewardCategory.AccessPass },
		{ name: 'Vip Pass', value: RewardCategory.VipPass },
		{ name: 'Race', value: RewardCategory.Race },
		{ name: 'Meet up', value: RewardCategory.MeetUp },
		{ name: 'Coupon', value: RewardCategory.Coupon },
		{ name: 'Merchandise/ Give-a-way', value: RewardCategory.MerchandiseGiveAWay },
		{ name: 'Raffle', value: RewardCategory.Raffle },
		{ name: 'Community', value: RewardCategory.Community },
		{ name: 'Exclusive Content', value: RewardCategory.ExclusiveContent },
		{ name: 'Other', value: RewardCategory.Other },
	]

	const ACHIVEMENT_CATEGORIES = [
		{ name: 'Launch Day supporter', value: AchievementCategory.LaunchDaySupporter },
		{ name: 'OG Supporter', value: AchievementCategory.OgSupporter },
		{ name: 'Participation Badge', value: AchievementCategory.ParticipationBadge },
		{ name: 'Event badge', value: AchievementCategory.EventBadge },
		{ name: 'Brand badge', value: AchievementCategory.BrandBadge },
		{ name: 'Product Badge', value: AchievementCategory.ProductBadge },
		{ name: 'Community Badge', value: AchievementCategory.CommunityBadge },
		{ name: 'Tier Badge Emerald', value: AchievementCategory.TierBadgeEmerald },
		{ name: 'Tier Badge Ruby', value: AchievementCategory.TierBadgeRuby },
		{ name: 'Tier Badge Diamond', value: AchievementCategory.TierBadgeDiamond },
		{ name: 'Other', value: AchievementCategory.Other },
	]

	const REDEEM_TYPES = [
		{ name: 'Promo Code', value: RedeemType.PromoCode },
		{ name: 'Access Pass', value: RedeemType.QrCode },
		{ name: 'Invite Link', value: RedeemType.InviteLink },
		{ name: 'Location Info', value: RedeemType.LocationInfo },
		{ name: 'User Contact Info', value: RedeemType.UserContactInfo },
	]

	const detailsDiplayNameScheme = Yup.string().required('Reward Name is invalid. Please try again.')
	const detailsSchema = Yup.object().shape({
		slug: Yup.string().required('Reward Slug is invalid. Please try again.'),
		displayName: detailsDiplayNameScheme,
		bio: Yup.string()
			.max(250000, 'Description is maximum 250.000 characters.')
			.required('Reward Bio is invalid. Please try again.'),
		type: Yup.string().required('Reward Type is invalid. Please try again.'),
		category: Yup.string().required('Reward Type is invalid. Please try again.'),
		avatarUrl: Yup.string().required('Reward Avatar is invalid. Please try again.'),
		ageLimit: Yup.number()
			.nullable()
			.transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
		maxWinners: Yup.number()
			.nullable()
			.transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
	})
	const requirementSchema = Yup.object().shape({
		type: Yup.string()
			.required('Requirement type is required')
			.oneOf(
				REQUIREMENTS.map((item: any) => item.value),
				'Requirement type is required'
			),
		amount: Yup.number().when(['type'], {
			is: (type: string) => type === 'own-slices' || type === 'own-slices-other',
			then: schema => schema.required('Number of slices is required').typeError('You must specify a number'),
			otherwise: schema => schema.optional(),
		}),
		pieId: Yup.string().when(['type'], {
			is: (type: string) => type === 'own-slices-other',
			then: schema => schema.required('Pie ID is required'),
			otherwise: schema => schema.optional(),
		}),
		achievementIds: Yup.array().when(['type'], {
			is: (type: string) => type === 'achievement-badge',
			then: schema => schema.min(1, 'Achievement badge is required. Please try again.'),
			otherwise: schema => schema.optional(),
		}),
	})
	const requirementsSchema = Yup.object().shape({
		requirements: Yup.array().of(requirementSchema),
	})
	const timeFrameSchema = Yup.object().shape({
		timeFrame: Yup.string()
			.required('Requirement time frame is required')
			.oneOf(
				TIME_FRAME.map((item: any) => item.value),
				'Requirement time frame is required'
			),
		startDate: Yup.date().when(['timeFrame'], {
			is: (timeFrame: string) => timeFrame === 'date-range',
			then: schema =>
				schema
					.required('Start Date is a required field')
					.typeError('Reward start date is invalid. Please try again.'),
			otherwise: schema => schema.nullable().optional(),
		}),
		endDate: Yup.date()
			.required('End Date is a required field')
			.typeError('Reward end date is invalid. Please try again.'),
		expiresAt: Yup.date().nullable().optional(),
	})
	const configurationsSchema = Yup.object().shape({
		canShareByParticipants: Yup.boolean(),
		needNewSlices: Yup.boolean(),
		optInParticipantPrivacyPolicy: Yup.boolean(),
		participantPrivacyPolicyContent: Yup.string().when(['optInParticipantPrivacyPolicy', 'policyFileUrl'], {
			is: (optInParticipantPrivacyPolicy: boolean, policyFileUrl: string) =>
				optInParticipantPrivacyPolicy && !policyFileUrl,
			then: schema => schema.required('Privacy Policy Description is required'),
			otherwise: schema => schema.nullable().optional(),
		}),
		participantPrivacyPolicyTitle: Yup.string().when(['optInParticipantPrivacyPolicy'], {
			is: (optInParticipantPrivacyPolicy: boolean) => optInParticipantPrivacyPolicy,
			then: schema => schema.required('Privacy Policy Title is required'),
			otherwise: schema => schema.nullable().optional(),
		}),
		policyFileUrl: Yup.string().nullable().optional(),
		enableRewardLocation: Yup.boolean(),
		rewardGeoRangeConditionArgAddress: Yup.string().when(['enableRewardLocation'], {
			is: (enableRewardLocation: boolean) => enableRewardLocation,
			then: schema => schema.required('Your address is invalid. Please try again.'),
			otherwise: schema => schema.nullable().optional(),
		}),
		rewardGeoRangeConditionArgLatitude: Yup.number().when(['enableRewardLocation'], {
			is: (enableRewardLocation: boolean) => enableRewardLocation,
			then: schema => schema.required('Your latitude is invalid. Please try again.'),
			otherwise: schema => schema.nullable().optional(),
		}),
		rewardGeoRangeConditionArgLongitude: Yup.number().when(['enableRewardLocation'], {
			is: (enableRewardLocation: boolean) => enableRewardLocation,
			then: schema => schema.required('Your longitude is invalid. Please try again.'),
			otherwise: schema => schema.nullable().optional(),
		}),
		rewardGeoRangeConditionArgIsActive: Yup.boolean().optional(),
		rewardGeoRangeConditionArgRadius: Yup.number().when(['enableRewardLocation'], {
			is: (enableRewardLocation: boolean) => enableRewardLocation,
			then: schema =>
				schema.required('Your radius is invalid. Please try again.').typeError('You must specify a number'),
			otherwise: schema => schema.optional(),
		}),
		rewardGeoRangeConditionArgLocationUnitLengthType: Yup.string().when(['enableRewardLocation'], {
			is: (enableRewardLocation: boolean) => enableRewardLocation,
			then: schema =>
				schema.oneOf(Object.values(LocationUnitLengthType)).required('Your unit is invalid. Please try again.'),
			otherwise: schema => schema.nullable().optional(),
		}),
	})
	const redemptionSchema = Yup.object().shape({
		individualApiUrl: Yup.string().optional(),
		notes: Yup.string().required('Reward Redemption Notes is invalid. Please try again.'),
		redeemDescription: Yup.string(),
		redeemCode: Yup.string().nullable(),
		redeemType: Yup.string().required('Reward Redemption Redeem Type is invalid. Please try again.'),
		promoCode: Yup.string().when(['redeemType'], {
			is: (redeemType: string) => redeemType === RedeemType.PromoCode,
			then: schema => schema.required('Promo Code is required'),
			otherwise: schema => schema.nullable().optional(),
		}),
		qrCode: Yup.string().when(['redeemType'], {
			is: (redeemType: string) => redeemType === RedeemType.QrCode,
			then: schema => schema.required('Qr Code is required'),
			otherwise: schema => schema.nullable().optional(),
		}),
		inviteLink: Yup.string().when(['redeemType'], {
			is: (redeemType: string) => redeemType === RedeemType.InviteLink,
			then: schema => schema.required('Invite Link is required'),
			otherwise: schema => schema.nullable().optional(),
		}),
		country: Yup.string().when(['redeemType'], {
			is: (redeemType: string) => redeemType === RedeemType.LocationInfo,
			then: schema => schema.required('Location Information Country is required'),
			otherwise: schema => schema.nullable().optional(),
		}),
		city: Yup.string().when(['redeemType'], {
			is: (redeemType: string) => redeemType === RedeemType.LocationInfo,
			then: schema => schema.required('Location Information City is required'),
			otherwise: schema => schema.nullable().optional(),
		}),
		region: Yup.string().when(['redeemType'], {
			is: (redeemType: string) => redeemType === RedeemType.LocationInfo,
			then: schema => schema.required('Location Information State / Province / Region is required'),
			otherwise: schema => schema.nullable().optional(),
		}),
		postalCode: Yup.string().when(['redeemType'], {
			is: (redeemType: string) => redeemType === RedeemType.LocationInfo,
			then: schema => schema.required('Location Information ZIP / Postal Code is required'),
			otherwise: schema => schema.nullable().optional(),
		}),
		address: Yup.string().when(['redeemType'], {
			is: (redeemType: string) => redeemType === RedeemType.LocationInfo,
			then: schema => schema.required('Location Information Street name is required'),
			otherwise: schema => schema.nullable().optional(),
		}),
		phoneNumber: Yup.string().when(['redeemType'], {
			is: (redeemType: string) => redeemType === RedeemType.LocationInfo,
			then: schema => schema.required('Location Information Phone number is required'),
			otherwise: schema => schema.nullable().optional(),
		}),
		applyUserName: Yup.boolean().nullable(),
		applyName: Yup.boolean().nullable(),
		applyEmail: Yup.boolean().nullable(),
		applyPhoneNumber: Yup.boolean().nullable(),
		applyAddress: Yup.boolean().nullable(),
		applySocialMediaAccount: Yup.boolean().nullable(),
		generateQrCode: Yup.boolean(),
	})
	const achievementSchema = Yup.object().shape({
		slug: Yup.string().required(),
		displayName: Yup.string().required('Achievement Name is invalid. Please try again.'),
		bio: Yup.string().required('Achievement Description is invalid. Please try again.'),
		activeFromUtc: Yup.date().optional(),
		activeToUtc: Yup.date().optional(),
		maxSupply: Yup.number().optional(),
		category: Yup.string().required(),
		claimByRewardId: Yup.string().optional(),
		avatarUrl: Yup.string().required(),
		id: Yup.string().optional(),
	})

	const defaultWinningBoosts = [
		{
			key: 'instagram',
			score: -1,
			type: WinningBoostType.SocialConnection,
		},
		{
			key: 'facebook',
			score: -1,
			type: WinningBoostType.SocialConnection,
		},
		{
			key: 'twitter',
			score: -1,
			type: WinningBoostType.SocialConnection,
		},
		{
			key: 'youtube',
			score: -1,
			type: WinningBoostType.SocialConnection,
		},
		{
			key: 'tiktok',
			score: -1,
			type: WinningBoostType.SocialConnection,
		},
		{
			key: 'spotify',
			score: -1,
			type: WinningBoostType.SocialConnection,
		},
		{
			key: 'twitch',
			score: -1,
			type: WinningBoostType.SocialConnection,
		},
	]

	const avatarInput = useRef<HTMLInputElement>(null)
	const achievementAvatarInput = useRef<HTMLInputElement>(null)
	const policiFileInput = useRef<HTMLInputElement>(null)

	//#endregion vars

	//#region hooks
	const [reward, setReward] = useState<Reward>()
	const [rewardId, setRewardId] = useState<string | undefined>()
	const [pieId, setPieId] = useState<string | undefined>()
	const [ownerId, setOwnerId] = useState<string | undefined>()
	const [updating, setUpdating] = useState<boolean>(false)
	const [error, setError] = useState<string>('')
	const [success, setSuccess] = useState<string>('')
	const [newAvatar, setNewAvatar] = useState<{ file: File; data: string } | null>(null)
	const [newAchievementAvatar, setNewAchievementAvatar] = useState<{ file: File; data: string } | null>(null)
	const [newPolicyFile, setNewPolicyFile] = useState<{ file: File } | null>(null)
	const [enableWinningBoosts, setEnableWinningBoosts] = useState<boolean>(false)
	const [winningBoosts, setWinningBoosts] = useState<{ key: string; enabled: boolean; score: number }[]>(
		defaultWinningBoosts.map(x => {
			return {
				key: x.key,
				enabled: false,
				score: x.score,
			}
		})
	)
	const { ipResponse } = useReactIpLocation({ numberToConvert: 100 })
	const [ipCountryCode, setIpCountryCode] = useState<any>(undefined)
	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
		libraries: mapLibraries as any,
	})
	const [geocoder, setGeocoder] = useState<any>(null)
	const [placeResult, setPlaceResult] = useState<any>(null)
	const [mapCenter, setMapCenter] = useState<any>(DEFAULT_MAP_COORDS)

	const [hasBonusAchievement, setHasBonusAchievement] = useState<boolean>(false)
	const [bonusExistingAchievement, setBonusExistingAchievement] = useState<boolean>(false)
	const [achievementAvatarCompleted, setAchievementAvatarCompleted] = useState<boolean>(false)
	const [rewardAvatarCompleted, setRewardAvatarCompleted] = useState<boolean>(false)
	const [policyFileCompleted, setPolicyFileCompleted] = useState<boolean>(false)

	const [availableRewardName, setAvailableRewardName] = useState<any>(null)

	const [canDelete, setCanDelete] = useState<boolean>()
	const [canRecover, setCanRecover] = useState<boolean>()
	const [processing, setProcessing] = useState<boolean>()
	const [confirming, setConfirming] = useState<{
		title: string
		message: string
		callback: (confirmed: boolean) => void
	} | null>(null)

	const navigate = useNavigate()

	const {
		control: controlDetails,
		formState: { dirtyFields: dirtyFieldsDetails },
		reset: resetDetails,
		setValue: setValueDetails,
		watch: watchDetails,
		getValues: getValuesDetails,
	} = useForm({
		resolver: yupResolver(detailsSchema),
		mode: 'onChange',
		defaultValues: {
			type: RewardType.Digital,
		},
	})
	const formMethodsRequirements = useForm({
		resolver: yupResolver(requirementsSchema),
		mode: 'onChange',
		defaultValues: {
			requirements: [],
		},
	})
	const {
		formState: {},
		reset: resetRequirements,
		getValues: getValuesRequirements,
		control: controlRequirements,
		setValue: setValueRequirements,
		trigger: triggerRequirements,
		watch: watchRequirements,
	} = formMethodsRequirements
	const {
		control: controlTimeFrame,
		formState: {},
		reset: resetTimeFrame,
		setValue: setValueTimeFrame,
		watch: watchTimeFrame,
		getValues: getValuesTimeFrame,
	} = useForm({
		resolver: yupResolver(timeFrameSchema),
		mode: 'onChange',
	})
	const {
		control: controlConfigurations,
		formState: {},
		register: registerConfigurations,
		reset: resetConfigurations,
		watch: watchConfigurations,
		getValues: getValuesConfigurations,
		setValue: setValueConfiguration,
	} = useForm({
		resolver: yupResolver(configurationsSchema),
		mode: 'onChange',
		defaultValues: {
			canShareByParticipants: true,
			needNewSlices: false,
			optInParticipantPrivacyPolicy: false,
			enableRewardLocation: false,
			rewardGeoRangeConditionArgIsActive: true,
			rewardGeoRangeConditionArgLocationUnitLengthType: LocationUnitLengthType.Miles,
		},
	})
	const {
		control: controlRedemption,
		formState: {},
		reset: resetRedemption,
		setValue: setValueRedemption,
		watch: watchRedemption,
		trigger: triggerRedemption,
		getValues: getValuesRedemption,
	} = useForm({
		resolver: yupResolver(redemptionSchema),
		mode: 'onChange',
		defaultValues: {
			country: 'US',
			applyUserName: true,
			generateQrCode: false,
		},
	})

	const formMethodsAchievement = useForm({
		resolver: yupResolver(achievementSchema),
		mode: 'onChange',
	})
	const {
		control: controlAchievement,
		formState: { dirtyFields: achievementDirtyFields },
		reset: resetAchievement,
		setValue: setValueAchievement,
		watch: watchAchievement,
		getValues: getValuesAchievement,
	} = formMethodsAchievement

	const params = useParams()
	const rid = params.id
	let isNew = false
	if (rid == undefined) {
		isNew = true
	} else {
		useEffect(() => {
			setRewardId(rid)
		}, [])
	}

	const debounceTypingRewardName = useCallback(
		debounce(nextValue => {
			const { text, isValidated } = nextValue
			if (isValidated && pieId) {
				return checkRewardSlugAvailability({
					variables: { pieId: pieId, rewardSlug: text },
				})
			}
		}, 1000),
		[reward, pieId]
	)

	const [checkRewardSlugAvailability, {}] = useCheckRewardSlugAvailability2LazyQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			const result = data.reward?.slugAvailable2
			if (result.isAvailable) {
				setAvailableRewardName(true)
			} else {
				if (result.objectId === rid) {
					setAvailableRewardName(true)
				} else {
					setAvailableRewardName(false)
				}
			}
		},
		onError: error => {
			setError(trueErrorMessage(error.message))
		},
	})

	const [createOrUpdateReward, {}] = useCreateOrUpdateRewardMutation({
		onCompleted: data => {
			setRewardId(data.reward.createOrUpdate.rewardId)
			if (hasBonusAchievement) {
				const { id, slug, displayName, bio, avatarUrl, activeFromUtc, activeToUtc, maxSupply, category } =
					getValuesAchievement()

				const aid = bonusExistingAchievement ? id : null
				createOrUpdateAchievement({
					variables: {
						id: aid,
						slug,
						displayName,
						bio,
						avatarUrl,
						activeFromUtc,
						activeToUtc,
						maxSupply,
						category,
						claimByRewardId: data.reward.createOrUpdate.rewardId,
						updateToUser: ownerId,
						pieId: pieId,
					} as any,
				})
			} else {
				setBonusAchivements({
					variables: {
						achievementIds: [],
						rewardId: data.reward.createOrUpdate.rewardId,
						doRewardToUser: true,
					},
				}).then(() => {
					toast.success(`Your reward has been saved.`)
					backToRewardsList()
				})
			}
		},
		onError: error => {
			toast.error(trueErrorMessage(error.message))
			setUpdating(false)
		},
	})

	const [createOrUpdateAchievement, {}] = useCreateOrUpdateAchievementMutation({
		onCompleted: data => {
			resetAchievement()
			setBonusAchivements({
				variables: {
					achievementIds: [data.achievement.createOrUpdate.achievementId],
					rewardId: rewardId,
				},
			}).then(() => {
				toast.success(`Your reward has been saved.`)
				backToRewardsList()
			})
		},
		onError: error => {
			toast.error(trueErrorMessage(error.message))
			setUpdating(false)
		},
	})

	const [setBonusAchivements] = useSetBonusAchivementsMutation()

	const [rewardPresignUploadUrl] = useRewardPresignUploadUrlMutation({
		onCompleted: presignUrlData => {
			const signedUrl = presignUrlData.reward.rewardAvatar.presignUploadUrl
			uploadService
				.uploadAvatar({ presignedS3Url: signedUrl, file: newAvatar?.file })
				.then(() => {
					const url = urlParse(signedUrl, true).pathname.slice(1)
					setValueDetails('avatarUrl', url)
					setRewardAvatarCompleted(true)
				})
				.catch(() => {
					setError('Failed to upload achievement avatar')
				})
		},
	})

	const [achievementPresignUploadUrl] = useAchievementPresignUploadUrlMutation({
		onCompleted: presignUrlData => {
			const signedUrl = presignUrlData.achievement.achievementAvatar.presignUploadUrl
			uploadService
				.uploadAvatar({ presignedS3Url: signedUrl, file: newAchievementAvatar?.file })
				.then(() => {
					const url = urlParse(signedUrl, true).pathname.slice(1)
					setValueAchievement('avatarUrl', url)
					setAchievementAvatarCompleted(true)
				})
				.catch(() => {
					setError('Failed to upload achievement avatar')
				})
		},
	})

	const [policyFilePresignUploadUrl] = useGenerateUserDocumentUploadUrlMutation({
		onCompleted: presignUrlData => {
			const signedUrl = presignUrlData.uploaded_asset.presignUploadUrl
			uploadService
				.uploadAvatar({ presignedS3Url: signedUrl, file: newPolicyFile?.file })
				.then(() => {
					const url = urlParse(signedUrl, true).pathname.slice(1)
					setValueConfiguration('policyFileUrl', url)
					createOrUpdatePolicyFileAsset({
						variables: {
							pieId: pieId,
							target: UploadedAssetTarget.Document,
							url: url,
						},
					})
				})
				.catch(() => {
					setError('Failed to upload policy file')
				})
		},
	})

	const [createOrUpdatePolicyFileAsset] = useCreateOrUpdateUploadedAssetMutation({
		onCompleted: () => {
			setPolicyFileCompleted(true)
		},
	})

	useEffect(() => {
		if (!achievementAvatarCompleted || !rewardAvatarCompleted || !policyFileCompleted) {
			return
		}

		const { slug, displayName, bio, avatarUrl, type, category, ageLimit, maxWinners } = getValuesDetails()
		const { requirements } = getValuesRequirements()
		const { timeFrame, startDate, endDate, expiresAt } = getValuesTimeFrame()
		const {
			canShareByParticipants,
			needNewSlices,
			optInParticipantPrivacyPolicy,
			participantPrivacyPolicyContent,
			participantPrivacyPolicyTitle,
			policyFileUrl,
			enableRewardLocation,
			rewardGeoRangeConditionArgAddress,
			rewardGeoRangeConditionArgLatitude,
			rewardGeoRangeConditionArgLongitude,
			rewardGeoRangeConditionArgIsActive,
			rewardGeoRangeConditionArgRadius,
			rewardGeoRangeConditionArgLocationUnitLengthType,
		} = getValuesConfigurations()

		const {
			individualApiUrl,
			notes,
			redeemDescription,
			redeemCode,
			redeemType,
			promoCode,
			qrCode,
			inviteLink,
			country,
			city,
			region,
			postalCode,
			address,
			phoneNumber,
			applyUserName,
			applyName,
			applyEmail,
			applyPhoneNumber,
			applyAddress,
			applySocialMediaAccount,
			generateQrCode,
		} = getValuesRedemption()

		const generateQrCodeValue = generateQrCode ?? false
		const ownSlicesConditions: OwnSlicesConditionArgsInput[] = []
		const achievementBadgeConditions: AchievementBadgeConditionArgsInput[] = []
		requirements?.map((item: any) => {
			if (item.type === REQUIREMENTS[0].value) {
				ownSlicesConditions.push({
					pieId: pieId,
					amount: strToFloat(item.amount),
				} as OwnSlicesConditionArgsInput)
			}
			if (item.type === REQUIREMENTS[2].value) {
				if (item.achievementIds) {
					item.achievementIds.forEach((achievementId: string) => {
						achievementBadgeConditions.push({
							achievementId: achievementId,
						})
					})
				}
			}
			if (item.type === REQUIREMENTS[1].value) {
				ownSlicesConditions.push({
					pieId: item.pieId,
					amount: strToFloat(item.amount),
				} as OwnSlicesConditionArgsInput)
			}
			return item
		})

		const redeemPromoCodeArgs =
			redeemType === RedeemType.PromoCode
				? {
						promoCode,
						generateQrCode: generateQrCodeValue,
				  }
				: undefined
		const redeemQRCodeArgs =
			redeemType === RedeemType.QrCode
				? {
						qrCode,
				  }
				: undefined
		const redeemInviteLinkArgs =
			redeemType === RedeemType.InviteLink
				? {
						inviteLink,
						generateQrCode: generateQrCodeValue,
				  }
				: undefined
		const redeemLocationInfoArgs =
			redeemType === RedeemType.LocationInfo
				? {
						address: {
							countryCode: country || 'US',
							city: city || '',
							region: region,
							postalCode: postalCode,
							address: address || '',
						},
						phoneNumber: phoneNumber || '',
						generateQrCode: generateQrCodeValue,
				  }
				: undefined
		const redeemUserContactInfoArgs =
			redeemType === RedeemType.UserContactInfo
				? {
						applyUserName,
						applyName,
						applyEmail,
						applyPhoneNumber,
						applyAddress,
						applySocialMediaAccount,
						generateQrCode: generateQrCodeValue,
				  }
				: undefined

		createOrUpdateReward({
			variables: {
				id: rewardId,
				pieId: pieId,
				slug,
				displayName,
				bio,
				avatarUrl,
				individualApiUrl: individualApiUrl || '',
				type: type || RewardType.Digital,
				category: category,
				notes,
				redeemDescription: redeemDescription ?? '',
				redeemCode: redeemCode || '',
				ageLimit: strToInt(ageLimit),
				maxWinners: strToInt(maxWinners),
				redeemType,
				redeemPromoCodeArgs,
				redeemQRCodeArgs,
				redeemInviteLinkArgs,
				redeemLocationInfoArgs,
				redeemUserContactInfoArgs,
				expiresAt,
				startDate,
				endDate,
				ownSlicesConditions,
				achievementBadgeConditions,
				canShareByParticipants,
				needNewSlices,
				optInParticipantPrivacyPolicy,
				participantPrivacyPolicyContent,
				winningBoosts: winningBoosts.map(({ key, score }) => {
					return {
						key,
						score: strToInt(score),
						type: WinningBoostType.SocialConnection,
					}
				}),
				doRewardToUser: !hasBonusAchievement,
				policyFileUrl: policyFileUrl,
				participantPrivacyPolicyTitle: participantPrivacyPolicyTitle,
				winnersChosenOption: timeFrame,
				rewardGeoRangeConditionArg: enableRewardLocation
					? {
							address: rewardGeoRangeConditionArgAddress,
							latitude: rewardGeoRangeConditionArgLatitude,
							longitude: rewardGeoRangeConditionArgLongitude,
							isActive: rewardGeoRangeConditionArgIsActive,
							radius: rewardGeoRangeConditionArgRadius,
							locationUnitLengthType: rewardGeoRangeConditionArgLocationUnitLengthType,
					  }
					: null,
			} as any,
		})
	}, [achievementAvatarCompleted, rewardAvatarCompleted, policyFileCompleted])

	const [adminGetRewardQuery, { data: dataReward, loading: loadingReward, error: loadRewardError }] =
		useAdminGetRewardLazyQuery()

	useEffect(() => {
		if (!isNew) {
			adminGetRewardQuery({
				variables: {
					rewardId: rid,
				},
			})
		}
	}, [])

	useEffect(() => {
		const rewardData = dataReward?.admin?.reward?.byId

		if (rewardData) {
			setReward({ ...rewardData } as Reward)
			setPieId(rewardData.pie?.pieId)
			setOwnerId(rewardData.pie?.createdBy?.userId)
			resetDetails({
				ageLimit: rewardData.ageLimit,
				avatarUrl: rewardData.avatarUrl,
				bio: rewardData.bio ?? '',
				category: rewardData.category!,
				displayName: rewardData.displayName,
				maxWinners: rewardData.maxWinners,
				slug: rewardData.slug,
				type: rewardData.type,
			})

			const requirements: any[] = []
			rewardData.ownSlicesConditions?.nodes.forEach((ownsSlicesCondition: any) => {
				requirements.push({
					type:
						ownsSlicesCondition.pie.pieId === rewardData.pie?.pieId
							? REQUIREMENTS[0].value
							: REQUIREMENTS[1].value,
					amount: ownsSlicesCondition.amount,
					pieId: ownsSlicesCondition.pie.pieId,
					id: ownsSlicesCondition.id,
				})
			})
			rewardData.achievementBadgeConditions?.nodes.forEach((achievementBadgeCondition: any) => {
				requirements.push({
					type: REQUIREMENTS[2].value,
					achievementIds: [achievementBadgeCondition.achievement.achievementId],
					id: achievementBadgeCondition.id,
				})
			})

			resetRequirements({
				requirements: requirements,
			})

			const winningBoosts = defaultWinningBoosts.map(x => {
				const settedBoosts = rewardData?.winningBoosts?.nodes?.find(w => w.key == x.key)
				return {
					enabled: settedBoosts && settedBoosts.score > -1 ? true : false,
					key: x.key,
					score: Number.parseInt(settedBoosts ? settedBoosts.score : x.score),
				}
			})

			setEnableWinningBoosts(winningBoosts.some(x => x.enabled))
			setWinningBoosts(winningBoosts)

			resetTimeFrame({
				endDate: rewardData.endDate ? new Date(rewardData.endDate) : undefined,
				expiresAt: rewardData.expiresAt ? new Date(rewardData.expiresAt) : undefined,
				startDate: rewardData.startDate ? new Date(rewardData.startDate) : undefined,
				timeFrame: rewardData.winnersChosenOption,
			})

			resetConfigurations({
				canShareByParticipants: rewardData.canShareByParticipants,
				needNewSlices: rewardData.needNewSlices,
				optInParticipantPrivacyPolicy: rewardData.optInParticipantPrivacyPolicy,
				participantPrivacyPolicyContent: rewardData.participantPrivacyPolicyContent ?? undefined,
				participantPrivacyPolicyTitle: rewardData.participantPrivacyPolicyTitle ?? undefined,
				policyFileUrl: rewardData.policyFileUrl ?? undefined,
				enableRewardLocation: !!rewardData.geoRangeCondition,
				rewardGeoRangeConditionArgIsActive: rewardData.geoRangeCondition?.isActive || true,
				rewardGeoRangeConditionArgLocationUnitLengthType:
					rewardData.geoRangeCondition?.locationUnitLengthType || LocationUnitLengthType.Miles,
				...(rewardData.geoRangeCondition
					? {
							rewardGeoRangeConditionArgAddress: rewardData.geoRangeCondition.address ?? '',
							rewardGeoRangeConditionArgLatitude: rewardData.geoRangeCondition.latitude,
							rewardGeoRangeConditionArgLongitude: rewardData.geoRangeCondition.longitude,
							rewardGeoRangeConditionArgRadius: rewardData.geoRangeCondition.radius,
					  }
					: {}),
			})

			if (rewardData.geoRangeCondition) {
				setMapCenter({
					lat: rewardData.geoRangeCondition.latitude,
					lng: rewardData.geoRangeCondition.longitude,
				})
			}

			resetRedemption({
				notes: rewardData.notes ?? undefined,
				redeemDescription: rewardData.redeemDescription ?? '',
				individualApiUrl: rewardData.individualApiUrl,
				redeemCode: rewardData.redeemCode,
				redeemType: rewardData.redeemType ?? undefined,
			})

			if (rewardData.redeemCode) {
				if (rewardData.redeemType === RedeemType.PromoCode) {
					const redeemPromoCodeArgs = JSON.parse(
						rewardData.redeemCode,
						toCamelCase
					) as RedeemPromoCodeArgsInput
					setValueRedemption('promoCode', redeemPromoCodeArgs.promoCode)
					setValueRedemption('generateQrCode', redeemPromoCodeArgs.generateQrCode)
				}

				if (rewardData.redeemType === RedeemType.QrCode) {
					const redeemQrCodeArgs = JSON.parse(rewardData.redeemCode, toCamelCase) as RedeemQrCodeArgsInput
					if (redeemQrCodeArgs.qrCode) {
						setValueRedemption('qrCode', redeemQrCodeArgs.qrCode)
					} else {
						generateCode('qrCode')
					}
				}

				if (rewardData.redeemType === RedeemType.InviteLink) {
					const redeemInviteLinkArgs = JSON.parse(
						rewardData.redeemCode,
						toCamelCase
					) as RedeemInviteLinkArgsInput
					setValueRedemption('inviteLink', redeemInviteLinkArgs.inviteLink)
					setValueRedemption('generateQrCode', redeemInviteLinkArgs.generateQrCode)
				}

				if (rewardData.redeemType === RedeemType.LocationInfo) {
					const redeemLocationInfoArgs = JSON.parse(
						rewardData.redeemCode,
						toCamelCase
					) as RedeemLocationInfoArgsInput
					setValueRedemption('country', redeemLocationInfoArgs.address.countryCode || 'US')
					setValueRedemption('city', redeemLocationInfoArgs.address.city)
					setValueRedemption('region', redeemLocationInfoArgs.address.region || undefined)
					setValueRedemption('postalCode', redeemLocationInfoArgs.address.postalCode || undefined)
					setValueRedemption('address', redeemLocationInfoArgs.address.address)
					setValueRedemption('phoneNumber', redeemLocationInfoArgs.phoneNumber)
					setValueRedemption('generateQrCode', redeemLocationInfoArgs.generateQrCode)
				}

				if (rewardData.redeemType === RedeemType.UserContactInfo) {
					const redeemUserContactInfoArgs = JSON.parse(
						rewardData.redeemCode,
						toCamelCase
					) as RedeemUserContactInfoArgsInput
					setValueRedemption('applyUserName', redeemUserContactInfoArgs.applyUserName)
					setValueRedemption('applyName', redeemUserContactInfoArgs.applyName)
					setValueRedemption('applyEmail', redeemUserContactInfoArgs.applyEmail)
					setValueRedemption('applyPhoneNumber', redeemUserContactInfoArgs.applyPhoneNumber)
					setValueRedemption('applyAddress', redeemUserContactInfoArgs.applyAddress)
					setValueRedemption('applySocialMediaAccount', redeemUserContactInfoArgs.applySocialMediaAccount)
				}

				triggerRedemption('promoCode')
			}

			if (rewardData.bonusAchievements?.nodes.length) {
				setHasBonusAchievement(true)
				setBonusExistingAchievement(true)
				const bonusAchievement = rewardData.bonusAchievements.nodes[0]
				resetAchievement({
					avatarUrl: bonusAchievement.avatarUrl,
					bio: bonusAchievement.bio,
					category: bonusAchievement.category!,
					displayName: bonusAchievement.displayName,
					slug: bonusAchievement.slug,
					id: bonusAchievement.achievementId,
				})
			}

			setCanDelete(!rewardData.isDeleted)
			setCanRecover(rewardData.isDeleted)
		} else if (!loadingReward === false) {
			backToRewardsList()
		}
	}, [dataReward])

	useEffect(() => {
		if (ipResponse) {
			setIpCountryCode(ipResponse.country_code.toLowerCase())
		} else {
			setIpCountryCode(ipResponse?.country_code?.toLowerCase() || 'us')
		}
	}, [ipResponse])

	useEffect(() => {
		if (achievementDirtyFields['category']) {
			const url = watchAchievement('category')
				? `${MEDIA_FOLDER.achievementAvatars}/${upperFirst(camelCase(watchAchievement('category')))}.png`
				: ''

			setNewAchievementAvatar(null)
			setValueAchievement('avatarUrl', url)
		}
	}, [watchAchievement('category'), achievementDirtyFields])

	useEffect(() => {
		if (dirtyFieldsDetails['category']) {
			const url = watchDetails('category')
				? `${MEDIA_FOLDER.rewardAvatars}/${upperFirst(camelCase(watchDetails('category')))}.png`
				: ''

			setNewAvatar(null)
			setValueDetails('avatarUrl', url, {
				shouldValidate: true,
			})
		}
	}, [watchDetails('category'), dirtyFieldsDetails])

	useEffect(() => {
		setValueDetails('slug', slugify(watchDetails('displayName') || ''))
	}, [watchDetails('displayName')])

	useEffect(() => {
		setValueAchievement('slug', slugify(watchAchievement('displayName') || ''))
	}, [watchAchievement('displayName')])

	const [deleteRewardMutation] = useAdminDeleteRewardMutation()
	const [recoverRewardMutation] = useAdminRecoverRewardMutation()
	//#endregion hooks

	//#region functions
	const backToRewardsList = () => {
		navigate('/dashboard/rewards')
	}

	const resolveAvatarUrl = (src: string) => {
		let url = src
		if (!url) {
			url = DEFAULT_INFORMATION.rewardImageUrl
		} else {
			if (!isUrlHttp(url) && url.indexOf('data:') === -1) {
				url = `${process.env.REACT_APP_MEDIA_URL}/${src}`
			}
		}

		return url
	}

	const handleKeyUp = async (e: any) => {
		const { value } = e.target
		const slug = slugify(value || '')
		setAvailableRewardName(null)
		const valid = await detailsDiplayNameScheme.isValid(value)
		debounceTypingRewardName({ text: slug, isValidated: valid })
	}

	const onAvatarSelected = (files: FileList | null) => {
		if (!files || !files.length) {
			return
		}
		var reader = new FileReader()
		reader.onload = function () {
			var dataURL = reader.result
			setNewAvatar({
				data: dataURL?.toString()!,
				file: files[0],
			})
		}
		reader.readAsDataURL(files[0])
	}

	const onAchievementAvatarSelected = (files: FileList | null) => {
		if (!files || !files.length) {
			return
		}
		var reader = new FileReader()
		reader.onload = function () {
			var dataURL = reader.result
			setNewAchievementAvatar({
				data: dataURL?.toString()!,
				file: files[0],
			})
		}
		reader.readAsDataURL(files[0])
	}

	const onPolicyFileSelected = (files: FileList | null) => {
		if (!files || !files.length) {
			return
		}

		setNewPolicyFile({ file: files[0] })
	}

	const generateCode = (fieldName: any) => {
		const prefixCode = Math.random().toString(36).slice(2)
		const suffixCode = uuidv4().split('-')[0]
		setValueRedemption(fieldName, `${prefixCode}${suffixCode}`.toUpperCase(), { shouldValidate: true })
	}

	const save = () => {
		setError('')
		setSuccess('')

		if (newAchievementAvatar && newAchievementAvatar.file) {
			const file = newAchievementAvatar.file
			const { type, size } = file
			const extension = mime.getExtension(type) as string
			achievementPresignUploadUrl({
				variables: {
					contentLength: size,
					contentType: type,
					extension: extension,
				},
			})
		} else {
			setAchievementAvatarCompleted(true)
		}

		if (newAvatar && newAvatar.file) {
			const file = newAvatar?.file
			const { type, size } = file
			const extension = mime.getExtension(type) as string
			rewardPresignUploadUrl({
				variables: {
					contentLength: size,
					contentType: type,
					extension: extension,
				},
			})
		} else {
			setRewardAvatarCompleted(true)
		}

		if (newPolicyFile && newPolicyFile.file) {
			const file = newPolicyFile?.file
			const { type, size } = file
			const extension = mime.getExtension(type) as string
			policyFilePresignUploadUrl({
				variables: {
					pieId: pieId,
					target: UploadedAssetTarget.Document,
					contentLength: size,
					contentType: type,
					extension: extension,
				},
			})
		} else {
			setPolicyFileCompleted(true)
		}

		setUpdating(true)
	}

	const deleteReward = () => {
		setConfirming({
			title: 'Delete reward confirmation',
			message: 'Are you sure to delete this reward?',
			callback: confirmed => {
				if (confirmed) {
					setProcessing(true)
					deleteRewardMutation({
						variables: {
							rewardId: rewardId,
						},
						onCompleted: () => {
							toast.success(`The reward has been deleted.`)
							setProcessing(true)
							backToRewardsList()
						},
						onError: error => {
							toast.error(trueErrorMessage(error))
							setProcessing(false)
						},
					})
				}
			},
		})
	}

	const recover = () => {
		setConfirming({
			title: 'Recover reward confirmation',
			message: 'Are you sure to recover this reward?',
			callback: confirmed => {
				if (confirmed) {
					setProcessing(true)
					recoverRewardMutation({
						variables: {
							rewardId: rewardId,
						},
						onCompleted: () => {
							toast.success(`The reward has been recover.`)
							setProcessing(true)
							backToRewardsList()
						},
						onError: error => {
							toast.error(trueErrorMessage(error))
							setProcessing(false)
						},
					})
				}
			},
		})
	}

	const strToInt = (value: any) => {
		if (Number.isNaN(value)) {
			return null
		} else {
			return parseInt(value)
		}
	}

	const strToFloat = (value: any) => {
		if (Number.isNaN(value)) {
			return null
		} else {
			return parseFloat(value)
		}
	}

	const handleGeolocate = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				position => {
					const marker = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					}
					setValueConfiguration('rewardGeoRangeConditionArgLatitude', marker.lat, {
						shouldValidate: true,
					})
					setValueConfiguration('rewardGeoRangeConditionArgLongitude', marker.lng, {
						shouldValidate: true,
					})
					setMapCenter(marker)

					if (geocoder) {
						geocoder
							.geocode({ location: marker })
							.then((response: any) => {
								if (response.results[0]) {
									setValueConfiguration(
										'rewardGeoRangeConditionArgAddress',
										response.results[0].formatted_address,
										{
											shouldValidate: true,
										}
									)
								} else {
									console.log('No results found')
								}
							})
							.catch((e: any) => {
								console.log('Geocoder failed due to: ' + e)
							})
					}
				},
				() => {
					console.log('Unable to retrieve your location')
				}
			)
		} else {
			console.log('Geolocation not supported')
		}
	}
	//#endregion functions

	if (!isNew && (rid == undefined || !rid.length)) {
		backToRewardsList()
	}

	if (loadingReward || (!reward && !isNew) || updating || processing) {
		return (
			<div className="flex justify-center items-center py-4">
				<Spinner className="h-12 w-12" />
			</div>
		)
	}

	if (loadRewardError) {
		return (
			<div className="py-4">
				<div className="flex justify-center items-center py-4">
					<Typography color="red">
						Could not load reward information due to server error. Please try again.
					</Typography>
				</div>
				<div className="flex justify-center items-center py-4">
					<NavLink to={'/dashboard/rewards'}>
						<Button variant="outlined" className="flex items-center gap-3">
							Back to Rewards list
						</Button>
					</NavLink>
				</div>
			</div>
		)
	}

	return (
		<>
			{confirming && (
				<Dialog open={!!confirming} handler={() => {}} size="xs">
					<DialogHeader>{confirming.title}</DialogHeader>
					<DialogBody>{confirming.message}</DialogBody>
					<DialogFooter>
						<div className="flex gap-6">
							<Button
								onClick={() => {
									confirming.callback(false)
									setConfirming(null)
								}}
							>
								Cancel
							</Button>
							<Button
								onClick={() => {
									confirming.callback(true)
									setConfirming(null)
								}}
							>
								Confirm
							</Button>
						</div>
					</DialogFooter>
				</Dialog>
			)}
			<Card className="mb-6">
				<CardBody className="p-4">
					{(() => {
						if (error) {
							return (
								<div className="flex w-full flex-col gap-2 mb-2">
									<Alert color="red" onClose={() => setError('')}>
										{error}
									</Alert>
								</div>
							)
						}
						if (success) {
							return (
								<div className="flex w-full flex-col gap-2 mb-2">
									<Alert color="green" onClose={() => setSuccess('')}>
										{success}
									</Alert>
								</div>
							)
						}
					})()}
					<form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
						<div className="mb-1 flex flex-col gap-6">
							<Typography variant="h5" color="blue-gray" className="-mb-3">
								Reward Details
							</Typography>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Pie <span className="text-red-500">*</span>
							</Typography>
							{!isNew && (
								<Input
									crossOrigin={undefined}
									type="text"
									size="lg"
									label="Pie"
									value={reward?.pie?.displayName}
									readOnly={true}
									className="bg-gray-500"
								/>
							)}
							{isNew && (
								<PieSearchInput
									placeholder={'Select a pie'}
									autoSuggestion
									onValueChange={(values: any) => {
										setPieId(values.pieId)
										setOwnerId(values.createdBy?.userId)
									}}
									excludeValues={[]}
								/>
							)}
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Name <span className="text-red-500">*</span>
							</Typography>
							<Controller
								control={controlDetails}
								name="displayName"
								render={({ field }) => (
									<Input
										crossOrigin={undefined}
										size="lg"
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										onChange={field.onChange}
										onKeyUp={handleKeyUp}
										value={field.value}
									/>
								)}
							/>
							{availableRewardName === false && (
								<Typography variant="small" color="red" className="-mt-3 -mb-3">
									Reward name is not available
								</Typography>
							)}
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Type <span className="text-red-500">*</span>
							</Typography>
							<Controller
								control={controlDetails}
								name="category"
								render={({ field }) => (
									<Select
										onChange={(option: any) => field.onChange(option)}
										variant="outlined"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										value={field.value}
									>
										{REWARD_CATEGORIES.map((option: any, index: number) => (
											<Option key={index} value={option.value}>
												{option.name}
											</Option>
										))}
									</Select>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Avatar
							</Typography>
							<div style={{ width: '100%', paddingBottom: '100%', position: 'relative' }}>
								<img
									className="object-cover object-center"
									style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
									src={resolveAvatarUrl(newAvatar?.data ? newAvatar.data : watchDetails('avatarUrl'))}
									alt="reward avatar"
									onClick={() => {
										avatarInput.current?.click()
									}}
								/>
							</div>
							<div className="hidden">
								<input
									ref={avatarInput}
									type="file"
									onChange={event => {
										onAvatarSelected(event.target.files)
									}}
								/>
							</div>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Description <span className="text-red-500">*</span>
							</Typography>
							<Controller
								control={controlDetails}
								name="bio"
								render={({ field }) => (
									<Textarea
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										value={field.value}
										onChange={field.onChange}
									/>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Age Limit
							</Typography>
							<Controller
								control={controlDetails}
								name="ageLimit"
								render={({ field }) => (
									<NumericFormat
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										thousandSeparator={true}
										min={0}
										value={field.value ?? ''}
										onValueChange={(e: any) => {
											field.onChange(e.floatValue)
										}}
										customInput={Input}
										crossOrigin={undefined}
									/>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Max Winner
							</Typography>
							<Controller
								control={controlDetails}
								name="maxWinners"
								render={({ field }) => (
									<NumericFormat
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										thousandSeparator={true}
										min={0}
										value={field.value ?? ''}
										onValueChange={(e: any) => {
											field.onChange(e.floatValue)
										}}
										customInput={Input}
										crossOrigin={undefined}
									/>
								)}
							/>
							<Typography variant="h5" color="blue-gray" className="-mb-3">
								Requirements <span className="text-red-500">*</span>
								<IconButton
									variant="text"
									onClick={() => {
										setValueRequirements('requirements', [
											...(watchRequirements().requirements ?? []),
											{},
										])
										triggerRequirements('requirements')
									}}
								>
									<PlusCircleIcon className="h-8 w-8" />
								</IconButton>
							</Typography>
							{watchRequirements().requirements?.map((req, index) => {
								return (
									<div className="flex flex-col gap-6" key={index}>
										<Typography variant="h6" color="blue-gray" className="-mb-3">
											Requirement #{index + 1}
											<IconButton
												variant="text"
												onClick={() => {
													const reqs = watchRequirements().requirements!
													reqs.splice(index, 1)
													setValueRequirements('requirements', reqs)
													triggerRequirements('requirements')
												}}
											>
												<MinusCircleIcon className="h-8 w-8" />
											</IconButton>
										</Typography>
										<Controller
											control={controlRequirements}
											name={`requirements.${index}.type`}
											render={({ field }) => (
												<Select
													value={field.value}
													onChange={(option: any) => {
														field.onChange(option)
														triggerRequirements(`requirements.${index}.type`)
													}}
													variant="outlined"
													label="Requirement Type"
												>
													{REQUIREMENTS.map((option: any, index: number) => (
														<Option key={index} value={option.value}>
															{option.name}
														</Option>
													))}
												</Select>
											)}
										/>
										{req.type === 'own-slices' && (
											<Controller
												control={controlRequirements}
												name={`requirements.${index}.amount`}
												render={({ field }) => (
													<NumericFormat
														className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
														labelProps={{
															className: 'before:content-none after:content-none',
														}}
														thousandSeparator={true}
														min={0}
														value={field.value ?? ''}
														onValueChange={(e: any) => {
															field.onChange(e.floatValue)
														}}
														customInput={Input}
														crossOrigin={undefined}
													/>
												)}
											/>
										)}
										{req.type === 'own-slices-other' && (
											<>
												{pieId && (
													<Controller
														control={controlRequirements}
														name={`requirements.${index}.pieId`}
														render={({ field }) => (
															<PieSearchInput
																placeholder={'Search pie name'}
																autoSuggestion
																onValueChange={(values: any) => {
																	field.onChange(values.pieId)
																}}
																defaultValue={field.value ?? ''}
																excludeValues={[pieId]}
															/>
														)}
													/>
												)}
												<Controller
													control={controlRequirements}
													name={`requirements.${index}.amount`}
													render={({ field }) => (
														<NumericFormat
															className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
															labelProps={{
																className: 'before:content-none after:content-none',
															}}
															thousandSeparator={true}
															min={0}
															value={field.value ?? ''}
															onValueChange={(e: any) => {
																field.onChange(e.floatValue)
															}}
															customInput={Input}
															crossOrigin={undefined}
														/>
													)}
												/>
											</>
										)}
										{req.type === 'achievement-badge' && (
											<Controller
												control={controlRequirements}
												name={`requirements.${index}.achievementIds.0`}
												render={({ field }) => (
													<AchievementSearchInput
														placeholder={'Search achievement name'}
														autoSuggestion
														suggestWhenEmpty
														onValueChange={(values: any) => {
															field.onChange(values.achievementId)
														}}
														defaultValue={field.value ?? ''}
														userId={ownerId!}
													/>
												)}
											/>
										)}
									</div>
								)
							})}
							<Typography variant="h5" color="blue-gray" className="-mb-3">
								Time Frame
							</Typography>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Winners are chosen <span className="text-red-500">*</span>
							</Typography>
							<Controller
								control={controlTimeFrame}
								name="timeFrame"
								render={({ field }) => (
									<Select
										label="Winners are chosen"
										onChange={(option: any) => field.onChange(option)}
										variant="outlined"
										value={field.value}
									>
										{TIME_FRAME.map((option: any, index: number) => (
											<Option key={index} value={option.value}>
												{option.name}
											</Option>
										))}
									</Select>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Start Date <span className="text-red-500">*</span>
							</Typography>
							<Controller
								control={controlTimeFrame}
								name="startDate"
								render={({ field }) => (
									<div className="custom-react-datetime-picker">
										<DateTimePicker
											className="px-4 py-3 peer w-full h-10 bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border text-sm rounded-[7px] border-blue-gray-200 placeholder-white-50 placeholder:italic placeholder:font-light flex items-center"
											value={field.value}
											onChange={value => {
												const endDate = watchTimeFrame('endDate')
												if (value && endDate && value > new Date(endDate)) {
													setValueTimeFrame('endDate', value)
												}
												if (value && value > new Date()) {
													field.onChange(value)
												} else {
													const extendedDate = moment()
														.add(DEFAULT_EXTENDED_MINUTES, 'minutes')
														.toDate()
													if (value && value <= extendedDate) {
														field.onChange(extendedDate)
													} else {
														field.onChange(value)
													}
												}
											}}
											format="MM/dd/yyyy h:mm aa"
											monthPlaceholder="mm"
											dayPlaceholder="dd"
											yearPlaceholder="yyyy"
											hourPlaceholder="h"
											minutePlaceholder="mm"
											calendarIcon={null}
											clearIcon={null}
											disableClock={true}
											calendarType="gregory"
										/>
									</div>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								End Date <span className="text-red-500">*</span>
							</Typography>
							<Controller
								control={controlTimeFrame}
								name="endDate"
								render={({ field }) => (
									<div className="custom-react-datetime-picker">
										<DateTimePicker
											className="px-4 py-3 peer w-full h-10 bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border text-sm rounded-[7px] border-blue-gray-200 placeholder-white-50 placeholder:italic placeholder:font-light flex items-center"
											value={field.value}
											onChange={value => {
												const expiresAt = watchTimeFrame('expiresAt')
												if (value && expiresAt && value > new Date(expiresAt)) {
													setValueTimeFrame('expiresAt', value)
												}
												if (value && value > new Date()) {
													field.onChange(value)
												} else {
													const extendedDate = moment()
														.add(DEFAULT_EXTENDED_MINUTES, 'minutes')
														.toDate()
													if (value && value <= extendedDate) {
														field.onChange(extendedDate)
													} else {
														field.onChange(value)
													}
												}
											}}
											format="MM/dd/yyyy h:mm aa"
											monthPlaceholder="mm"
											dayPlaceholder="dd"
											yearPlaceholder="yyyy"
											hourPlaceholder="h"
											minutePlaceholder="mm"
											calendarIcon={null}
											clearIcon={null}
											disableClock={true}
											calendarType="gregory"
										/>
									</div>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Expires At <span className="text-red-500">*</span>
							</Typography>
							<Controller
								control={controlTimeFrame}
								name="expiresAt"
								render={({ field }) => (
									<div className="custom-react-datetime-picker">
										<DateTimePicker
											className="px-4 py-3 peer w-full h-10 bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border text-sm rounded-[7px] border-blue-gray-200 placeholder-white-50 placeholder:italic placeholder:font-light flex items-center"
											value={field.value}
											onChange={field.onChange}
											format="MM/dd/yyyy h:mm aa"
											monthPlaceholder="mm"
											dayPlaceholder="dd"
											yearPlaceholder="yyyy"
											hourPlaceholder="h"
											minutePlaceholder="mm"
											calendarIcon={null}
											clearIcon={null}
											disableClock={true}
											calendarType="gregory"
										/>
									</div>
								)}
							/>
							<Typography variant="h5" color="blue-gray" className="-mb-3">
								Configurations
							</Typography>
							<Controller
								control={controlConfigurations}
								name={`canShareByParticipants`}
								render={({ field }) => (
									<Switch
										label={
											<div>
												<Typography color="blue-gray">
													This reward can be shared by participants
												</Typography>
											</div>
										}
										crossOrigin={undefined}
										checked={field.value}
										onChange={field.onChange}
									/>
								)}
							/>
							<Controller
								control={controlConfigurations}
								name={`needNewSlices`}
								render={({ field }) => (
									<Switch
										label={
											<div>
												<Typography color="blue-gray">Users need to own new slices</Typography>
											</div>
										}
										crossOrigin={undefined}
										checked={field.value}
										onChange={field.onChange}
									/>
								)}
							/>
							<Controller
								control={controlConfigurations}
								name={`optInParticipantPrivacyPolicy`}
								render={({ field }) => (
									<Switch
										label={
											<div>
												<Typography color="blue-gray">
													Add my own opt-in policy for participants
												</Typography>
											</div>
										}
										crossOrigin={undefined}
										checked={field.value}
										onChange={field.onChange}
									/>
								)}
							/>
							{watchConfigurations('optInParticipantPrivacyPolicy') && (
								<>
									<Controller
										control={controlConfigurations}
										name="participantPrivacyPolicyTitle"
										render={({ field }) => (
											<Input
												label="Policy Title"
												value={field.value}
												onChange={field.onChange}
												crossOrigin={undefined}
												size="lg"
											/>
										)}
									/>
									<Controller
										control={controlConfigurations}
										name="participantPrivacyPolicyContent"
										render={({ field }) => (
											<Textarea
												label="Policy Description"
												value={field.value}
												onChange={field.onChange}
											/>
										)}
									/>
									<Input
										label="Policy File Url"
										readOnly
										value={
											newPolicyFile && newPolicyFile.file
												? newPolicyFile.file.name
												: watchConfigurations('policyFileUrl')
												? watchConfigurations('policyFileUrl')!
												: 'Select a file'
										}
										crossOrigin={undefined}
										size="lg"
										onClick={() => {
											policiFileInput.current?.click()
										}}
										icon={<i className="fas fa-upload" />}
									/>
									{watchConfigurations('policyFileUrl') && (
										<a
											target="_blank"
											style={{ textDecoration: 'underline' }}
											href={resolveAvatarUrl(watchConfigurations('policyFileUrl')!)}
										>
											Uploaded Policy File
										</a>
									)}
									<div className="hidden">
										<input
											ref={policiFileInput}
											type="file"
											onChange={event => {
												onPolicyFileSelected(event.target.files)
											}}
										/>
									</div>
								</>
							)}
							<Switch
								label={
									<div>
										<Typography color="blue-gray">
											To earn bonus entries, users must link their social media accounts
										</Typography>
									</div>
								}
								crossOrigin={undefined}
								checked={enableWinningBoosts}
								onChange={event => {
									setEnableWinningBoosts(event.target.checked)
								}}
							/>
							{enableWinningBoosts &&
								winningBoosts.map((x, index) => {
									return (
										<div className="mb-1 flex flex-col gap-6" key={index}>
											<Switch
												label={
													<div>
														<Typography color="blue-gray">{x.key}</Typography>
													</div>
												}
												crossOrigin={undefined}
												checked={x.enabled}
												onChange={event => {
													winningBoosts[index].enabled = event.target.checked
													winningBoosts[index].score = x.score > -1 ? x.score : 10
													setWinningBoosts([...winningBoosts])
												}}
											/>
											{x.enabled && (
												<Input
													crossOrigin={undefined}
													type="number"
													min={0}
													max={100}
													step={10}
													size="lg"
													className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
													labelProps={{
														className: 'before:content-none after:content-none',
													}}
													value={x.score ?? ''}
													onChange={event => {
														winningBoosts[index].score = event.target.value
															? Number.parseInt(event.target.value)
															: 0
														setWinningBoosts([...winningBoosts])
													}}
												/>
											)}
										</div>
									)
								})}
							<Controller
								control={controlConfigurations}
								name={`enableRewardLocation`}
								render={({ field }) => (
									<Switch
										label={
											<div>
												<Typography color="blue-gray">Set reward location radius</Typography>
											</div>
										}
										crossOrigin={undefined}
										checked={field.value}
										onChange={field.onChange}
									/>
								)}
							/>
							{watchConfigurations('enableRewardLocation') && isLoaded && (
								<>
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										Location <span className="text-red-500">*</span>
									</Typography>
									<Autocomplete
										onLoad={autocomplete => {
											setPlaceResult(autocomplete)
										}}
										onPlaceChanged={() => {
											if (placeResult) {
												const place = placeResult.getPlace()
												if (place) {
													setValueConfiguration(
														'rewardGeoRangeConditionArgLatitude',
														place.geometry.location.lat(),
														{ shouldValidate: true }
													)
													setValueConfiguration(
														'rewardGeoRangeConditionArgLongitude',
														place.geometry.location.lng(),
														{ shouldValidate: true }
													)
													setMapCenter({
														lat: place.geometry.location.lat(),
														lng: place.geometry.location.lng(),
													})
												}
											}
										}}
									>
										<Input
											crossOrigin={undefined}
											type="text"
											placeholder="Address"
											{...registerConfigurations('rewardGeoRangeConditionArgAddress')}
											icon={<i className="fas fa-location-arrow" onClick={handleGeolocate} />}
										/>
									</Autocomplete>
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										Radius <span className="text-red-500">*</span>
									</Typography>
									<div className="flex">
										<Controller
											control={controlConfigurations}
											name="rewardGeoRangeConditionArgRadius"
											render={({ field }) => (
												<NumericFormat
													label="Distance"
													thousandSeparator={true}
													value={field.value ?? ''}
													onValueChange={(e: any) => {
														field.onChange(e.floatValue)
													}}
													autoComplete="off"
													allowNegative={false}
													decimalScale={3}
													customInput={Input}
													crossOrigin={undefined}
												/>
											)}
										/>
										<Controller
											control={controlConfigurations}
											name="rewardGeoRangeConditionArgLocationUnitLengthType"
											render={({ field }) => (
												<Select
													containerProps={{ className: 'shrink !min-w-0 max-w-[70px]' }}
													onChange={(option: any) => field.onChange(option)}
													variant="outlined"
													label="Unit"
													value={field.value}
												>
													{MEASURING_LENGTH_UNITS.map((option: any, index: number) => (
														<Option key={index} value={option.value}>
															{option.name}
														</Option>
													))}
												</Select>
											)}
										/>
									</div>
									<div className="w-full h-[384px] mt-8 overflow-hidden rounded-lg">
										<GoogleMap
											mapContainerStyle={{
												width: '100%',
												height: '100%',
											}}
											center={mapCenter}
											zoom={15}
											onLoad={() => {
												// This is just an example of getting and using the map instance!!! don't just blindly copy!
												// const bounds = new window.google.maps.LatLngBounds(center)
												// map.fitBounds(bounds)
												const geocoder = new window.google.maps.Geocoder()
												setGeocoder(geocoder)
											}}
											onUnmount={() => {
												setGeocoder(null)
											}}
											options={{
												// SOURCE: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
												streetViewControl: false,
												scaleControl: false,
												mapTypeControl: false,
												zoomControl: false,
											}}
										>
											{/* Child components, such as markers, info windows, etc. */}
											<MarkerF
												position={
													watchConfigurations('rewardGeoRangeConditionArgLatitude') &&
													watchConfigurations('rewardGeoRangeConditionArgLongitude')
														? {
																lat: watchConfigurations(
																	'rewardGeoRangeConditionArgLatitude'
																),
																lng: watchConfigurations(
																	'rewardGeoRangeConditionArgLongitude'
																),
														  }
														: mapCenter
												}
												options={{
													draggable: true,
												}}
												onDrag={e => {
													setValueConfiguration(
														'rewardGeoRangeConditionArgLatitude',
														e.latLng?.lat()
													)
													setValueConfiguration(
														'rewardGeoRangeConditionArgLongitude',
														e.latLng?.lng()
													)
												}}
												onDragEnd={e => {
													if (geocoder) {
														const marker = {
															lat: e.latLng?.lat(),
															lng: e.latLng?.lng(),
														}
														geocoder
															.geocode({ location: marker })
															.then((response: any) => {
																if (response.results[0]) {
																	setValueConfiguration(
																		'rewardGeoRangeConditionArgAddress',
																		response.results[0].formatted_address,
																		{
																			shouldValidate: true,
																		}
																	)
																} else {
																	console.log('No results found')
																}
															})
															.catch((e: any) => {
																console.log('Geocoder failed due to: ' + e)
															})
													}
												}}
											/>
											<CircleF
												center={
													watchConfigurations('rewardGeoRangeConditionArgLatitude') &&
													watchConfigurations('rewardGeoRangeConditionArgLongitude')
														? {
																lat: watchConfigurations(
																	'rewardGeoRangeConditionArgLatitude'
																),
																lng: watchConfigurations(
																	'rewardGeoRangeConditionArgLongitude'
																),
														  }
														: mapCenter
												}
												radius={
													watchConfigurations(
														'rewardGeoRangeConditionArgLocationUnitLengthType'
													) === LocationUnitLengthType.Kilometers
														? (watchConfigurations('rewardGeoRangeConditionArgRadius') ||
																0) * 1000
														: (watchConfigurations('rewardGeoRangeConditionArgRadius') ||
																0) * 1609.344
												}
												options={{
													fillColor: '#58aadb',
													fillOpacity: 0.3,
													strokeWeight: 4,
													strokeColor: '#58aadb',
													clickable: false,
													editable: false,
													zIndex: 1,
												}}
												// onCenterChanged={() => console.log('onCenterChanged')}
												// onRadiusChanged={() => console.log('onRadiusChanged')}
											/>
										</GoogleMap>
									</div>
								</>
							)}
							<Typography variant="h5" color="blue-gray" className="-mb-3">
								Redemption
							</Typography>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Pie Holder Notes <span className="text-red-500">*</span>
							</Typography>
							<Controller
								control={controlRedemption}
								name="notes"
								render={({ field }) => (
									<Textarea
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										value={field.value}
										onChange={field.onChange}
									/>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								How To Redeem
							</Typography>
							<Controller
								control={controlRedemption}
								name="redeemDescription"
								render={({ field }) => (
									<Textarea
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										value={field.value ?? ''}
										onChange={field.onChange}
									/>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Redeem Type <span className="text-red-500">*</span>
							</Typography>
							<Controller
								control={controlRedemption}
								name="redeemType"
								render={({ field }) => (
									<Select
										onChange={(option: any) => field.onChange(option)}
										variant="outlined"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										value={field.value}
									>
										{REDEEM_TYPES.map((option: any, index: number) => (
											<Option key={index} value={option.value}>
												{option.name}
											</Option>
										))}
									</Select>
								)}
							/>
							{watchRedemption('redeemType') == RedeemType.PromoCode && (
								<>
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										Promo Code
									</Typography>
									<Controller
										control={controlRedemption}
										name="promoCode"
										render={({ field }) => (
											<div className="relative flex w-full max-w-[24rem]">
												<Input
													crossOrigin={undefined}
													size="lg"
													className=" !border-t-blue-gray-200 focus:!border-t-gray-900 pr-20"
													labelProps={{
														className: 'before:content-none after:content-none',
													}}
													containerProps={{
														className: 'min-w-0',
													}}
													onChange={field.onChange}
													value={field.value ?? ''}
												/>
												<Button
													size="sm"
													className="!absolute right-1 top-1 rounded"
													onClick={() => generateCode('promoCode')}
												>
													Generate
												</Button>
											</div>
										)}
									/>
								</>
							)}
							{watchRedemption('redeemType') == RedeemType.InviteLink && (
								<>
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										Invite Link
									</Typography>
									<Controller
										control={controlRedemption}
										name="inviteLink"
										render={({ field }) => (
											<Input
												crossOrigin={undefined}
												size="lg"
												className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
												labelProps={{
													className: 'before:content-none after:content-none',
												}}
												onChange={field.onChange}
												value={field.value ?? ''}
											/>
										)}
									/>
									<Controller
										control={controlRedemption}
										name={`generateQrCode`}
										render={({ field }) => (
											<Switch
												label={
													<div>
														<Typography color="blue-gray">
															Generate Access Pass QR codes
														</Typography>
													</div>
												}
												crossOrigin={undefined}
												checked={field.value}
												onChange={field.onChange}
											/>
										)}
									/>
								</>
							)}
							{watchRedemption('redeemType') == RedeemType.LocationInfo && (
								<>
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										Country
									</Typography>
									<Controller
										control={controlRedemption}
										name="country"
										render={({ field }) => (
											<Select
												onChange={(option: any) => field.onChange(option)}
												variant="outlined"
												labelProps={{
													className: 'before:content-none after:content-none',
												}}
												value={field.value}
											>
												{COUNTRY_DATA.map((option: any, index: number) => (
													<Option key={index} value={option.value}>
														{option.name}
													</Option>
												))}
											</Select>
										)}
									/>
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										Street Name
									</Typography>
									<Controller
										control={controlRedemption}
										name="address"
										render={({ field }) => (
											<Input
												crossOrigin={undefined}
												size="lg"
												className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
												labelProps={{
													className: 'before:content-none after:content-none',
												}}
												onChange={field.onChange}
												value={field.value ?? ''}
											/>
										)}
									/>
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										State / Province / Region
									</Typography>
									<Controller
										control={controlRedemption}
										name="region"
										render={({ field }) => (
											<Input
												crossOrigin={undefined}
												size="lg"
												className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
												labelProps={{
													className: 'before:content-none after:content-none',
												}}
												onChange={field.onChange}
												value={field.value ?? ''}
											/>
										)}
									/>
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										City
									</Typography>
									<Controller
										control={controlRedemption}
										name="city"
										render={({ field }) => (
											<Input
												crossOrigin={undefined}
												size="lg"
												className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
												labelProps={{
													className: 'before:content-none after:content-none',
												}}
												onChange={field.onChange}
												value={field.value ?? ''}
											/>
										)}
									/>
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										Zip / Postal Code
									</Typography>
									<Controller
										control={controlRedemption}
										name="postalCode"
										render={({ field }) => (
											<Input
												crossOrigin={undefined}
												size="lg"
												className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
												labelProps={{
													className: 'before:content-none after:content-none',
												}}
												onChange={field.onChange}
												value={field.value ?? ''}
											/>
										)}
									/>
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										Phone Number
									</Typography>
									<Controller
										control={controlRedemption}
										name="phoneNumber"
										render={({ field }) => (
											<ReactPhoneInput
												country={ipCountryCode}
												value={field.value}
												onChange={(phone: any) => {
													const value = phone ? `+${phone}` : undefined
													field.onChange(value as any)
												}}
												onBlur={field.onBlur}
												containerClass="custom-react-phone-input-sign-up"
												excludeCountries={EXCLUDE_COUNTRIES}
											/>
										)}
									/>
									<Controller
										control={controlRedemption}
										name={`generateQrCode`}
										render={({ field }) => (
											<Switch
												label={
													<div>
														<Typography color="blue-gray">
															Generate Access Pass QR codes
														</Typography>
													</div>
												}
												crossOrigin={undefined}
												checked={field.value}
												onChange={field.onChange}
											/>
										)}
									/>
								</>
							)}
							{watchRedemption('redeemType') == RedeemType.UserContactInfo && (
								<>
									<Controller
										control={controlRedemption}
										name={`applyUserName`}
										render={({ field }) => (
											<Switch
												label={
													<div>
														<Typography color="blue-gray">User Name</Typography>
													</div>
												}
												crossOrigin={undefined}
												checked={field.value ?? false}
												onChange={field.onChange}
											/>
										)}
									/>
									<Controller
										control={controlRedemption}
										name={`applyName`}
										render={({ field }) => (
											<Switch
												label={
													<div>
														<Typography color="blue-gray">Name</Typography>
													</div>
												}
												crossOrigin={undefined}
												checked={field.value ?? false}
												onChange={field.onChange}
											/>
										)}
									/>
									<Controller
										control={controlRedemption}
										name={`applyEmail`}
										render={({ field }) => (
											<Switch
												label={
													<div>
														<Typography color="blue-gray">Email</Typography>
													</div>
												}
												crossOrigin={undefined}
												checked={field.value ?? false}
												onChange={field.onChange}
											/>
										)}
									/>
									<Controller
										control={controlRedemption}
										name={`applyPhoneNumber`}
										render={({ field }) => (
											<Switch
												label={
													<div>
														<Typography color="blue-gray">Phone Number</Typography>
													</div>
												}
												crossOrigin={undefined}
												checked={field.value ?? false}
												onChange={field.onChange}
											/>
										)}
									/>
									<Controller
										control={controlRedemption}
										name={`applyAddress`}
										render={({ field }) => (
											<Switch
												label={
													<div>
														<Typography color="blue-gray">Address</Typography>
													</div>
												}
												crossOrigin={undefined}
												checked={field.value ?? false}
												onChange={field.onChange}
											/>
										)}
									/>
									<Controller
										control={controlRedemption}
										name={`applySocialMediaAccount`}
										render={({ field }) => (
											<Switch
												label={
													<div>
														<Typography color="blue-gray">Social Media Account</Typography>
													</div>
												}
												crossOrigin={undefined}
												checked={field.value ?? false}
												onChange={field.onChange}
											/>
										)}
									/>
								</>
							)}
							<Typography variant="h5" color="blue-gray" className="-mb-3">
								Achievement
							</Typography>
							<Switch
								label={
									<div>
										<Typography color="blue-gray">Award Achievement?</Typography>
									</div>
								}
								crossOrigin={undefined}
								checked={hasBonusAchievement}
								onChange={event => setHasBonusAchievement(event.target.checked)}
							/>
							{hasBonusAchievement && (
								<>
									<Switch
										label={
											<div>
												<Typography color="blue-gray">Award existing achievement?</Typography>
											</div>
										}
										crossOrigin={undefined}
										checked={bonusExistingAchievement}
										onChange={event => {
											setBonusExistingAchievement(event.target.checked)
										}}
									/>
									{bonusExistingAchievement && (
										<>
											<Typography variant="h6" color="blue-gray" className="-mb-3">
												Select Existing Achievement
											</Typography>
											<Controller
												control={controlAchievement}
												name="id"
												render={({ field }) => (
													<AchievementSearchInput
														placeholder={'Select Existing Achievement'}
														autoSuggestion
														suggestWhenEmpty
														onValueChange={(values: any) => {
															resetAchievement({
																avatarUrl: values.avatarUrl,
																bio: values.bio,
																category: values.category!,
																displayName: values.displayName,
																slug: values.slug,
																id: values.achievementId,
															})
														}}
														defaultValue={field.value ?? ''}
														userId={ownerId!}
													/>
												)}
											/>
										</>
									)}
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										Achievement Type
									</Typography>
									<Controller
										control={controlAchievement}
										name="category"
										render={({ field }) => (
											<Select
												onChange={(option: any) => {
													field.onChange(option)
												}}
												variant="outlined"
												labelProps={{
													className: 'before:content-none after:content-none',
												}}
												value={field.value}
											>
												{ACHIVEMENT_CATEGORIES.map((option: any, index: number) => (
													<Option key={index} value={option.value}>
														{option.name}
													</Option>
												))}
											</Select>
										)}
									/>
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										Avatar
									</Typography>
									<div style={{ width: '100%', paddingBottom: '100%', position: 'relative' }}>
										<img
											className="object-cover object-center"
											style={{
												position: 'absolute',
												top: 0,
												left: 0,
												width: '100%',
												height: '100%',
											}}
											src={resolveAvatarUrl(
												newAchievementAvatar?.data
													? newAchievementAvatar.data
													: watchAchievement('avatarUrl')
											)}
											alt="achievement avatar"
											onClick={() => {
												achievementAvatarInput.current?.click()
											}}
										/>
									</div>
									<div className="hidden">
										<input
											ref={achievementAvatarInput}
											type="file"
											onChange={event => {
												onAchievementAvatarSelected(event.target.files)
											}}
										/>
									</div>
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										Name
									</Typography>
									<Controller
										control={controlAchievement}
										name="displayName"
										render={({ field }) => (
											<Input
												crossOrigin={undefined}
												size="lg"
												className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
												labelProps={{
													className: 'before:content-none after:content-none',
												}}
												onChange={field.onChange}
												value={field.value}
											/>
										)}
									/>
									<Typography variant="h6" color="blue-gray" className="-mb-3">
										Description
									</Typography>
									<Controller
										control={controlAchievement}
										name="bio"
										render={({ field }) => (
											<Textarea
												className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
												labelProps={{
													className: 'before:content-none after:content-none',
												}}
												value={field.value}
												onChange={field.onChange}
											/>
										)}
									/>
								</>
							)}
						</div>
						<div className="flex flex-col gap-6 mt-6">
							<Button fullWidth onClick={save}>
								Save
							</Button>
							{canDelete && (
								<Button
									className="self-end"
									color="deep-orange"
									onClick={deleteReward}
									disabled={processing}
								>
									Delete
								</Button>
							)}
							{canRecover && (
								<Button
									className="self-end"
									color="deep-orange"
									onClick={recover}
									disabled={processing}
								>
									Recover
								</Button>
							)}
						</div>
					</form>
				</CardBody>
			</Card>
		</>
	)
}

export default RewardPage
