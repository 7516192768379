import { Routes, Route, Navigate } from 'react-router-dom'
import { Dashboard, Auth } from '@/layouts'
import { PublicGuard, AuthGuard } from '@/guards'
import { Toaster } from 'react-hot-toast'

function App() {
	return (
		<>
			<Routes>
				<Route
					path="/dashboard/*"
					element={
						<AuthGuard>
							<Dashboard />
						</AuthGuard>
					}
				/>
				<Route
					path="/auth/*"
					element={
						<PublicGuard>
							<Auth />
						</PublicGuard>
					}
				/>
				<Route path="*" element={<Navigate to="/dashboard/users" replace />} />
			</Routes>
			<Toaster />
		</>
	)
}

export default App
