import { Card, Typography, Button, Input, Alert, Textarea, Select, Option } from '@material-tailwind/react'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import * as Yup from 'yup'

import { trueErrorMessage } from '@/utils/error'
import { UserGender, UserTier, useAdminCreateUserMutation } from '@/graphql/generated/scheme'
import { yupResolver } from '@hookform/resolvers/yup'
import DateTimePicker from 'react-datetime-picker'

const GENDER = [
	{ name: 'Unknown', value: UserGender.Unknown },
	{ name: 'Female', value: UserGender.Female },
	{ name: 'Male', value: UserGender.Male },
	{ name: 'Other', value: UserGender.Other },
	{ name: 'Not Disclosed', value: UserGender.NotDisclosed },
]

const addressSchema = Yup.object().shape({
	address: Yup.string().optional(),
	city: Yup.string().optional(),
	region: Yup.string().optional(),
	postalCode: Yup.string().optional(),
	countryCode: Yup.string().optional(),
})

const userSchema = Yup.object().shape({
	emailAddress: Yup.string().required('Email is required.').email('Invalid email.'),
	userName: Yup.string().required('User ID is required.'),
	password: Yup.string().required('Password is required.').min(6, 'Password must be at least 6 characters.'),
	name: Yup.string().required('Name is required.'),
	gender: Yup.string()
		.required('Gender is required.')
		.oneOf(
			GENDER.map((item: any) => item.value),
			'Gender is required.'
		),
	dateOfBirth: Yup.date().optional().nullable(),
	bio: Yup.string().optional(),
	countryCode: Yup.string().required('Country code is required.'),
	phoneNumber: Yup.string().required('Phone number is required.'),
	address: addressSchema,
})

export function UserCreate() {
	//#region hooks
	const [error, setError] = useState<string>('')
	const navigate = useNavigate()

	const [createUser, { loading: saving }] = useAdminCreateUserMutation({
		onCompleted: data => {
			navigate(`/dashboard/users/${data.admin.createUser.fullUser?.userId}`)
		},
		onError: error => {
			setError(trueErrorMessage(error.message))
		},
	})

	const {
		formState: { errors, isValid },
		getValues: getValuesUser,
		control: controlUser,
	} = useForm({
		resolver: yupResolver(userSchema),
		mode: 'onChange',
		defaultValues: {},
	})

	//#endregion hooks

	//#region functions
	const save = () => {
		if (!isValid) {
			setError('Please solve all validation errors')
			return
		}

		const { address, countryCode, emailAddress, name, password, phoneNumber, userName, bio, dateOfBirth, gender } =
			getValuesUser()

		createUser({
			variables: {
				authArgs: {
					userName: emailAddress,
					password: password,
				},
				profileArgs: {
					address: address as any,
					bio: bio,
					countryCode: countryCode,
					dateOfBirth: dateOfBirth,
					emailAddress: emailAddress,
					gender: gender,
					name: name,
					phoneNumber: phoneNumber,
					userName: userName,
					agreeToTermsAndConditions: false,
					tier: UserTier.Unverified,
				},
			},
		})
	}

	//#endregion functions

	return (
		<>
			<Card color="transparent" shadow={false}>
				<Typography variant="h4" color="blue-gray">
					Create new User
				</Typography>
				<div className="mt-8 mb-2 max-w-screen-md">
					{(() => {
						if (error) {
							return (
								<div className="flex w-full flex-col gap-2 mb-6">
									<Alert color="red" onClose={() => setError('')}>
										{error}
									</Alert>
								</div>
							)
						}
					})()}
					<form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
						<div className="mb-1 flex flex-col gap-6">
							<Typography variant="h5" color="blue-gray" className="-mb-3">
								Authentication Info
							</Typography>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Email Address*
							</Typography>
							<Controller
								control={controlUser}
								name="emailAddress"
								render={({ field }) => (
									<Input
										crossOrigin={undefined}
										size="lg"
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										onChange={field.onChange}
										value={field.value ?? ''}
									/>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="emailAddress"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Password*
							</Typography>
							<Controller
								control={controlUser}
								name="password"
								render={({ field }) => (
									<Input
										crossOrigin={undefined}
										size="lg"
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										onChange={field.onChange}
										value={field.value ?? ''}
									/>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="password"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<Typography variant="h5" color="blue-gray" className="-mb-3">
								User Profile
							</Typography>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								User ID*
							</Typography>
							<Controller
								control={controlUser}
								name="userName"
								render={({ field }) => (
									<Input
										crossOrigin={undefined}
										size="lg"
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										onChange={field.onChange}
										value={field.value ?? ''}
									/>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="userName"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Name*
							</Typography>
							<Controller
								control={controlUser}
								name="name"
								render={({ field }) => (
									<Input
										crossOrigin={undefined}
										size="lg"
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										onChange={field.onChange}
										value={field.value ?? ''}
									/>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="name"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Gender*
							</Typography>
							<Controller
								control={controlUser}
								name="gender"
								render={({ field }) => (
									<Select
										onChange={(option: any) => field.onChange(option)}
										variant="outlined"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										value={field.value}
									>
										{GENDER.map((option: any, index: number) => (
											<Option key={index} value={option.value}>
												{option.name}
											</Option>
										))}
									</Select>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="gender"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Phone Number*
							</Typography>
							<div className="relative flex w-full max-w-[24rem]">
								<Controller
									control={controlUser}
									name="countryCode"
									render={({ field }) => (
										<Input
											crossOrigin={undefined}
											size="lg"
											label="Country Code"
											containerProps={{
												className: ' !min-w-[100px]',
											}}
											className="rounded-none rounded-l-md"
											onChange={field.onChange}
											value={field.value ?? ''}
										/>
									)}
								/>
								<Controller
									control={controlUser}
									name="phoneNumber"
									render={({ field }) => (
										<Input
											crossOrigin={undefined}
											size="lg"
											label="Phone Number"
											containerProps={{
												className: ' !min-w-[250px]',
											}}
											className="rounded-none rounded-r-md"
											onChange={field.onChange}
											value={field.value ?? ''}
										/>
									)}
								/>
							</div>
							<ErrorMessage
								errors={errors}
								name="countryCode"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="phoneNumber"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Bio
							</Typography>
							<Controller
								control={controlUser}
								name="bio"
								render={({ field }) => (
									<Textarea
										size="lg"
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										onChange={field.onChange}
										value={field.value ?? ''}
									/>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Date Of Birth
							</Typography>
							<Controller
								control={controlUser}
								name="dateOfBirth"
								render={({ field }) => (
									<div className="custom-react-datetime-picker">
										<DateTimePicker
											className="px-4 py-3 peer w-full h-10 bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border text-sm rounded-[7px] border-blue-gray-200 placeholder-white-50 placeholder:italic placeholder:font-light flex items-center"
											value={field.value}
											onChange={value => {
												field.onChange(value)
											}}
											format="MM/dd/yyyy"
											monthPlaceholder="mm"
											dayPlaceholder="dd"
											yearPlaceholder="yyyy"
											calendarIcon={null}
											clearIcon={null}
											disableClock={true}
											maxDate={new Date()}
											calendarType="gregory"
										/>
									</div>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="dateOfBirth"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Address
							</Typography>
							<Controller
								control={controlUser}
								name="address.address"
								render={({ field }) => (
									<Input
										crossOrigin={undefined}
										size="lg"
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										onChange={field.onChange}
										value={field.value ?? ''}
									/>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="address.address"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								City
							</Typography>
							<Controller
								control={controlUser}
								name="address.city"
								render={({ field }) => (
									<Input
										crossOrigin={undefined}
										size="lg"
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										onChange={field.onChange}
										value={field.value ?? ''}
									/>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="address.city"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Region
							</Typography>
							<Controller
								control={controlUser}
								name="address.region"
								render={({ field }) => (
									<Input
										crossOrigin={undefined}
										size="lg"
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										onChange={field.onChange}
										value={field.value ?? ''}
									/>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="address.region"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Postal Code
							</Typography>
							<Controller
								control={controlUser}
								name="address.postalCode"
								render={({ field }) => (
									<Input
										crossOrigin={undefined}
										size="lg"
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										onChange={field.onChange}
										value={field.value ?? ''}
									/>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="address.postalCode"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Country Code
							</Typography>
							<Controller
								control={controlUser}
								name="address.countryCode"
								render={({ field }) => (
									<Input
										crossOrigin={undefined}
										size="lg"
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										onChange={field.onChange}
										value={field.value ?? ''}
									/>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="address.countryCode"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
						</div>
						<Button disabled={saving} className="mt-6" fullWidth onClick={() => save()}>
							Create
						</Button>
					</form>
				</div>
			</Card>
		</>
	)
}

export default UserCreate
