import { useState } from 'react'
import { Card, CardHeader, CardBody, CardFooter, Input, Button, Typography } from '@material-tailwind/react'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'

import { useAuth } from '@/hooks/use-auth'

const validationSchema = Yup.object().shape({
	userName: Yup.string()
		.transform((_value, originalValue) => originalValue?.toLowerCase())
		.email(
			'Invalid username or password. Please try again. If you forgot your current login credentials, contact support'
		)
		.required('Email is required'),
	password: Yup.string().required(
		'Invalid username or password. Please try again. If you forgot your current login credentials, contact support'
	),
})

export function SignIn() {
	const [submitting, setSubmitting] = useState(false)
	const { login } = useAuth()
	const {
		register,
		handleSubmit,
		formState: { isValid },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'onChange', // "onChange"
	})

	const onSubmit = (values: any) => {
		setSubmitting(true)
		login(
			values,
			() => {
				setSubmitting(false)
			},
			(error: string) => {
				setSubmitting(false)
				toast.error(error)
			}
		)
	}

	return (
		<>
			<img
				src="https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
				className="absolute inset-0 z-0 h-full w-full object-cover"
			/>
			<div className="absolute inset-0 z-0 h-full w-full bg-black/50" />
			<div className="container mx-auto p-4">
				<Card className="absolute top-2/4 left-2/4 w-full max-w-[24rem] -translate-y-2/4 -translate-x-2/4">
					<CardHeader variant="gradient" color="blue" className="mb-4 grid h-28 place-items-center">
						<Typography variant="h3" color="white">
							Sign In
						</Typography>
					</CardHeader>
					<form onSubmit={handleSubmit(onSubmit)}>
						<CardBody className="flex flex-col gap-4">
							<Input type="email" label="Email" size="lg" {...register('userName')} crossOrigin="" />
							<Input
								type="password"
								label="Password"
								size="lg"
								{...register('password')}
								crossOrigin=""
							/>
						</CardBody>
						<CardFooter className="pt-0">
							<Button
								variant="gradient"
								color="blue"
								fullWidth
								type="submit"
								disabled={!isValid || submitting}
							>
								Sign In
							</Button>
						</CardFooter>
					</form>
				</Card>
			</div>
		</>
	)
}

export default SignIn
