import { Fragment, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { debounce } from 'lodash'
import classNames from 'classnames'
import toast from 'react-hot-toast'
import { Menu, Transition } from '@headlessui/react'

import {
	useAdminGetAchievemntByIdMutation,
	useSearchMinimalAchievementsLazyQuery,
} from '@/graphql/generated/scheme'

import { useOutsideClick } from '@/hooks/use-click-outside'
import { trueErrorMessage } from '@/utils/error'
import { DEFAULT_INFORMATION } from '@/utils/constants'
import { Avatar, Input } from '@material-tailwind/react'
import isUrlHttp from 'is-url-http'

interface Props {
	id?: any
	loading?: boolean
	autoSuggestion?: boolean
	className?: string
	placeholder?: any
	excludeValues?: any
	onValueChange?: (...args: any) => any
	defaultValue?: any
	disabled?: boolean
	suggestWhenEmpty?: boolean
	userId: string
}

const validationSchema = Yup.object().shape({
	q: Yup.string(),
})

export function AchievementSearchInput({
	id,
	loading,
	autoSuggestion,
	className,
	placeholder,
	excludeValues,
	onValueChange,
	defaultValue,
	disabled,
	suggestWhenEmpty,
	userId,
}: Props) {
	const [selectedItem, setSelectedItem] = useState<any>(null)
	const [achievements, setAchievements] = useState<Array<any>>([])
	const [openSuggestion, setOpenSuggestion] = useState<boolean>(false)
	const { register, setValue } = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'onBlur',
	})
	const [
		getAchievements,
		{
			data: dataAchievements,
			loading: loadingAchievements,
			error: errorAchievements,
			called: calledAchievements,
			refetch: refetchAchievements,
		},
	] = useSearchMinimalAchievementsLazyQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'no-cache',
	})
	const [getAchievementById] = useAdminGetAchievemntByIdMutation({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'no-cache',
		onCompleted: dataAchievement => {
			const oAchievement = dataAchievement?.admin.achievementById.fullAchievement
			setValue('q', oAchievement?.slug)
			setSelectedItem(oAchievement)
		},
		onError: error => {
			toast.error(trueErrorMessage(error.message))
		},
	})
	const handleClickOutside = () => {
		setOpenSuggestion(false)
	}
	const ref = useOutsideClick(handleClickOutside)
	const debounceDropDown = useCallback(
		debounce((nextValue, isEnterKey) => {
			if (suggestWhenEmpty || (!!autoSuggestion && !loadingAchievements && !isEnterKey)) {
				if (suggestWhenEmpty || (nextValue || '').trim().length > 0) {
					setOpenSuggestion(true)
					if (calledAchievements) {
						refetchAchievements({
							name: nextValue,
							userId: userId,
						})
					} else {
						getAchievements({
							variables: {
								offset: 0,
								limit: 10,
								name: nextValue,
								userId: userId,
							},
						})
					}
				} else {
					setAchievements([])
				}
			}
		}, 1000),
		[]
	)

	useEffect(() => {
		if (defaultValue) {
			getAchievementById({
				variables: {
					achievementId: defaultValue,
				},
			})
		}
	}, [defaultValue])

	useEffect(() => {
		if (dataAchievements) {
			setAchievements(
				dataAchievements?.admin.achievement.listCreatedAchievements?.nodes.filter(item => !excludeValues?.includes(item.achievementId)) || []
			)
		}
		if (errorAchievements) {
			toast.error(errorAchievements.message)
		}
	}, [dataAchievements, errorAchievements])

	const handleInputOnchange = (e: any) => {
		const { value } = e.target
		debounceDropDown(value, e.key === 'Enter')
	}

	const handleInputOnFocus = (e: any) => {
		debounceDropDown(e.target.value, false);
	}

	const resolveAvatarUrl = (src: string) => {
		let url = src
		if (!url) {
			url = DEFAULT_INFORMATION.avatarImageUrl
		} else {
			if (!isUrlHttp(url) && url.indexOf('data:') === -1) {
				url = `${process.env.REACT_APP_MEDIA_URL}/${src}`
			}
		}

		return url
	}

	return (
		<div ref={ref}>
			<Menu as="div" className="relative">
				<div className="relative">
					<div className="absolute top-1/2 left-4 -mt-4">
						<Avatar
							className="w-8 h-8 rounded-md overflow-hidden flex-shrink-0"
							src={resolveAvatarUrl(selectedItem?.avatarUrl)}
						/>
					</div>
					<Input
						crossOrigin={undefined}
						id={id}
						className={classNames(
							'px-4 pl-14 py-3 !border-t-blue-gray-200 focus:!border-t-gray-900',
							className
						)}
						placeholder={placeholder}
						type="text"
						{...register('q')}
						onKeyUp={handleInputOnchange}
						onFocus={handleInputOnFocus}
						disabled={loading || loadingAchievements || disabled}
					/>
				</div>
				{!!autoSuggestion && (
					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
						show={openSuggestion}
					>
						<Menu.Items
							className="absolute right-0 rounded-lg shadow-lg bg-white focus:outline-none mt-2 w-full z-10 py-4 !border-t-blue-gray-200 focus:!border-t-gray-900"
							static
						>
							{achievements.map((item, index) => {
								return (
									<Menu.Item key={index} as={Fragment}>
										{({ active }) => (
											<button
												type="button"
												className={classNames(
													`hover:bg-white-500 grid grid-cols-2 w-full text-left`,
													{ 'bg-white-5': active },
													'items-center'
												)}
												onClick={() => {
													setOpenSuggestion(false)
													setValue('q', item.slug)
													setSelectedItem(item)
													onValueChange?.(item)
												}}
											>
												<div className="flex items-top">
													<div className="relative flex-shrink-0">
														<Avatar
															className="h-11 w-11 rounded-full object-cover"
															src={resolveAvatarUrl(item.avatarUrl)}
															alt={item.displayName}
														/>
													</div>
													<div className="flex-grow">
														<div className="font-bold leading-5">{item.displayName}</div>
													</div>
												</div>
											</button>
										)}
									</Menu.Item>
								)
							})}
						</Menu.Items>
					</Transition>
				)}
			</Menu>
		</div>
	)
}
