import { useState } from 'react'
import { Card, CardHeader, CardBody, CardFooter, Button, Typography } from '@material-tailwind/react'
import OtpInput from 'react-otp-input'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import toast from 'react-hot-toast'

import { useAuth } from '@/hooks/use-auth'
import { useStore } from '@/hooks/use-store'

const validationSchema = Yup.object().shape({
	otp: Yup.string().required('').min(6, ''),
})

export function Mfa() {
	const { verifyOtp, resendOtp, logout } = useAuth()
	const { clearStore } = useStore()
	const [submitting, setSubmitting] = useState(false)
	const [resending, setResending] = useState(false)
	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'onBlur', // "onChange"
	})

	const doVerifyOtp = (otp: string) => {
		setSubmitting(true)

		verifyOtp(
			otp,
			'VerifySignIn',
			(_: any) => {
				setSubmitting(false)
			},
			(error: string) => {
				setSubmitting(false)
				toast.error(error)
			}
		)
	}

	const doResendOtp = () => {
		setResending(true)
		resendOtp(
			(_: any) => {
				setResending(false)
				toast.success('New OTP was resent')
			},
			(error: string) => {
				setResending(false)
				toast.error(error)
			}
		)
	}

	const onSubmit = (values: any) => {
		doVerifyOtp(values.otp)
	}

	return (
		<>
			<img
				src="https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
				className="absolute inset-0 z-0 h-full w-full object-cover"
			/>
			<div className="absolute inset-0 z-0 h-full w-full bg-black/50" />
			<div className="container mx-auto p-4">
				<Card className="absolute top-2/4 left-2/4 w-full max-w-[24rem] -translate-y-2/4 -translate-x-2/4">
					<CardHeader variant="gradient" color="blue" className="mb-4 grid h-28 place-items-center">
						<Typography variant="h3" color="white">
							2FA verification
						</Typography>
					</CardHeader>
					<form onSubmit={handleSubmit(onSubmit)}>
						<CardBody className="flex flex-col gap-4">
							<div>
								<Controller
									control={control}
									name="otp"
									render={({ field }) => (
										<OtpInput
											value={field.value}
											onChange={(otp: string) => field.onChange(otp)}
											numInputs={6}
											containerStyle="!grid grid-cols-6 gap-2"
											inputStyle="px-4 py-3 border-2 border-white-10 bg-white-10 rounded-lg placeholder-white-50 placeholder:italic placeholder:font-light block !w-12 h-12 text-center"
											renderInput={props => <input {...props} type="tel" />}
										/>
									)}
								/>
								{errors.otp && (
									<div className="text-secondary-red mt-2 text-sm">
										{errors.otp.message as string}
									</div>
								)}
							</div>
						</CardBody>
						<CardFooter className="pt-0">
							<Button
								type="submit"
								variant="gradient"
								color="blue"
								fullWidth
								disabled={!isValid || submitting}
							>
								Continue
							</Button>
							<div className="mt-8 text-center">
								<Button
									variant="text"
									onClick={() =>
										logout(null, null, () => {
											clearStore()
										})
									}
									disabled={submitting}
								>
									<i className="fas fa-arrow-left" /> Back to Sign In
								</Button>
							</div>
							<div className="mt-8">
								<h3 className="text-center mb-6">Didn't get the code?</h3>
								<div>
									<Button
										type="button"
										variant="gradient"
										fullWidth
										onClick={() => doResendOtp()}
										disabled={resending || submitting}
									>
										Resend
									</Button>
								</div>
							</div>
						</CardFooter>
					</form>
				</Card>
			</div>
		</>
	)
}

export default Mfa
