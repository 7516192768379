/**
=========================================================
* Material Tailwind Dashboard React - v2.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-tailwind-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-tailwind-dashboard-react/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@material-tailwind/react'
import { MaterialTailwindControllerProvider } from '@/context'
import 'react-phone-input-2/lib/style.css'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import './tailwind.css'

import { ApolloProvider } from '@apollo/client'
import { AuthProvider } from '@/hooks/use-auth'
import { StoreProvider } from '@/hooks/use-store'
import Apollo from '@/graphql/apollo'

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<AuthProvider>
			<ApolloProvider client={Apollo.getInstance().client}>
				<StoreProvider>
					<BrowserRouter>
						<ThemeProvider>
							<MaterialTailwindControllerProvider>
								<App />
							</MaterialTailwindControllerProvider>
						</ThemeProvider>
					</BrowserRouter>
				</StoreProvider>
			</ApolloProvider>
		</AuthProvider>
	</React.StrictMode>
)
