import { Reward, useAdminGetRewardParticipantsQuery } from '@/graphql/generated/scheme'
import { Dialog, DialogHeader, DialogBody, DialogFooter, Button, Typography } from '@material-tailwind/react'
import { useEffect, useState } from 'react'

export default function RewardParticipantDetailsDialog({
	rewardId,
	onClose,
}: {
	rewardId: string
	onClose: () => any
}) {
	const [open, setOpen] = useState(true)
	const [reward, setReward] = useState<Reward>()

	const { data: dataRewardParticipants, loading: loadingRewardParticipants } = useAdminGetRewardParticipantsQuery({
		variables: {
			rewardId: rewardId,
		},
	})

	useEffect(() => {
		const rewardParticipantsData = dataRewardParticipants?.admin?.reward?.byId

		if (rewardParticipantsData) {
			setReward(rewardParticipantsData as Reward)
		} else if (!loadingRewardParticipants) {
			setOpen(false)
			onClose()
		}
	}, [dataRewardParticipants])

	const columns = [
		{ display: 'User ID', key: 'userId' },
		{ display: 'Location', key: 'location' },
		{ display: 'Gender', key: 'gender' },
		{ display: 'Age Range', key: 'ageRange' },
		{ display: 'Unlock Date', key: 'unlockDate' },
		{ display: 'Slice Purchase Date', key: 'slicePurchaseDate' },
		{ display: 'Date Used', key: 'dateUsed' },
		{ display: 'Achievement Earned', key: 'achievementEarned' },
		{ display: 'Is Winner', key: 'isWinner' },
	]

	return (
		<Dialog open={open} size="xl" handler={() => {}}>
			<DialogHeader>Participants of {reward?.displayName}</DialogHeader>
			<DialogBody className="overflow-x-auto overflow-y-auto max-h-[80svh] p-0" divider>
				{reward?.participants ? (
					<table className="w-full min-w-max table-auto text-left">
						<thead>
							<tr>
								{columns.map(el => (
									<th key={el.key} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-normal leading-none opacity-70"
										>
											<span>{el.display}</span>
										</Typography>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{(reward?.participants ?? []).map((participant, index: number) => {
								const isLast = index === (reward?.participants?.length ?? 0) - 1
								const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50'

								return (
									<tr key={participant?.userId}>
										{columns.map(el => (
											<td key={el.key} className={classes}>
												<Typography variant="small" color="blue-gray" className="font-normal">
													{(participant as any)[el.key] ?? ''}
												</Typography>
											</td>
										))}
									</tr>
								)
							})}
						</tbody>
					</table>
				) : (
					<></>
				)}
			</DialogBody>
			<DialogFooter>
				<Button
					variant="text"
					onClick={() => {
						setOpen(false)
						onClose()
					}}
					className="mr-1"
				>
					<span>Close</span>
				</Button>
			</DialogFooter>
		</Dialog>
	)
}
