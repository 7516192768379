import { useLocation, Navigate } from 'react-router-dom'

import { useAuth } from '@/hooks/use-auth'

export function PublicGuard({ children }: { children: JSX.Element }) {
	const location = useLocation()
	const { token, authInfo, requireOtp } = useAuth()

	if (requireOtp) {
		if (location.pathname !== '/auth/mfa') {
			return <Navigate to="/auth/mfa" state={{ from: location }} replace />
		}
	} else {
		if (token && authInfo) {
			return <Navigate to="/" state={{ from: location }} replace />
		}
		if (location.pathname !== '/auth/sign-in') {
			return <Navigate to="/auth/sign-in" state={{ from: location }} replace />
		}
	}

	return children
}
