import { useAuth } from '@/hooks/use-auth'
import { useStore } from '@/hooks/use-store'
import { DEFAULT_INFORMATION } from '@/utils/constants'
import { Menu, MenuHandler, MenuList, MenuItem, Avatar, Typography } from '@material-tailwind/react'

export function ProfileMenu() {
	const { logout } = useAuth()
	const { user, clearStore } = useStore()

	return (
		<Menu>
			<MenuHandler>
				<Avatar
					size="sm"
					variant="circular"
					className="cursor-pointer"
					src={user?.avatarUrl || DEFAULT_INFORMATION.avatarImageUrl}
					alt={user?.name}
				/>
			</MenuHandler>
			<MenuList>
				<MenuItem
					className="flex items-center gap-2"
					onClick={() =>
						logout(null, null, () => {
							clearStore()
						})
					}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={2}
						stroke="currentColor"
						className="h-4 w-4"
					>
						<path strokeLinecap="round" strokeLinejoin="round" d="M5.636 5.636a9 9 0 1012.728 0M12 3v9" />
					</svg>
					<Typography variant="small" className="font-normal">
						Sign Out
					</Typography>
				</MenuItem>
			</MenuList>
		</Menu>
	)
}
