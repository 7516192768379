import { createContext, useContext, useMemo } from 'react'

import { useAuth } from './use-auth'
import Apollo from '@/graphql/apollo'

const StoreContext = createContext<any>(null)

export const StoreProvider = ({ children }: any) => {
	const apolloClient = Apollo.getInstance().client
	const { clearAuth } = useAuth()

	const clearStore = () => {
		apolloClient.clearStore().finally(() => {
			clearAuth()
		})
	}

	const value = useMemo(
		() => ({
			clearStore,
		}),
		[]
	)
	return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

export const useStore = () => {
	return useContext(StoreContext)
}
