import { Card, Typography, Button, Input, Alert, Textarea } from '@material-tailwind/react'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
	PieArgsInput,
	PieOperationalState,
	useAdminCreatePieMutation,
	useAdminGetUserIdByUserNameMutation,
} from '@/graphql/generated/scheme'
import { slugify } from '@/utils/common'
import { ApolloError } from '@apollo/client'
import { trueErrorMessage } from '@/utils/error'
import { NumericFormat } from 'react-number-format'

export function PieCreate() {
	const [error, setError] = useState<string>('')
	const [saving, setSaving] = useState<boolean>(false)
	const [ownerUserName, setOwnerUserName] = useState<string>('')
	const [pie, setPie] = useState<PieArgsInput>({
		availableSlices: 0,
		bio: '',
		displayName: '',
		individualAssets: [],
		isFeatured: false,
		issuedSlices: 0,
		isVerified: false,
		isVisible: false,
		marketSymbol: '',
		operationalState: PieOperationalState.Open,
		sentSlices: 0,
		slicePrice: {
			symbol: 'USD',
			value: 0,
		},
		slug: '',
		soldSlices: 0,
		totalUniqueSubscribers: 0,
		avatarUrl: null,
		createdUtc: null,
		launchedUtc: null,
		rank: 0,
		preserveForInstagram: undefined,
	})

	useEffect(() => {
		if (saving) {
			save()
		}
	}, [saving])

	const navigate = useNavigate()
	const [getUserId] = useAdminGetUserIdByUserNameMutation()
	const [createPie] = useAdminCreatePieMutation()

	async function save() {
		setError('')

		if (
			!ownerUserName ||
			!pie.displayName ||
			isNaN(pie.issuedSlices) ||
			isNaN(pie.availableSlices) ||
			isNaN(pie.slicePrice.value)
		) {
			setError('Please fill all required fields')
			setSaving(false)
			return
		}

		if (pie.issuedSlices < 0 || pie.availableSlices < 0 || pie.slicePrice.value < 0) {
			setError('Slices fields must be non negative number')
			setSaving(false)
			return
		}

		if (pie.issuedSlices < pie.availableSlices) {
			setError('Total Slices must be greater than Slice for Sales')
			setSaving(false)
			return
		}

		let userId = null
		try {
			const { errors: getUserIdErrors, data: getUserIdData } = await getUserId({
				variables: { userName: ownerUserName },
			})

			if (getUserIdErrors || !(getUserIdData && getUserIdData?.admin?.userByUserName?.fullUser?.userId)) {
				setError(`Could not find user has USER ID: ${ownerUserName}`)
				setSaving(false)
				return
			}

			userId = getUserIdData?.admin?.userByUserName?.fullUser?.userId
		} catch (error) {
			setError(`Could not find user has USER ID: ${ownerUserName}`)
			setSaving(false)
			return
		}

		try {
			const { errors: createErrors, data: createData } = await createPie({
				variables: {
					ownerId: userId,
					pie: pie,
				},
			})

			if (createErrors || !(createData && createData?.admin?.createPie?.fullPie?.pieId)) {
				setError(`Could not create pie. Please try again.`)
				setSaving(false)
				return
			}

			navigate(`/dashboard/pies/${createData?.admin?.createPie?.fullPie?.pieId}`)
		} catch (error: any) {
			const err = error as ApolloError
			if (!err || !err.graphQLErrors || !err.graphQLErrors.length) {
				setError(`Oops! Could not create pie. Please try again.`)
				setSaving(false)
				return
			}

			setError(trueErrorMessage(err.graphQLErrors[0].message))
			setSaving(false)
			return
		}
	}

	return (
		<>
			<Card color="transparent" shadow={false}>
				<Typography variant="h4" color="blue-gray">
					Create new Pie
				</Typography>
				<div className="mt-8 mb-2 max-w-screen-md">
					{(() => {
						if (error) {
							return (
								<div className="flex w-full flex-col gap-2 mb-6">
									<Alert color="red" onClose={() => setError('')}>
										{error}
									</Alert>
								</div>
							)
						}
					})()}
					<div className="mb-1 flex flex-col gap-6">
						<Input
							size="lg"
							label="Owner User ID"
							required
							crossOrigin={undefined}
							value={ownerUserName}
							onChange={e => {
								setOwnerUserName(e.target.value)
							}}
							readOnly={saving}
						/>
						{ownerUserName?.toLocaleLowerCase().trim() === 'repbets' && (
							<div className="flex flex-row">
								<Input
									size="lg"
									crossOrigin={undefined}
									label="Preserve for Instagram"
									value={pie?.preserveForInstagram ?? undefined}
									onChange={e => {
										setPie({
											...pie,
											preserveForInstagram: e.target.value,
										})
									}}
									readOnly={saving}
								/>
							</div>
						)}
						<Input
							size="lg"
							label="Display Name"
							required
							crossOrigin={undefined}
							value={pie?.displayName}
							onChange={e => {
								setPie({
									...pie,
									displayName: e.target.value,
									slug: slugify(e.target.value),
								})
							}}
							readOnly={saving}
						/>
						<Input size="lg" label="Slug" crossOrigin={undefined} value={pie?.slug} readOnly />
						<Textarea
							size="lg"
							label="Bio"
							value={pie?.bio}
							onChange={e => {
								setPie({
									...pie,
									bio: e.target.value,
								})
							}}
							readOnly={saving}
						/>
						<NumericFormat
							thousandSeparator={true}
							min={0}
							value={pie?.issuedSlices}
							onValueChange={(e: any) => {
								setPie({
									...pie,
									issuedSlices: e.floatValue,
								})
							}}
							readOnly={saving}
							required
							customInput={Input}
							crossOrigin={undefined}
							label="Total Slices"
						/>
						<NumericFormat
							thousandSeparator={true}
							min={0}
							value={pie?.availableSlices}
							onValueChange={(e: any) => {
								setPie({
									...pie,
									availableSlices: e.floatValue,
								})
							}}
							readOnly={saving}
							required
							customInput={Input}
							crossOrigin={undefined}
							label="Slices for Sale"
						/>
						<NumericFormat
							thousandSeparator={true}
							min={0}
							value={pie?.slicePrice.value}
							onValueChange={(e: any) => {
								setPie({
									...pie,
									slicePrice: {
										...pie?.slicePrice,
										value: e.floatValue,
									},
								})
							}}
							readOnly={saving}
							required
							customInput={Input}
							crossOrigin={undefined}
							label="Price per slice"
						/>
					</div>
					<div className="flex">
						<Button disabled={saving} className="mt-6" fullWidth onClick={() => setSaving(true)}>
							Create
						</Button>
					</div>
				</div>
			</Card>
		</>
	)
}

export default PieCreate
