import {
	ApolloClient,
	ApolloLink,
	from,
	HttpLink,
	InMemoryCache,
	NormalizedCacheObject,
	ServerError,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import * as authService from '@/services/auth'

export default class Apollo {
	private static instance: Apollo

	public client: ApolloClient<NormalizedCacheObject>

	httpLink = new HttpLink({
		uri: '/api/graphql',
		headers: {
			'Content-Type': 'application/json',
		},
	})

	authLink = new ApolloLink((operation, forward) => {
		// add the authorization to the headers
		const token = authService.getToken()
		operation.setContext(({ headers = {} }) => ({
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : '',
			},
		}))

		return forward(operation)
	})

	errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
		if (
			(networkError as ServerError)?.statusCode === 401 ||
			graphQLErrors?.some(err => err.extensions.code === 'AUTH_NOT_AUTHORIZED' || err.message === 'Unauthorized')
		) {
			localStorage.clear()
			window.location.reload()
			return forward(operation)
		}
	})

	private constructor() {
		this.client = new ApolloClient({
			link: from([this.authLink, this.errorLink, this.httpLink]),
			cache: new InMemoryCache(),
		})
	}

	static getInstance(): Apollo {
		if (!this.instance) {
			this.instance = new Apollo()
		}
		return this.instance
	}
}
