import {
	Achievement,
	AchievementCategory,
	useAchievementPresignUploadUrlMutation,
	useAdminCheckAchievementSlugAvailabilityLazyQuery,
	useAdminDeleteAchievementMutation,
	useAdminGetAchievemntByIdMutation,
	useAdminRecoverAchievementMutation,
	useCreateOrUpdateAchievementMutation,
} from '@/graphql/generated/scheme'
import { trueErrorMessage } from '@/utils/error'
import { camelCase, debounce, upperFirst } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import * as uploadService from '../../services/upload'
import urlParse from 'url-parse'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { DEFAULT_INFORMATION, MEDIA_FOLDER } from '@/utils/constants'
import { slugify } from '@/utils/common'
import isUrlHttp from 'is-url-http'
import mime from 'mime'
import {
	Alert,
	Button,
	Card,
	CardBody,
	Input,
	Select,
	Spinner,
	Typography,
	Option,
	Textarea,
	Avatar,
	Dialog,
	DialogHeader,
	DialogBody,
	DialogFooter,
} from '@material-tailwind/react'
import { ErrorMessage } from '@hookform/error-message'
import { PieSearchInput } from './pie-search-input'

export default function AchievementPage() {
	//#region setup
	const diplayNameScheme = Yup.string().required('Achievement Name is required.')
	const achievementSchema = Yup.object().shape({
		slug: Yup.string().required(),
		displayName: diplayNameScheme,
		bio: Yup.string().required('Achievement Description is required.'),
		activeFromUtc: Yup.date().optional(),
		activeToUtc: Yup.date().optional(),
		maxSupply: Yup.number().optional(),
		category: Yup.string().required(),
		claimByRewardId: Yup.string().optional(),
		avatarUrl: Yup.string().required(),
		id: Yup.string().optional(),
	})

	const ACHIVEMENT_CATEGORIES = [
		{ name: 'Launch Day supporter', value: AchievementCategory.LaunchDaySupporter },
		{ name: 'OG Supporter', value: AchievementCategory.OgSupporter },
		{ name: 'Participation Badge', value: AchievementCategory.ParticipationBadge },
		{ name: 'Event badge', value: AchievementCategory.EventBadge },
		{ name: 'Brand badge', value: AchievementCategory.BrandBadge },
		{ name: 'Product Badge', value: AchievementCategory.ProductBadge },
		{ name: 'Community Badge', value: AchievementCategory.CommunityBadge },
		{ name: 'Tier Badge Emerald', value: AchievementCategory.TierBadgeEmerald },
		{ name: 'Tier Badge Ruby', value: AchievementCategory.TierBadgeRuby },
		{ name: 'Tier Badge Diamond', value: AchievementCategory.TierBadgeDiamond },
		{ name: 'Other', value: AchievementCategory.Other },
	]
	//#endregion setup

	//#region hooks
	const [achievement, setAchievement] = useState<Achievement>()
	const [achievementId, setAchievementId] = useState<string | undefined>()
	const [pieId, setPieId] = useState<string | undefined>()
	const [ownerId, setOwnerId] = useState<string | undefined>()
	const [updating, setUpdating] = useState<boolean>(false)
	const [error, setError] = useState<string>('')
	const [success, setSuccess] = useState<string>('')
	const [newAvatar, setNewAvatar] = useState<{ file: File; data: string } | null>(null)
	const [avatarCompleted, setAvatarCompleted] = useState<boolean>(false)
	const [checkingAvailableName, setCheckingAvailableName] = useState<boolean>(false)
	const [canDelete, setCanDelete] = useState<boolean>()
	const [canRecover, setCanRecover] = useState<boolean>()
	const [processing, setProcessing] = useState<boolean>()
	const [confirming, setConfirming] = useState<{
		title: string
		message: string
		callback: (confirmed: boolean) => void
	} | null>(null)

	const navigate = useNavigate()

	const avatarInput = useRef<HTMLInputElement>(null)

	const formMethodsAchievement = useForm({
		resolver: yupResolver(achievementSchema),
		mode: 'onChange',
	})
	const {
		control: controlAchievement,
		formState: { dirtyFields: achievementDirtyFields, errors, isValid: isValidForm },
		reset: resetAchievement,
		setValue: setValueAchievement,
		watch: watchAchievement,
		getValues: getValuesAchievement,
		setError: setErrorAchievement,
		clearErrors: clearErrorsAchievement,
	} = formMethodsAchievement

	const params = useParams()
	const aid = params.id
	let isNew = false
	if (aid == undefined) {
		isNew = true
	} else {
		useEffect(() => {
			setAchievementId(aid)
		}, [])
	}

	const debounceTypingAchievementName = useCallback(
		debounce(nextValue => {
			const { text, isValidated } = nextValue
			if (isValidated && ownerId) {
				return checkAchievementSlugAvailability({
					variables: { ownerId: ownerId, slug: text },
				})
			}
			setCheckingAvailableName(false)
		}, 500),
		[achievement, ownerId]
	)

	const [checkAchievementSlugAvailability, {}] = useAdminCheckAchievementSlugAvailabilityLazyQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			const result = data.admin.achievement?.slugAvailable
			if (result.isAvailable) {
				clearErrorsAchievement('displayName')
			} else {
				if (result.objectId === aid) {
					clearErrorsAchievement('displayName')
				} else {
					setErrorAchievement('displayName', {
						message: 'Achievement name is not available',
						type: 'available',
					})
				}
			}
			setCheckingAvailableName(false)
		},
		onError: error => {
			setError(trueErrorMessage(error.message))
		},
	})

	const [createOrUpdateAchievement, {}] = useCreateOrUpdateAchievementMutation({
		onCompleted: () => {
			toast.success(`Your achievement has been saved.`)
			backToAchievementsList()
		},
		onError: error => {
			toast.error(trueErrorMessage(error.message))
			setUpdating(false)
		},
	})

	const [achievementPresignUploadUrl] = useAchievementPresignUploadUrlMutation({
		onCompleted: presignUrlData => {
			const signedUrl = presignUrlData.achievement.achievementAvatar.presignUploadUrl
			uploadService
				.uploadAvatar({ presignedS3Url: signedUrl, file: newAvatar?.file })
				.then(() => {
					const url = urlParse(signedUrl, true).pathname.slice(1)
					setValueAchievement('avatarUrl', url)
					setAvatarCompleted(true)
				})
				.catch(() => {
					setError('Failed to upload achievement avatar')
				})
		},
	})

	useEffect(() => {
		if (!avatarCompleted) {
			return
		}
		const { id, slug, displayName, bio, avatarUrl, activeFromUtc, activeToUtc, maxSupply, category } =
			getValuesAchievement()
		const aid = achievementId ? id : null
		createOrUpdateAchievement({
			variables: {
				id: aid,
				slug,
				displayName,
				bio,
				avatarUrl,
				activeFromUtc,
				activeToUtc,
				maxSupply,
				category,
				updateToUser: ownerId,
				pieId: pieId,
			} as any,
		})
	}, [avatarCompleted])

	const [adminGetAchievement, { data: dataAchievement, loading: loadingAchievement, error: loadAchievementError }] =
		useAdminGetAchievemntByIdMutation()

	useEffect(() => {
		if (!isNew) {
			adminGetAchievement({
				variables: {
					achievementId: aid,
				},
			})
		}
	}, [])

	useEffect(() => {
		const achievementData = dataAchievement?.admin?.achievementById?.fullAchievement

		if (achievementData) {
			setAchievement({ ...achievementData } as Achievement)
			setOwnerId(achievementData.owner?.userId)
			setPieId(achievementData.pie?.pieId)
			resetAchievement({
				avatarUrl: achievementData.avatarUrl,
				bio: achievementData.bio,
				category: achievementData.category!,
				displayName: achievementData.displayName,
				slug: achievementData.slug,
				id: achievementData.achievementId,
			})
			setCanDelete(!achievementData.isDeleted)
			setCanRecover(achievementData.isDeleted)
		} else if (!loadingAchievement === false) {
			backToAchievementsList()
		}
	}, [dataAchievement])

	useEffect(() => {
		if (achievementDirtyFields['category']) {
			const url = watchAchievement('category')
				? `${MEDIA_FOLDER.achievementAvatars}/${upperFirst(camelCase(watchAchievement('category')))}.png`
				: ''

			setNewAvatar(null)
			setValueAchievement('avatarUrl', url, {
				shouldValidate: true,
			})
		}
	}, [watchAchievement('category'), achievementDirtyFields])

	useEffect(() => {
		setValueAchievement('slug', slugify(watchAchievement('displayName') || ''))
	}, [watchAchievement('displayName')])

	const [deleteAchievementMutation] = useAdminDeleteAchievementMutation()
	const [recoverAchievementMutation] = useAdminRecoverAchievementMutation()
	//#endregion hooks

	//#region functions
	const backToAchievementsList = () => {
		navigate('/dashboard/achievements')
	}

	const resolveAvatarUrl = (src: string) => {
		let url = src
		if (!url) {
			url = DEFAULT_INFORMATION.rewardImageUrl
		} else {
			if (!isUrlHttp(url) && url.indexOf('data:') === -1) {
				url = `${process.env.REACT_APP_MEDIA_URL}/${src}`
			}
		}

		return url
	}

	const handleKeyUp = async (e: any) => {
		const { value } = e.target
		const slug = slugify(value || '')
		const valid = await diplayNameScheme.isValid(value)
		debounceTypingAchievementName({ text: slug, isValidated: valid })
		setCheckingAvailableName(true)
	}

	const onAvatarSelected = (files: FileList | null) => {
		if (!files || !files.length) {
			return
		}
		var reader = new FileReader()
		reader.onload = function () {
			var dataURL = reader.result
			setNewAvatar({
				data: dataURL?.toString()!,
				file: files[0],
			})
		}
		reader.readAsDataURL(files[0])
	}

	const save = () => {
		setError('')
		setSuccess('')

		if (!isValidForm || checkingAvailableName || JSON.stringify(errors) !== '{}') {
			setError('Please solve all validation errors')
			return
		}

		if (newAvatar && newAvatar.file) {
			const file = newAvatar.file
			const { type, size } = file
			const extension = mime.getExtension(type) as string
			achievementPresignUploadUrl({
				variables: {
					contentLength: size,
					contentType: type,
					extension: extension,
				},
			})
		} else {
			setAvatarCompleted(true)
		}

		setUpdating(true)
	}

	const deleteAchievement = () => {
		setConfirming({
			title: 'Delete achievement confirmation',
			message: 'Are you sure to delete this achievement?',
			callback: confirmed => {
				if (confirmed) {
					setProcessing(true)
					deleteAchievementMutation({
						variables: {
							achievementId: achievementId,
						},
						onCompleted: () => {
							toast.success(`The achievement has been deleted.`)
							setProcessing(true)
							backToAchievementsList()
						},
						onError: error => {
							toast.error(trueErrorMessage(error))
							setProcessing(false)
						},
					})
				}
			},
		})
	}

	const recover = () => {
		setConfirming({
			title: 'Recover achievement confirmation',
			message: 'Are you sure to recover this achievement?',
			callback: confirmed => {
				if (confirmed) {
					setProcessing(true)
					recoverAchievementMutation({
						variables: {
							achievementId: achievementId,
						},
						onCompleted: () => {
							toast.success(`The achievement has been recover.`)
							setProcessing(true)
							backToAchievementsList()
						},
						onError: error => {
							toast.error(trueErrorMessage(error))
							setProcessing(false)
						},
					})
				}
			},
		})
	}

	//#endregion functions
	if (!isNew && (aid == undefined || !aid.length)) {
		backToAchievementsList()
	}

	if (loadingAchievement || (!achievement && !isNew) || updating || processing) {
		return (
			<div className="flex justify-center items-center py-4">
				<Spinner className="h-12 w-12" />
			</div>
		)
	}

	if (loadAchievementError) {
		return (
			<div className="py-4">
				<div className="flex justify-center items-center py-4">
					<Typography color="red">
						Could not load achievement information due to server error. Please try again.
					</Typography>
				</div>
				<div className="flex justify-center items-center py-4">
					<NavLink to={'/dashboard/achievements'}>
						<Button variant="outlined" className="flex items-center gap-3">
							Back to Achievements list
						</Button>
					</NavLink>
				</div>
			</div>
		)
	}

	return (
		<>
			{confirming && (
				<Dialog open={!!confirming} handler={() => {}} size="xs">
					<DialogHeader>{confirming.title}</DialogHeader>
					<DialogBody>{confirming.message}</DialogBody>
					<DialogFooter>
						<div className="flex gap-6">
							<Button
								onClick={() => {
									confirming.callback(false)
									setConfirming(null)
								}}
							>
								Cancel
							</Button>
							<Button
								onClick={() => {
									confirming.callback(true)
									setConfirming(null)
								}}
							>
								Confirm
							</Button>
						</div>
					</DialogFooter>
				</Dialog>
			)}
			<Card className="mb-6">
				<CardBody className="p-4">
					{(() => {
						if (error) {
							return (
								<div className="flex w-full flex-col gap-2 mb-2">
									<Alert color="red" onClose={() => setError('')}>
										{error}
									</Alert>
								</div>
							)
						}
						if (success) {
							return (
								<div className="flex w-full flex-col gap-2 mb-2">
									<Alert color="green" onClose={() => setSuccess('')}>
										{success}
									</Alert>
								</div>
							)
						}
					})()}
					<form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
						<div className="mb-1 flex flex-col gap-6">
							<Typography variant="h5" color="blue-gray" className="-mb-3">
								Achievement Details
							</Typography>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Pie
							</Typography>
							{!isNew && (
								<div className="flex gap-2">
									<Avatar
										src={resolveAvatarUrl(achievement?.pie?.truePieAvatarUrl ?? '')}
										variant="square"
									/>
									<Typography className="self-center">{achievement?.pie?.displayName}</Typography>
								</div>
							)}
							{isNew && (
								<PieSearchInput
									placeholder={'Select a pie'}
									autoSuggestion
									onValueChange={(values: any) => {
										setPieId(values.pieId)
										setOwnerId(values.createdBy.userId)
									}}
									excludeValues={[]}
								/>
							)}
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Achievement Type
							</Typography>
							<Controller
								control={controlAchievement}
								name="category"
								render={({ field }) => (
									<Select
										onChange={(option: any) => {
											field.onChange(option)
										}}
										variant="outlined"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										value={field.value}
									>
										{ACHIVEMENT_CATEGORIES.map((option: any, index: number) => (
											<Option key={index} value={option.value}>
												{option.name}
											</Option>
										))}
									</Select>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="category"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										<span>{message}</span>
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Avatar
							</Typography>
							<div style={{ width: '100%', paddingBottom: '100%', position: 'relative' }}>
								<img
									className="object-cover object-center"
									style={{
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
									}}
									src={resolveAvatarUrl(
										newAvatar?.data ? newAvatar.data : watchAchievement('avatarUrl')
									)}
									alt="achievement avatar"
									onClick={() => {
										avatarInput.current?.click()
									}}
								/>
							</div>
							<div className="hidden">
								<input
									ref={avatarInput}
									type="file"
									onChange={event => {
										onAvatarSelected(event.target.files)
									}}
								/>
							</div>
							<Typography variant="h6" color="blue-gray" className="-mb-3 flex flex-row">
								Name {checkingAvailableName && <Spinner className="ml-6 h6 w6" />}
							</Typography>
							<Controller
								control={controlAchievement}
								name="displayName"
								render={({ field }) => (
									<Input
										crossOrigin={undefined}
										size="lg"
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										onChange={field.onChange}
										onKeyUp={handleKeyUp}
										value={field.value ?? ''}
									/>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="displayName"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										<span>{message}</span>
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Description
							</Typography>
							<Controller
								control={controlAchievement}
								name="bio"
								render={({ field }) => (
									<Textarea
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										value={field.value}
										onChange={field.onChange}
									/>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="bio"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										<span>{message}</span>
									</Typography>
								)}
							/>
						</div>
						<div className="flex flex-col gap-6 mt-6">
							<Button
								fullWidth
								onClick={save}
								disabled={!isValidForm || checkingAvailableName || JSON.stringify(errors) !== '{}'}
							>
								Save
							</Button>
							{canDelete && (
								<Button
									className="self-end"
									color="deep-orange"
									onClick={deleteAchievement}
									disabled={processing}
								>
									Delete
								</Button>
							)}
							{canRecover && (
								<Button
									className="self-end"
									color="deep-orange"
									onClick={recover}
									disabled={processing}
								>
									Recover
								</Button>
							)}
						</div>
					</form>
				</CardBody>
			</Card>
		</>
	)
}
