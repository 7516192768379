import { Reward, useAdminGetPieRewardsQuery } from '@/graphql/generated/scheme'
import { ArrowLeftIcon, ArrowRightIcon, PencilIcon } from '@heroicons/react/24/outline'
import { EyeIcon } from '@heroicons/react/24/solid'
import {
	Avatar,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	IconButton,
	Tooltip,
	Typography,
} from '@material-tailwind/react'
import { format } from 'date-fns'
import isUrlHttp from 'is-url-http'
import { useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import RewardParticipantDetailsDialog from './reward-participants-details-dialog'

export default function PieRewardList() {
	const [pie, setPie] = useState<{ pieId: string; displayName: string }>()
	const [rewards, setRewards] = useState<Reward[]>([])
	const [pageInfo, setPageInfo] = useState<{
		total: number
		totalPage: number
		currentPage: number
		offset: number
		limit: number
	}>({
		total: 0,
		totalPage: 0,
		currentPage: 1,
		offset: 0,
		limit: 10,
	})
	const [viewingReward, setViewingReward] = useState<string | null>(null)

	const navigate = useNavigate()
	const { pieId } = useParams()

	const backToPie = () => {
		navigate(`/dashboard/pies/${pieId}`)
	}

	function resolveAvatarUrl(src: string) {
		let url = src
		if (!url) {
			url = '/img/default/avatar.png'
		} else {
			if (!isUrlHttp(url) && url.indexOf('data:') === -1) {
				url = `${process.env.REACT_APP_MEDIA_URL}/${src}`
			}
		}

		return url
	}

	function previousClick() {
		const offset = pageInfo.offset - pageInfo.limit
		const currPage = pageInfo.currentPage - 1
		setPageInfo({
			...pageInfo,
			offset: offset,
			currentPage: currPage,
		})
		refetchPieRewards({ pieId: pieId, limit: pageInfo.limit, offset: offset })
	}

	function nextClick() {
		const offset = pageInfo.offset + pageInfo.limit
		const currPage = pageInfo.currentPage + 1
		setPageInfo({
			...pageInfo,
			offset: offset,
			currentPage: currPage,
		})
		refetchPieRewards({ pieId: pieId, limit: pageInfo.limit, offset: offset })
	}

	function goToPage(pageNumber: number) {
		const offset = (pageNumber - 1) * pageInfo.limit
		const currPage = pageNumber
		setPageInfo({
			...pageInfo,
			offset: offset,
			currentPage: currPage,
		})
		refetchPieRewards({ pieId: pieId, limit: pageInfo.limit, offset: offset })
	}

	const {
		data: dataPieRewards,
		loading: loadingPieRewards,
		refetch: refetchPieRewards,
	} = useAdminGetPieRewardsQuery({
		variables: {
			pieId: pieId,
			limit: pageInfo.limit,
			offset: pageInfo.offset,
		},
	})

	useEffect(() => {
		const pieRewardsData = dataPieRewards?.admin?.pie?.byId

		if (pieRewardsData) {
			setPie({ displayName: pieRewardsData!.displayName, pieId: pieRewardsData!.pieId })
			setRewards(pieRewardsData.rewardList?.nodes.map(x => x as Reward) ?? ([] as Reward[]))
			const pageInfoData = {
				...pageInfo,
				total: pieRewardsData.rewardList?.totalCount,
				totalPage: Math.ceil(pieRewardsData.rewardList?.totalCount / pageInfo.limit),
			}
			setPageInfo(pageInfoData)
		} else if (!loadingPieRewards) {
			backToPie()
		}
	}, [dataPieRewards])

	return (
		<>
			{viewingReward ? (
				<RewardParticipantDetailsDialog
					key={viewingReward}
					rewardId={viewingReward}
					onClose={() => setViewingReward(null)}
				></RewardParticipantDetailsDialog>
			) : null}
			<Card className="mb-6 h-full w-full">
				<CardHeader floated={false} shadow={false} className="rounded-none">
					<div className="flex items-center justify-between gap-8">
						<div>
							<Typography variant="h5" color="blue-gray">
								Rewards list
							</Typography>
							<div className="mt-5">
								<NavLink to={`/dashboard/pies/${pieId}`}>
									<Button variant="outlined" className="flex items-center gap-3">
										Back to {pie?.displayName ?? 'pie'}
									</Button>
								</NavLink>
							</div>
						</div>
					</div>
				</CardHeader>
				<CardBody className="overflow-x-auto px-0">
					<table className="mt-4 w-full min-w-max table-auto text-left">
						<thead>
							<tr>
								{[
									{ display: 'Display Name', key: 'displayName' },
									{ display: 'Slug', key: 'slug' },
									{ display: 'Created Date', key: 'createdDate' },
									{ display: 'Start Date', key: 'startDate' },
									{ display: 'End Date', key: 'endDate' },
									{ display: 'Status', key: 'status' },
									{ display: 'Is Expired', key: 'isExpired' },
									{ display: 'Is Hidden', key: 'isHidden' },
									{ display: 'Total Participants', key: 'totalParticipants' },
									{ display: 'Total Unclocked Users', key: 'totalUnlockedUsers' },
									{ display: '', key: 'actions' },
								].map(el => (
									<th key={el.key} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-normal leading-none opacity-70"
										>
											<span>{el.display}</span>
										</Typography>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{(rewards ?? []).map((reward: Reward, index: number) => {
								const isLast = index === rewards.length - 1
								const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50'

								return (
									<tr key={reward.rewardId}>
										<td className={classes}>
											<div className="flex items-center gap-3">
												<Avatar
													src={resolveAvatarUrl(reward.avatarUrl ?? '')}
													alt={reward.displayName ?? ''}
													size="sm"
													variant="square"
												/>
												<div className="flex flex-col">
													<Typography
														variant="small"
														color="blue-gray"
														className="font-normal"
													>
														{reward.displayName ?? ''}
													</Typography>
												</div>
											</div>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{reward.slug ?? ''}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{reward.createdUtc
													? format(new Date(reward.createdUtc), 'dd MMM yyyy')
													: ''}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{reward.startDate
													? format(new Date(reward.startDate), 'dd MMM yyyy HH:mm:ss')
													: ''}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{reward.endDate
													? format(new Date(reward.endDate), 'dd MMM yyyy HH:mm:ss')
													: ''}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{reward.status ?? ''}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{reward.isExpired ? 'Yes' : 'No'}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{reward.isHidden ? 'Yes' : 'No'}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{new Intl.NumberFormat('en-US').format(reward.totalParticipants ?? 0)}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{new Intl.NumberFormat('en-US').format(reward.totalUnlockedUser ?? 0)}
											</Typography>
										</td>
										<td className={classes}>
											<Tooltip content="View detail participants">
												<IconButton
													variant="text"
													onClick={() => setViewingReward(reward.rewardId)}
												>
													<EyeIcon className="h-4 w-4" />
												</IconButton>
											</Tooltip>
											<Tooltip content="Edit reward">
												<NavLink to={`/dashboard/rewards/${reward.rewardId}`}>
													<IconButton variant="text">
														<PencilIcon className="h-4 w-4" />
													</IconButton>
												</NavLink>
											</Tooltip>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</CardBody>
				<CardFooter className="flex items-center justify-center border-t border-blue-gray-50 p-4">
					<div className="flex justify-center gap-8">
						<IconButton
							size="sm"
							variant="outlined"
							onClick={previousClick}
							disabled={pageInfo.currentPage === 1}
						>
							<ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
						</IconButton>
						<Typography color="gray" className="font-normal">
							Page{' '}
							<select
								value={pageInfo.currentPage}
								onChange={e => goToPage(Number.parseInt(e.target.value))}
								style={{ fontWeight: 'bold', border: '1px solid', padding: '2px 2px 2px 6px' }}
							>
								{Array.from({ length: pageInfo.totalPage }, (_, i) => i + 1).map(page => {
									return (
										<option key={page} value={page}>
											{page}
										</option>
									)
								})}
							</select>{' '}
							of <strong className="text-gray-900">{pageInfo.totalPage}</strong>
						</Typography>
						<IconButton
							size="sm"
							variant="outlined"
							onClick={nextClick}
							disabled={pageInfo.currentPage === pageInfo.totalPage}
						>
							<ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
						</IconButton>
					</div>
				</CardFooter>
			</Card>
		</>
	)
}
