import humanNumber from 'human-number'

export const numberFormat = (number: number, force2Decimal?: boolean, isHumanNumber?: boolean) => {
	if (number === 0) {
		return `${number}`
	}
	const options: any = {}
	if (force2Decimal) {
		if (isHumanNumber) {
			return humanNumber(number, n => {
				let number = n
				if (number > 100) {
					number = Math.floor(number)
				}
				return Number.parseFloat(number.toPrecision(3))
					.toFixed(2)
					.replace(/[.,]?0+$/, '')
			})
		}
		options.minimumFractionDigits = 2
	}
	options.maximumFractionDigits = 2
	if (isHumanNumber) {
		return humanNumber(number)
	}
	return new Intl.NumberFormat(undefined, options).format(number)
}

export const currencyFormat = (
	number: number,
	unit: string = '$',
	force2Decimal: boolean = true,
	isHumanNumber?: boolean
) => {
	return `${unit}${numberFormat(number, force2Decimal, isHumanNumber)}`
}

export const volatilityFormat = (number: number, force2Decimal: boolean = true) => {
	if (number > 0) {
		return `+${numberFormat(number, force2Decimal)}`
	}
	return numberFormat(number, force2Decimal)
}

export const slugify = (str: string) =>
	str
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, '')
		.replace(/[\s_-]+/g, '-')
		.replace(/^-+|-+$/g, '')

export const isShowRank = (rank: any) => {
	return rank && rank > 0 && rank <= 1000
}

export const toCamelCase = (_key: any, value: any) => {
	if (value && typeof value === 'object') {
		for (const k in value) {
			if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
				value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k]
				delete value[k]
			}
		}
	}
	return value
}

export const todayUTC = () => {
	const localDate = new Date()
	return new Date(localDate.toISOString())
}

export const camelCaseToWords = (text: string) => {
	return text.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export const toProperCase = (text: string) => {
	return text.replace(/\w\S*/g, txt => {
		return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
	})
}

export const toOrdinalNumber = (i: number) => {
	let j = i % 10,
		k = i % 100
	if (j == 1 && k != 11) {
		return i + 'st'
	}
	if (j == 2 && k != 12) {
		return i + 'nd'
	}
	if (j == 3 && k != 13) {
		return i + 'rd'
	}
	return i + 'th'
}
