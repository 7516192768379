import axios from 'axios'

export const uploadAvatar = (data: any) => {
	const { type, size } = data.file
	return axios.put(data.presignedS3Url, data.file, {
		headers: {
			'Content-Type': type,
			'Content-Length': size,
		},
	})
}
