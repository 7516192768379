import { createContext, useContext, useMemo, useState } from 'react'

import { useLocalStorage } from './use-local-storage'
import * as authService from '@/services/auth'
import { AuthResponse } from '@/models/response'
import { trueErrorMessage } from '@/utils/error'

const AuthContext = createContext<any>(null)

export const AuthProvider = ({ children }: any) => {
	const [token, setToken] = useLocalStorage('token', null)
	const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', null)
	const [authInfo, setAuthInfo] = useLocalStorage('authInfo', null)
	const [registerUserName, setRegisterUserName] = useState<any>(null)
	const [requireOtp, setRequireOtp] = useState<any>(null)

	const clearAuth = () => {
		setToken(null)
		setRefreshToken(null)
		setAuthInfo(null)
		setRequireOtp(null)
		localStorage.clear()
	}

	const register = (data: any, onSuccess: (data: AuthResponse) => void, onError: (reason: string) => void) => {
		setRegisterUserName(data.userName)
		authService
			.passwordRegister(data)
			.then((response: any) => {
				setToken(response.token)
				setRefreshToken(response.refreshToken)
				setAuthInfo(response)

				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const resendRegisterEmail = (onSuccess: (data: AuthResponse) => void, onError: (reason: string) => void) => {
		authService
			.passwordResendRegisterEmail({ userName: registerUserName, refreshToken })
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const login = (data: any, onSuccess: (data: AuthResponse) => void, onError: (reason: string) => void) => {
		authService
			.passwordSignIn(data)
			.then((response: any) => {
				if (response.isAdmin) {
					if (response.skipMultiFactorAuthentication) {
						setRequireOtp(false)
						setToken(response.token)
					} else {
						setRequireOtp(true)
					}
					setRefreshToken(response.refreshToken)
					setAuthInfo(response)

					onSuccess(response)
				} else {
					onError('User is not authorized to access this resource')
				}
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const verifyOtp = (
		otp: string,
		purpose: string,
		onSuccess: (data: AuthResponse) => void,
		onError: (reason: string) => void
	) => {
		authService
			.verifyOtp({ refreshToken, otp, purpose })
			.then((response: any) => {
				setRequireOtp(false)
				setToken(response.token)
				setRefreshToken(response.refreshToken)
				setAuthInfo(response)

				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const resendOtp = (onSuccess: (data: AuthResponse) => void, onError: (reason: string) => void) => {
		authService
			.resendOtp({ refreshToken })
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const verifyEmailToken = (
		token: string,
		onSuccess: (data: AuthResponse) => void,
		onError: (reason: string) => void
	) => {
		authService
			.verifyEmailToken({ refreshToken: token })
			.then((response: any) => {
				setRequireOtp(false)
				setToken(response.token)
				setRefreshToken(response.refreshToken)
				setAuthInfo(response)

				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const validateToken = (
		token: string,
		onSuccess: (data: AuthResponse) => void,
		onError: (reason: string) => void
	) => {
		authService
			.validateToken({ token })
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const checkEmailAvailability = (
		data: any,
		onSuccess: (data: AuthResponse) => void,
		onError: (reason: string) => void
	) => {
		authService
			.checkEmailAvailability(data)
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const logout = (
		onSuccess?: (data: AuthResponse) => void,
		onError?: (reason: string) => void,
		onFinal?: () => void
	) => {
		authService
			.logout()
			.then((response: any) => {
				onSuccess?.(response)
			})
			.catch((error: any) => {
				onError?.(trueErrorMessage(error.data))
			})
			.finally(() => {
				onFinal?.()
			})
	}

	const forgetPassword = (data: any, onSuccess: (data: any) => void, onError: (reason: string) => void) => {
		authService
			.passwordForget(data)
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const recoverPassword = (data: any, onSuccess: (data: AuthResponse) => void, onError: (reason: string) => void) => {
		authService
			.passwordRecover(data)
			.then((response: any) => {
				setRequireOtp(true)
				setRefreshToken(response.refreshToken)

				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const startUpdatePassword = (onSuccess: (data: any) => void, onError: (reason: string) => void) => {
		authService
			.startUpdatePassword()
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const updatePassword = (data: any, onSuccess: (data: any) => void, onError: (reason: string) => void) => {
		authService
			.updatePassword(data)
			.then((response: any) => {
				onSuccess(response)
			})
			.catch((error: any) => onError(trueErrorMessage(error.data)))
	}

	const value = useMemo(
		() => ({
			requireOtp,
			token,
			refreshToken,
			authInfo,
			setToken,
			setRefreshToken,
			setAuthInfo,
			register,
			resendRegisterEmail,
			login,
			verifyOtp,
			resendOtp,
			verifyEmailToken,
			logout,
			forgetPassword,
			recoverPassword,
			clearAuth,
			validateToken,
			checkEmailAvailability,
			startUpdatePassword,
			updatePassword,
		}),
		[requireOtp, token, refreshToken, JSON.stringify(authInfo)]
	)
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
	return useContext(AuthContext)
}
