import api from '@/api'
import { AuthResponse } from '@/models/response'

export const setToken = (token: any): void => {
	if (token) {
		localStorage.setItem('token', token)
	} else {
		localStorage.removeItem('token')
	}
}

export const getToken = (): string | any => {
	return JSON.parse(localStorage.getItem('token') as string)
}

export const setRefreshToken = (token: any): void => {
	if (token) {
		localStorage.setItem('refreshToken', token)
	} else {
		localStorage.removeItem('refreshToken')
	}
}

export const getRefreshToken = (): string | any => {
	return localStorage.getItem('refreshToken')
}

export const passwordRegister = (data: any) => {
	return api.post<AuthResponse>('/auth/password/register', data)
}

export const passwordResendRegisterEmail = (data: any) => {
	return api.post<AuthResponse>('/auth/password/resend-register-email', data)
}

export const passwordSignIn = (data: any) => {
	return api.post<AuthResponse>('/auth/password/sign-in', data)
}

export const verifyOtp = (data: any) => {
	return api.post<AuthResponse>('/auth/password/verify-otp', data)
}

export const resendOtp = (data: any) => {
	return api.post<AuthResponse>('/auth/password/resend-otp', data)
}

export const verifyEmailToken = (data: any) => {
	return api.post<AuthResponse>('/auth/password/verify-email-token', data)
}

export const validateToken = (data: any) => {
	const { token } = data
	return api.post<any>(`/auth/validate-token?token=${token}`)
}

export const checkEmailAvailability = (data: any) => {
	return api.post<any>('/auth/password/check-email-availability', data)
}

export const startUpdatePassword = () => {
	return api.post<any>('/auth/password/start-update-password')
}

export const updatePassword = (data: any) => {
	return api.post<any>('/auth/password/update-password', data)
}

export const logout = () => {
	return api.post('/auth/sign-out')
}

export const refresh = (refreshToken: string) => {
	return api.post<AuthResponse>('/auth/refresh', { refreshToken })
}

export const passwordForget = (data: any) => {
	return api.post<any>('/auth/password', data)
}

export const passwordRecover = (data: any) => {
	return api.post<any>('/auth/password/recover', data)
}
