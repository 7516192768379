import {
	Card,
	CardBody,
	Avatar,
	Typography,
	Button,
	Spinner,
	IconButton,
	Dialog,
	DialogHeader,
	DialogBody,
	DialogFooter,
	Input,
	Alert,
	Radio,
} from '@material-tailwind/react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
	CompletePieWithBalance,
	PieUpdateSlicesArgsInput,
	useAdminCompletePieAvatarUploadMutation,
	useAdminGetPieQuery,
	useAdminPresignedPieAvatarUploadUrlMutation,
	useAdminSetPieDisplayNameMutation,
	useAdminSetPieMaxSlicesMutation,
	useAdminSetPieVisibleMutation,
	useAdminUpdatePieSlicesMutation,
} from '@/graphql/generated/scheme'
import isUrlHttp from 'is-url-http'
import mime from 'mime'
import urlParse from 'url-parse'
import { PencilIcon } from '@heroicons/react/24/solid'
import * as uploadService from '../../services/upload'
import { slugify } from '@/utils/common'
import { trueErrorMessage } from '@/utils/error'
import { NumericFormat } from 'react-number-format'

export function Pie() {
	const [pie, setPie] = useState<CompletePieWithBalance>()
	const [editing, setEditing] = useState<{
		isEditing: boolean
		field?: string
		fieldDisplayName?: string
		header?: string
	}>({
		isEditing: false,
	})
	const [editingValue, setEditingValue] = useState<any>(null)
	const [updating, setUpdating] = useState<boolean>(false)
	const [error, setError] = useState<string>('')
	const [success, setSuccess] = useState<string>('')

	const navigate = useNavigate()
	const { id } = useParams()

	const backToPiesList = () => {
		navigate('/dashboard/pies')
	}

	const resolveAvatarUrl = (src: string) => {
		let url = src
		if (!url) {
			url = '/img/default/avatar.png'
		} else {
			if (!isUrlHttp(url) && url.indexOf('data:') === -1) {
				url = `${process.env.REACT_APP_MEDIA_URL}/${src}`
			}
		}

		return url
	}

	const edit = (field?: string, fieldDisplayName?: string, defaultValue?: any, header?: string) => {
		if (!field) {
			setEditing({ isEditing: false })
			return
		}

		setEditing({ isEditing: true, field, fieldDisplayName, header })
		setEditingValue(defaultValue)
	}

	const [presignUrl, { loading: presignUrlLoading, error: presignUrlError, data: presignUrlData }] =
		useAdminPresignedPieAvatarUploadUrlMutation()

	const [completeUpload, { loading: completeUploadLoading, error: completeUploadError, data: completeUploadData }] =
		useAdminCompletePieAvatarUploadMutation()

	const [
		updateDisplayNameSlug,
		{ loading: updateDisplayNameSlugLoading, error: updateDisplayNameSlugError, data: updateDisplayNameSlugData },
	] = useAdminSetPieDisplayNameMutation()

	const [
		updatePieSlices,
		{ loading: updatePieSlicesLoading, error: updatePieSlicesError, data: updatePieSlicesData },
	] = useAdminUpdatePieSlicesMutation()

	const [
		updatePieVisible,
		{ loading: updatePieVisibleLoading, error: updatePieVisibleError, data: updatePieVisibleData },
	] = useAdminSetPieVisibleMutation()

	const [
		updateMaxSlices,
		{ loading: updateMaxSlicesLoading, error: updateMaxSlicesError, data: updateMaxSlicesData },
	] = useAdminSetPieMaxSlicesMutation()

	const save = () => {
		const editingField = editing.field
		const value = editingValue
		setEditing({ isEditing: false })
		setError('')
		setSuccess('')

		switch (editingField) {
			case 'Avatar':
				if (!value || !value.length) {
					break
				}
				const file = (value as FileList)[0]
				const { type, size } = file
				const extension = mime.getExtension(type) as string
				presignUrl({
					variables: { pieId: pie?.pieId, contentType: type, extension, contentLength: size },
				})
				break
			case 'DisplayName':
				updateDisplayNameSlug({
					variables: {
						pieId: pie?.pieId,
						displayName: value,
						slug: slugify(value),
					},
				})
				break
			case 'MaxSlices':
				updateMaxSlices({
					variables: {
						pieId: pie?.pieId,
						maxSlices: Number.parseFloat(value),
					},
				})
				break
			case 'Slices':
				updatePieSlices({
					variables: {
						pieId: pie?.pieId,
						slices: value,
					},
				})
				break
			case 'IsVisible':
				updatePieVisible({
					variables: {
						pieId: pie?.pieId,
						isVisible: value,
					},
				})
				break
			default:
				break
		}
	}

	if (id == undefined || !id.length) {
		backToPiesList()
	}

	const {
		data: dataPie,
		loading: loadingPie,
		refetch: refetchPie,
		error: loadPieError,
	} = useAdminGetPieQuery({
		variables: {
			pieId: id,
		},
	})

	useEffect(() => {
		const pieData = dataPie?.admin?.pie?.byId

		if (pieData) {
			setPie({ ...pieData } as CompletePieWithBalance)
		} else if (!loadingPie) {
			backToPiesList()
		}
	}, [dataPie])

	useEffect(() => {
		if (
			presignUrlLoading ||
			completeUploadLoading ||
			updateMaxSlicesLoading ||
			updatePieSlicesLoading ||
			updatePieVisibleLoading ||
			updateDisplayNameSlugLoading
		) {
			setUpdating(true)
			return
		}
		setUpdating(false)
	}, [
		presignUrlLoading,
		completeUploadLoading,
		updateMaxSlicesLoading,
		updatePieSlicesLoading,
		updatePieVisibleLoading,
		updateDisplayNameSlugLoading,
	])

	useEffect(() => {
		if (presignUrlData) {
			const file = (editingValue as FileList)[0]
			const signedUrl = presignUrlData.admin.pieById.presignUploadAvatarUrl
			setUpdating(true)
			uploadService
				.uploadAvatar({ presignedS3Url: signedUrl, file: file })
				.then(() => {
					const url = urlParse(signedUrl, true).pathname.slice(1)
					completeUpload({
						variables: {
							pieId: pie?.pieId,
							avatarUrl: url,
						},
					})
				})
				.catch(() => {
					setError(trueErrorMessage(`Upload failure!`))
					setUpdating(false)
				})
		}
	}, [presignUrlData])

	useEffect(() => {
		if (completeUploadData || updatePieSlicesData || updatePieVisibleData || updateMaxSlicesData) {
			refetchPie({ pieId: pie?.pieId })
		}
	}, [completeUploadData, updatePieSlicesData, updatePieVisibleData, updateDisplayNameSlugData, updateMaxSlicesData])

	useEffect(() => {
		if (completeUploadData) {
			setSuccess('Update pie avatar successfully')
		}
	}, [completeUploadData])

	useEffect(() => {
		if (presignUrlError) {
			setError(trueErrorMessage(presignUrlError.message))
		}
	}, [presignUrlError])

	useEffect(() => {
		if (completeUploadError) {
			setError(trueErrorMessage(completeUploadError.message))
		}
	}, [completeUploadError])

	useEffect(() => {
		if (updateMaxSlicesData) {
			setSuccess('Update max slices successfully')
		}
	}, [updateMaxSlicesData])

	useEffect(() => {
		if (updateMaxSlicesError) {
			setError(trueErrorMessage(updateMaxSlicesError))
		}
	}, [updateMaxSlicesError])

	useEffect(() => {
		if (updatePieSlicesData) {
			setSuccess('Update pie slices successfully')
		}
	}, [updatePieSlicesData])

	useEffect(() => {
		if (updatePieSlicesError) {
			setError(trueErrorMessage(updatePieSlicesError.message))
		}
	}, [updatePieSlicesError])

	useEffect(() => {
		if (updatePieVisibleData) {
			setSuccess('Update pie is visible successfully')
		}
	}, [updatePieVisibleData])

	useEffect(() => {
		if (updatePieVisibleError) {
			setError(trueErrorMessage(updatePieVisibleError.message))
		}
	}, [updatePieVisibleError])

	useEffect(() => {
		if (updateDisplayNameSlugData) {
			setSuccess('Update pie display name and slug successfully')
		}
	}, [updateDisplayNameSlugData])

	useEffect(() => {
		if (updateDisplayNameSlugError) {
			setError(trueErrorMessage(updateDisplayNameSlugError.message))
		}
	}, [updateDisplayNameSlugError])

	if (loadingPie || !pie || updating) {
		return (
			<div className="flex justify-center items-center py-4">
				<Spinner className="h-12 w-12" />
			</div>
		)
	}

	if (loadPieError) {
		return (
			<div className="py-4">
				<div className="flex justify-center items-center py-4">
					<Typography color="red">
						Could not load pie information due to server error. Please try again.
					</Typography>
				</div>
				<div className="flex justify-center items-center py-4">
					<NavLink to={'/dashboard/pies'}>
						<Button variant="outlined" className="flex items-center gap-3">
							Back to Pies list
						</Button>
					</NavLink>
				</div>
			</div>
		)
	}

	return (
		<>
			<Dialog open={editing.isEditing} handler={edit}>
				<DialogHeader>{editing.header ? editing.header : `Update ${editing.fieldDisplayName}`}</DialogHeader>
				<DialogBody divider>
					{(() => {
						switch (editing.field) {
							case 'Avatar':
								return (
									<Input
										type="file"
										label={editing.fieldDisplayName}
										crossOrigin={undefined}
										onChange={event => {
											setEditingValue(event.target.files)
										}}
									/>
								)
							case 'DisplayName':
								return (
									<>
										<Input
											type="text"
											label={editing.fieldDisplayName}
											crossOrigin={undefined}
											onChange={event => {
												setEditingValue(event.target.value)
											}}
											value={editingValue}
										/>
										<br />
										<Input
											readOnly
											type="text"
											label="Slug"
											crossOrigin={undefined}
											value={slugify(editingValue)}
										/>
									</>
								)
							case 'IsVisible':
								return (
									<>
										<Radio
											name="editingYesNo"
											label="Yes"
											defaultChecked={!!editingValue}
											onChange={() => {
												setEditingValue(true)
											}}
											crossOrigin={undefined}
										/>
										<Radio
											name="editingYesNo"
											label="No"
											defaultChecked={!editingValue}
											onChange={() => {
												setEditingValue(false)
											}}
											crossOrigin={undefined}
										/>
									</>
								)
							case 'MaxSlices':
								return (
									<NumericFormat
										thousandSeparator={true}
										min={0}
										value={editingValue}
										onValueChange={(e: any) => {
											setEditingValue(e.floatValue)
										}}
										customInput={Input}
										crossOrigin={undefined}
										label={editing.fieldDisplayName}
									/>
								)
							case 'Slices':
								const slices = editingValue as PieUpdateSlicesArgsInput
								return (
									<div className="mb-1 flex flex-col gap-6">
										<NumericFormat
											thousandSeparator={true}
											min={0}
											value={slices.issuedSlices}
											onValueChange={(e: any) => {
												setEditingValue({
													...slices,
													issuedSlices: e.floatValue,
												})
											}}
											customInput={Input}
											crossOrigin={undefined}
											label="Total Slices"
										/>
										<NumericFormat
											thousandSeparator={true}
											min={0}
											value={slices.holdingSlices}
											onValueChange={(e: any) => {
												setEditingValue({
													...slices,
													holdingSlices: e.floatValue,
												})
											}}
											customInput={Input}
											crossOrigin={undefined}
											label="Holding Slices"
										/>
										<NumericFormat
											thousandSeparator={true}
											min={0}
											value={slices.availableSlices}
											onValueChange={(e: any) => {
												setEditingValue({
													...slices,
													availableSlices: e.floatValue,
												})
											}}
											customInput={Input}
											crossOrigin={undefined}
											label="Slices for Sale"
										/>
										<NumericFormat
											thousandSeparator={true}
											min={0}
											value={slices.soldSlices}
											onValueChange={(e: any) => {
												setEditingValue({
													...slices,
													soldSlices: e.floatValue,
												})
											}}
											customInput={Input}
											crossOrigin={undefined}
											label="Sold Slices"
										/>
										<NumericFormat
											thousandSeparator={true}
											min={0}
											value={slices.sentSlices}
											onValueChange={(e: any) => {
												setEditingValue({
													...slices,
													sentSlices: e.floatValue,
												})
											}}
											customInput={Input}
											crossOrigin={undefined}
											label="Sent Slices"
										/>
										<NumericFormat
											thousandSeparator={true}
											min={0}
											value={slices.receivedSlices}
											onValueChange={(e: any) => {
												setEditingValue({
													...slices,
													receivedSlices: e.floatValue,
												})
											}}
											customInput={Input}
											crossOrigin={undefined}
											label="Received Slices"
										/>
										<NumericFormat
											thousandSeparator={true}
											min={0}
											value={slices.slicePrice?.value}
											onValueChange={(e: any) => {
												setEditingValue({
													...slices,
													slicePrice: {
														value: e.floatValue,
														symbol: slices.slicePrice?.symbol,
													},
												})
											}}
											customInput={Input}
											crossOrigin={undefined}
											label="Slice price"
										/>
									</div>
								)
							default:
								return <></>
						}
					})()}
				</DialogBody>
				<DialogFooter>
					<Button variant="text" color="red" onClick={() => edit()} className="mr-1">
						<span>Cancel</span>
					</Button>
					<Button variant="gradient" color="green" onClick={save}>
						<span>Confirm</span>
					</Button>
				</DialogFooter>
			</Dialog>
			<Card className="mb-6">
				<CardBody className="p-4">
					{(() => {
						if (error) {
							return (
								<div className="flex w-full flex-col gap-2 mb-2">
									<Alert color="red" onClose={() => setError('')}>
										{error}
									</Alert>
								</div>
							)
						}
						if (success) {
							return (
								<div className="flex w-full flex-col gap-2 mb-2">
									<Alert color="green" onClose={() => setSuccess('')}>
										{success}
									</Alert>
								</div>
							)
						}
					})()}
					<div className="mb-10 flex items-center justify-between gap-6">
						<div className="flex items-center gap-6">
							<Avatar
								src={resolveAvatarUrl(pie!.pieAvatarUrl!)}
								alt="bruce-mars"
								size="xl"
								className="rounded-lg shadow-lg shadow-blue-gray-500/40"
								variant="square"
								onClick={() => edit('Avatar', 'Avatar')}
							/>
							<div>
								<Typography variant="h5" color="blue-gray" className="mb-1">
									{pie.displayName}
								</Typography>
								<Typography variant="small" className="font-normal text-blue-gray-600">
									@{pie.slug}
								</Typography>
							</div>
						</div>
					</div>
					<div className="">
						<div>
							<Typography variant="h6" color="blue-gray" className="mb-3">
								Pie Information
							</Typography>
							<div>
								<a className="hpl" href={`${pie.pieId}/rewards-list`}>
									Rewards List
								</a>
							</div>
							{pie.bio ? (
								<>
									<Typography variant="small" className="font-normal text-blue-gray-500">
										{pie.bio}
									</Typography>
									<hr className="my-4 border-blue-gray-50" />
								</>
							) : null}
							{pie.preserveForInstagram ? (
								<div className="flex items-center gap-4">
									<Typography variant="small" color="blue-gray" className="font-semibold capitalize">
										Preserve for Instagram:
									</Typography>
									<Typography variant="small" className="font-normal text-blue-gray-500">
										{pie.preserveForInstagram}
									</Typography>
								</div>
							) : null}
							<hr className="my-4 border-blue-gray-50" />
							<div className="flex flex-col gap-12">
								<ul className="flex flex-col gap-4 p-0">
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Display name:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{pie.displayName}
										</Typography>
										<IconButton
											variant="text"
											onClick={() => edit('DisplayName', 'Display Name', pie.displayName)}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Slug:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{pie.slug}
										</Typography>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Max Slices:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{new Intl.NumberFormat('en-US').format(pie.maxSlices)}
										</Typography>
										<IconButton
											variant="text"
											onClick={() => edit('MaxSlices', 'Max Slices', pie.maxSlices)}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Total Slices:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{new Intl.NumberFormat('en-US').format(pie.issuedSlices)}
										</Typography>
										<IconButton
											variant="text"
											onClick={() =>
												edit('Slices', 'Slices', {
													holdingSlices: pie.holdingSlices,
													issuedSlices: pie.issuedSlices,
													slicePrice: {
														symbol: pie.slicePrice.symbol,
														value: pie.slicePrice.value,
													},
													availableSlices: pie.pieBalance?.availableSlices,
													receivedSlices: pie.pieBalance?.receivedSlices,
													sentSlices: pie.pieBalance?.sentSlices,
													soldSlices: pie.pieBalance?.soldSlices,
												})
											}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Holding Slices:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{new Intl.NumberFormat('en-US').format(pie.holdingSlices)}
										</Typography>
										<IconButton
											variant="text"
											onClick={() =>
												edit('Slices', 'Slices', {
													holdingSlices: pie.holdingSlices,
													issuedSlices: pie.issuedSlices,
													slicePrice: {
														symbol: pie.slicePrice.symbol,
														value: pie.slicePrice.value,
													},
													availableSlices: pie.pieBalance?.availableSlices,
													receivedSlices: pie.pieBalance?.receivedSlices,
													sentSlices: pie.pieBalance?.sentSlices,
													soldSlices: pie.pieBalance?.soldSlices,
												})
											}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Slices for Sale:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{new Intl.NumberFormat('en-US').format(pie.pieBalance?.availableSlices)}
										</Typography>
										<IconButton
											variant="text"
											onClick={() =>
												edit('Slices', 'Slices', {
													holdingSlices: pie.holdingSlices,
													issuedSlices: pie.issuedSlices,
													slicePrice: {
														symbol: pie.slicePrice.symbol,
														value: pie.slicePrice.value,
													},
													availableSlices: pie.pieBalance?.availableSlices,
													receivedSlices: pie.pieBalance?.receivedSlices,
													sentSlices: pie.pieBalance?.sentSlices,
													soldSlices: pie.pieBalance?.soldSlices,
												})
											}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Sold Slices:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{new Intl.NumberFormat('en-US').format(pie.pieBalance?.soldSlices)}
										</Typography>
										<IconButton
											variant="text"
											onClick={() =>
												edit('Slices', 'Slices', {
													holdingSlices: pie.holdingSlices,
													issuedSlices: pie.issuedSlices,
													slicePrice: {
														symbol: pie.slicePrice.symbol,
														value: pie.slicePrice.value,
													},
													availableSlices: pie.pieBalance?.availableSlices,
													receivedSlices: pie.pieBalance?.receivedSlices,
													sentSlices: pie.pieBalance?.sentSlices,
													soldSlices: pie.pieBalance?.soldSlices,
												})
											}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Sent Slices:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{new Intl.NumberFormat('en-US').format(pie.pieBalance?.sentSlices)}
										</Typography>
										<IconButton
											variant="text"
											onClick={() =>
												edit('Slices', 'Slices', {
													holdingSlices: pie.holdingSlices,
													issuedSlices: pie.issuedSlices,
													slicePrice: {
														symbol: pie.slicePrice.symbol,
														value: pie.slicePrice.value,
													},
													availableSlices: pie.pieBalance?.availableSlices,
													receivedSlices: pie.pieBalance?.receivedSlices,
													sentSlices: pie.pieBalance?.sentSlices,
													soldSlices: pie.pieBalance?.soldSlices,
												})
											}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Received Slices:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{new Intl.NumberFormat('en-US').format(pie.pieBalance?.receivedSlices)}
										</Typography>
										<IconButton
											variant="text"
											onClick={() =>
												edit('Slices', 'Slices', {
													holdingSlices: pie.holdingSlices,
													issuedSlices: pie.issuedSlices,
													slicePrice: {
														symbol: pie.slicePrice.symbol,
														value: pie.slicePrice.value,
													},
													availableSlices: pie.pieBalance?.availableSlices,
													receivedSlices: pie.pieBalance?.receivedSlices,
													sentSlices: pie.pieBalance?.sentSlices,
													soldSlices: pie.pieBalance?.soldSlices,
												})
											}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Slice Price:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{pie.slicePrice.symbol}{' '}
											{new Intl.NumberFormat('en-US').format(pie.slicePrice.value)}
										</Typography>
										<IconButton
											variant="text"
											onClick={() =>
												edit('Slices', 'Slices', {
													holdingSlices: pie.holdingSlices,
													issuedSlices: pie.issuedSlices,
													slicePrice: {
														symbol: pie.slicePrice.symbol,
														value: pie.slicePrice.value,
													},
													availableSlices: pie.pieBalance?.availableSlices,
													receivedSlices: pie.pieBalance?.receivedSlices,
													sentSlices: pie.pieBalance?.sentSlices,
													soldSlices: pie.pieBalance?.soldSlices,
												})
											}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Is Visible:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{pie.isVisible ? 'Yes' : 'No'}
										</Typography>
										<IconButton
											variant="text"
											onClick={() => edit('IsVisible', 'Is Pie Visible', pie.isVisible)}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		</>
	)
}

export default Pie
