import {
	Card,
	CardBody,
	Avatar,
	Typography,
	Button,
	Spinner,
	IconButton,
	Dialog,
	DialogHeader,
	DialogBody,
	DialogFooter,
	Input,
	Alert,
	Radio,
	Switch,
} from '@material-tailwind/react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
	EmailSubscription,
	EmailSubscriptionArgsInput,
	EmailSubscriptionStatus,
	EmailSubscriptionType,
	useAdminCompleteUserAvatarUploadMutation,
	useAdminGetUserQuery,
	useAdminPresignedUserAvatarUploadUrlMutation,
	useAdminSetPieCreateQuotaRemainingMutation,
	useAdminSetSkipMfaMutation,
	useAdminSetUserPasswordMutation,
	useAdminUpdateUserEmailSubscriptionsMutation,
	useDeleteUserMutation,
	useGrantInvitesMutation,
	useRecoverUserMutation,
	useSetEmailAddressMutation,
	useSetIsVerifiedMutation,
	useSetPhoneNumberMutation,
	useSetUserNameMutation,
} from '@/graphql/generated/scheme'
import isUrlHttp from 'is-url-http'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { PencilIcon } from '@heroicons/react/24/solid'
import mime from 'mime'
import * as uploadService from '../../services/upload'
import urlParse from 'url-parse'
import { NumericFormat } from 'react-number-format'

export function User() {
	const [user, setUser] = useState<any>(null)
	const [editing, setEditing] = useState<{
		isEditing: boolean
		field?: string
		fieldDisplayName?: string
		header?: string
	}>({
		isEditing: false,
	})
	const [editingValue, setEditingValue] = useState<any>(null)
	const [updating, setUpdating] = useState<boolean>(false)
	const [error, setError] = useState<string>('')
	const [success, setSuccess] = useState<string>('')
	const [uploading, setUploading] = useState<boolean>(false)

	const navigate = useNavigate()
	const { id } = useParams()

	const backToUsersList = () => {
		navigate('/dashboard/users')
	}

	const resolveAvatarUrl = (src: string) => {
		let url = src
		if (!url) {
			url = '/img/default/avatar.png'
		} else {
			if (!isUrlHttp(url) && url.indexOf('data:') === -1) {
				url = `${process.env.REACT_APP_MEDIA_URL}/${src}`
			}
		}

		return url
	}

	const isEmailTypeSubscribed = (emailType: EmailSubscriptionType) => {
		if (emailType === EmailSubscriptionType.All) {
			return (
				user?.emailSubscriptions?.find((x: EmailSubscription) => x.type === emailType)?.status ===
				EmailSubscriptionStatus.Active
			)
		}

		return (
			user?.emailSubscriptions?.find((x: EmailSubscription) => x.type === emailType)?.status !==
			EmailSubscriptionStatus.Inactive
		)
	}

	const setEmailSubscriptionStatus = (emailSubscriptionType: EmailSubscriptionType, checked: boolean) => {
		let args: EmailSubscriptionArgsInput[] = [
			{
				status: EmailSubscriptionStatus.Active,
				type: EmailSubscriptionType.Achievement,
			},
			{
				status: EmailSubscriptionStatus.Active,
				type: EmailSubscriptionType.Reward,
			},
			{
				status: EmailSubscriptionStatus.Active,
				type: EmailSubscriptionType.Slice,
			},
			{
				status: EmailSubscriptionStatus.Inactive,
				type: EmailSubscriptionType.All,
			},
		]

		if (user.emailSubscriptions) {
			args = args.map((x: EmailSubscription) => {
				const existSubscription = user.emailSubscriptions.find((e: any) => e.type === x.type)
				return {
					type: x.type,
					status:
						existSubscription && existSubscription.status != EmailSubscriptionStatus.None
							? existSubscription.status
							: x.status,
				}
			})
		}

		let subscription = args.find(x => x.type === emailSubscriptionType)
		if (!subscription) {
			subscription = {
				type: emailSubscriptionType,
				status: EmailSubscriptionStatus.None,
			}
			args.push(subscription)
		}

		subscription.status = checked ? EmailSubscriptionStatus.Active : EmailSubscriptionStatus.Inactive

		if (emailSubscriptionType === EmailSubscriptionType.All && checked) {
			args.forEach(x => {
				if (x.type !== EmailSubscriptionType.All) {
					x.status = EmailSubscriptionStatus.Inactive
				}
			})
		} else if (emailSubscriptionType !== EmailSubscriptionType.All && checked) {
			const all = args.find(x => x.type === EmailSubscriptionType.All)
			if (all) {
				all.status = EmailSubscriptionStatus.Inactive
			}
		}

		updateEmailSubscription({
			variables: {
				userId: user.userId,
				args: args,
			},
		})
	}

	const edit = (field?: string, fieldDisplayName?: string, defaultValue?: any, header?: string) => {
		if (!field) {
			setEditing({ isEditing: false })
			return
		}

		setEditing({ isEditing: true, field, fieldDisplayName, header })
		setEditingValue(defaultValue)
	}

	const [
		updateEmailSubscription,
		{
			loading: updateEmailSubscriptionLoading,
			error: updateEmailSubscriptionError,
			data: updateEmailSubscriptionData,
		},
	] = useAdminUpdateUserEmailSubscriptionsMutation()

	const [presignUrl, { loading: presignUrlLoading, error: presignUrlError, data: presignUrlData }] =
		useAdminPresignedUserAvatarUploadUrlMutation()
	const [completeUpload, { loading: completeUploadLoading, error: completeUploadError, data: completeUploadData }] =
		useAdminCompleteUserAvatarUploadMutation()

	const [updateEmailAddress, { loading: editFieldEmailLoading, error: editFieldEmailError, data: updateEmailData }] =
		useSetEmailAddressMutation()
	const [updatePhoneNumber, { loading: editFieldPhoneLoading, error: editFieldPhoneError, data: updatePhoneData }] =
		useSetPhoneNumberMutation()
	const [
		updateUserName,
		{ loading: editFieldUserNameLoading, error: editFieldUserNameError, data: updateUserNameData },
	] = useSetUserNameMutation()

	const [
		updateCreatePieQuotaRemaining,
		{
			loading: editFieldCreatePieQuotaRemainingLoading,
			error: editFieldCreatePieQuotaRemainingError,
			data: updateCreatePieQuotaRemainingData,
		},
	] = useAdminSetPieCreateQuotaRemainingMutation()

	const [
		grantInvites,
		{
			loading: editFieldRemainingInvitesLoading,
			error: editFieldRemainingInvitesError,
			data: updateRemainingInvitesData,
		},
	] = useGrantInvitesMutation()

	const [setIsVerified, { loading: setIsVerifiedLoading, error: setIsVerifiedError, data: setIsVerifiedData }] =
		useSetIsVerifiedMutation()

	const [deleteUser, { loading: deleteLoading, error: deleteError, data: deleteData }] = useDeleteUserMutation()

	const [recoverUser, { loading: recoverLoading, error: recoverError, data: recoverData }] = useRecoverUserMutation()

	const [setSkipMfa, { loading: setSkipMfaLoading, error: setSkipMfaError, data: setSkipMfaData }] =
		useAdminSetSkipMfaMutation()

	const [setPassword, { loading: setPasswordLoading, error: setPasswordError, data: setPasswordData }] =
		useAdminSetUserPasswordMutation()

	const save = () => {
		const editingField = editing.field
		const value = editingValue
		setEditing({ isEditing: false })
		setError('')
		setSuccess('')

		switch (editingField) {
			case 'Avatar':
				if (!value || !value.length) {
					break
				}
				const file = (value as FileList)[0]
				const { type, size } = file
				const extension = mime.getExtension(type) as string
				presignUrl({
					variables: { userId: user.userId, contentType: type, extension, contentLength: size },
				})
				break
			case 'Password':
				if (!value || !value.trim().length) {
					break
				}
				setPassword({
					variables: { userId: user.userId, password: value },
				})
				break
			case 'Email':
				updateEmailAddress({
					variables: {
						userId: user.userId,
						emailAddress: value,
					},
				})
				break
			case 'Phone':
				updatePhoneNumber({
					variables: {
						userId: user.userId,
						phoneNumber: value,
					},
				})
				break
			case 'UserName':
				updateUserName({
					variables: {
						userId: user.userId,
						userName: value,
					},
				})
				break

			case 'CreatePieQuotaRemaining':
				updateCreatePieQuotaRemaining({
					variables: {
						userId: user.userId,
						quota: value * 1,
					},
				})
				break

			case 'RemainingInvites':
				grantInvites({
					variables: {
						userId: user.userId,
						numOfInvites: (user.userInvitesRemaining - value) * -1,
					},
				})
				break

			case 'IsVerified':
				setIsVerified({
					variables: {
						userId: user.userId,
						isVerified: value,
					},
				})
				break

			case 'Recover':
				recoverUser({
					variables: {
						userId: user.userId,
					},
				})
				break

			case 'Delete':
				deleteUser({
					variables: {
						userId: user.userId,
					},
				})
				break

			case 'SkipMfa':
				setSkipMfa({
					variables: {
						userId: user.userId,
						skip: value,
					},
				})
				break
			default:
				break
		}
	}

	if (id == undefined || !id.length) {
		backToUsersList()
	}

	const {
		data: dataUser,
		loading: loadingUser,
		refetch: refetchUser,
		error: loadUserError,
	} = useAdminGetUserQuery({
		variables: {
			userId: id,
		},
	})

	useEffect(() => {
		const userData = dataUser?.admin?.user?.byId

		if (userData) {
			setUser({ ...userData })
		} else if (!loadingUser) {
			backToUsersList()
		}
	}, [dataUser])

	useEffect(() => {
		if (presignUrlData) {
			setUploading(true)
			const file = (editingValue as FileList)[0]
			const signedUrl = presignUrlData.admin.userById.presignUploadAvatarUrl
			uploadService
				.uploadAvatar({ presignedS3Url: signedUrl, file: file })
				.then(() => {
					const url = urlParse(signedUrl, true).pathname.slice(1)
					completeUpload({
						variables: {
							userId: user.userId,
							avatarUrl: url,
						},
					})
					setUploading(false)
				})
				.catch(() => {
					setUploading(false)
				})
		}
	}, [presignUrlData])

	useEffect(() => {
		if (
			presignUrlLoading ||
			completeUploadLoading ||
			editFieldEmailLoading ||
			editFieldPhoneLoading ||
			editFieldUserNameLoading ||
			editFieldCreatePieQuotaRemainingLoading ||
			editFieldRemainingInvitesLoading ||
			recoverLoading ||
			deleteLoading ||
			setIsVerifiedLoading ||
			setSkipMfaLoading ||
			setPasswordLoading
		) {
			setUpdating(true)
			return
		}
		setUpdating(false)
	}, [
		presignUrlLoading,
		completeUploadLoading,
		editFieldEmailLoading,
		editFieldPhoneLoading,
		editFieldUserNameLoading,
		editFieldCreatePieQuotaRemainingLoading,
		editFieldRemainingInvitesLoading,
		recoverLoading,
		deleteLoading,
		setIsVerifiedLoading,
		setSkipMfaLoading,
		setPasswordLoading,
	])

	useEffect(() => {
		if (
			completeUploadData ||
			updateEmailData ||
			updatePhoneData ||
			updateUserNameData ||
			updateCreatePieQuotaRemainingData ||
			updateRemainingInvitesData ||
			recoverData ||
			deleteData ||
			setIsVerifiedData ||
			setSkipMfaData ||
			updateEmailSubscriptionData ||
			setPasswordData
		) {
			refetchUser({
				userId: user.userId,
			})
		}
	}, [
		completeUploadData,
		updateEmailData,
		updatePhoneData,
		updateUserNameData,
		updateCreatePieQuotaRemainingData,
		updateRemainingInvitesData,
		recoverData,
		deleteData,
		setIsVerifiedData,
		setSkipMfaData,
		updateEmailSubscriptionData,
		setPasswordData,
	])

	useEffect(() => {
		if (completeUploadData) {
			setSuccess('Update user avatar successfully')
		}
	}, [completeUploadData])

	useEffect(() => {
		if (presignUrlError) {
			setError(presignUrlError.message)
		}
	}, [presignUrlError])

	useEffect(() => {
		if (completeUploadError) {
			setError(completeUploadError.message)
		}
	}, [completeUploadError])

	useEffect(() => {
		if (setPasswordData) {
			setSuccess('Set password successfully')
		}
	}, [setPasswordData])

	useEffect(() => {
		if (setPasswordError) {
			setError(setPasswordError.message)
		}
	}, [setPasswordError])

	useEffect(() => {
		if (updateEmailData) {
			setSuccess('Update email address successfully')
		}
	}, [updateEmailData])

	useEffect(() => {
		if (editFieldEmailError) {
			setError(editFieldEmailError.message)
		}
	}, [editFieldEmailError])

	useEffect(() => {
		if (updatePhoneData) {
			setSuccess('Update phone number successfully')
		}
	}, [updatePhoneData])

	useEffect(() => {
		if (editFieldPhoneError) {
			setError(editFieldPhoneError.message)
		}
	}, [editFieldPhoneError])

	useEffect(() => {
		if (updateUserNameData) {
			setSuccess('Update user id successfully')
		}
	}, [updateUserNameData])

	useEffect(() => {
		if (editFieldUserNameError) {
			setError(editFieldUserNameError.message)
		}
	}, [editFieldUserNameError])

	useEffect(() => {
		if (updateCreatePieQuotaRemainingData) {
			setSuccess('Update create pie quota remaining successfully')
		}
	}, [updateCreatePieQuotaRemainingData])

	useEffect(() => {
		if (editFieldCreatePieQuotaRemainingError) {
			setError(editFieldCreatePieQuotaRemainingError.message)
		}
	}, [editFieldCreatePieQuotaRemainingError])

	useEffect(() => {
		if (updateRemainingInvitesData) {
			setSuccess('Update remaining invites successfully')
		}
	}, [updateRemainingInvitesData])

	useEffect(() => {
		if (editFieldRemainingInvitesError) {
			setError(editFieldRemainingInvitesError.message)
		}
	}, [editFieldRemainingInvitesError])

	useEffect(() => {
		if (recoverData) {
			setSuccess('Recover user successfully')
		}
	}, [recoverData])

	useEffect(() => {
		if (recoverError) {
			setError(recoverError.message)
		}
	}, [recoverError])

	useEffect(() => {
		if (deleteData) {
			setSuccess('Delete user successfully')
		}
	}, [deleteData])

	useEffect(() => {
		if (deleteError) {
			setError(deleteError.message)
		}
	}, [deleteError])

	useEffect(() => {
		if (setIsVerifiedData) {
			setSuccess('Update user is verified successfully')
		}
	}, [setIsVerifiedData])

	useEffect(() => {
		if (setIsVerifiedError) {
			setError(setIsVerifiedError.message)
		}
	}, [setIsVerifiedError])

	useEffect(() => {
		if (setSkipMfaData) {
			setSuccess('Update Skip Multi-Factor Authentication succesfully')
		}
	}, [setSkipMfaData])

	useEffect(() => {
		if (setSkipMfaError) {
			setError(setSkipMfaError.message)
		}
	}, [setSkipMfaError])

	useEffect(() => {
		if (updateEmailSubscriptionData) {
			setSuccess('Update email subscriptions successfully')
		}
	}, [updateEmailSubscriptionData])

	useEffect(() => {
		if (updateEmailSubscriptionError) {
			setError(updateEmailSubscriptionError.message)
		}
	}, [updateEmailSubscriptionError])

	if (loadingUser || !user || updating || uploading) {
		return (
			<div className="flex justify-center items-center py-4">
				<Spinner className="h-12 w-12" />
			</div>
		)
	}

	if (loadUserError) {
		return (
			<div className="py-4">
				<div className="flex justify-center items-center py-4">
					<Typography color="red">
						Could not load user information due to server error. Please try again.
					</Typography>
				</div>
				<div className="flex justify-center items-center py-4">
					<NavLink to={'/dashboard/users'}>
						<Button variant="outlined" className="flex items-center gap-3">
							Back to Users list
						</Button>
					</NavLink>
				</div>
			</div>
		)
	}

	return (
		<>
			<Dialog open={editing.isEditing} handler={edit}>
				<DialogHeader>{editing.header ? editing.header : `Update ${editing.fieldDisplayName}`}</DialogHeader>
				<DialogBody divider>
					{(() => {
						switch (editing.field) {
							case 'Avatar':
								return (
									<Input
										type="file"
										label={editing.fieldDisplayName}
										crossOrigin={undefined}
										onChange={event => {
											setEditingValue(event.target.files)
										}}
									/>
								)
							case 'Password':
								return (
									<>
										<Input
											type="password"
											label={editing.fieldDisplayName}
											crossOrigin={undefined}
											onChange={event => {
												setEditingValue(event.target.value)
											}}
										/>
									</>
								)
							case 'Email':
							case 'Phone':
							case 'UserName':
								return (
									<Input
										label={editing.fieldDisplayName}
										crossOrigin={undefined}
										value={editingValue}
										onChange={event => {
											setEditingValue(event.target.value)
										}}
									/>
								)
							case 'RemainingInvites':
							case 'CreatePieQuotaRemaining':
								return (
									<NumericFormat
										thousandSeparator={true}
										className="numeric-format-input peer"
										value={editingValue}
										onChange={event => {
											setEditingValue(event.target.value)
										}}
									/>
								)
							case 'IsVerified':
							case 'SkipMfa':
								return (
									<>
										<Radio
											name="editingYesNo"
											label="Yes"
											defaultChecked={!!editingValue}
											onChange={() => {
												setEditingValue(true)
											}}
											crossOrigin={undefined}
										/>
										<Radio
											name="editingYesNo"
											label="No"
											defaultChecked={!editingValue}
											onChange={() => {
												setEditingValue(false)
											}}
											crossOrigin={undefined}
										/>
									</>
								)
							case 'Recover':
								return <div>Are you sure to recover this user?</div>
							case 'Delete':
								return <div>Are you sure to delete this user?</div>
							default:
								return <></>
						}
					})()}
				</DialogBody>
				<DialogFooter>
					<Button variant="text" color="red" onClick={() => edit()} className="mr-1">
						<span>Cancel</span>
					</Button>
					<Button variant="gradient" color="green" onClick={save}>
						<span>Confirm</span>
					</Button>
				</DialogFooter>
			</Dialog>
			<Card className="mb-6">
				<CardBody className="p-4">
					{(() => {
						if (error) {
							return (
								<div className="flex w-full flex-col gap-2 mb-2">
									<Alert color="red" onClose={() => setError('')}>
										{error}
									</Alert>
								</div>
							)
						}
						if (success) {
							return (
								<div className="flex w-full flex-col gap-2 mb-2">
									<Alert color="green" onClose={() => setSuccess('')}>
										{success}
									</Alert>
								</div>
							)
						}
					})()}
					<div className="mb-10 flex items-center justify-between gap-6">
						<div className="flex items-center gap-6">
							<Avatar
								src={resolveAvatarUrl(user.avatarUrl)}
								alt="bruce-mars"
								size="xl"
								className="rounded-lg shadow-lg shadow-blue-gray-500/40"
								onClick={() => edit('Avatar', 'Avatar')}
							/>
							<div>
								<Typography variant="h5" color="blue-gray" className="mb-1">
									{user.name}
									<IconButton
										className={user.isVerified ? '' : 'opacity-20'}
										color={user.isVerified ? 'blue' : 'gray'}
										variant="text"
										onClick={() => edit('IsVerified', 'Is Verified', user.isVerified)}
									>
										<CheckCircleIcon className="h-8 w-8" />
									</IconButton>
								</Typography>
								<Typography variant="small" className="font-normal text-blue-gray-600">
									@{user.userName}
									<IconButton
										variant="text"
										onClick={() => edit('UserName', 'User ID', user.userName)}
									>
										<PencilIcon className="h-4 w-4" />
									</IconButton>
								</Typography>
							</div>
						</div>
					</div>
					<div className="flex gap-24">
						<div>
							<Typography variant="h6" color="blue-gray" className="mb-3">
								Profile Information
							</Typography>
							{user.bio ? (
								<>
									<Typography variant="small" className="font-normal text-blue-gray-500">
										{user.bio}
									</Typography>
									<hr className="my-4 border-blue-gray-50" />
								</>
							) : null}
							<div className="flex flex-col gap-12">
								<ul className="flex flex-col gap-4 p-0">
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Email:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{user.verifiedEmailAddress ?? user.emailAddress}
										</Typography>
										{user.verifiedEmailAddress && (
											<CheckCircleIcon color="green" className="h-4 w-4" />
										)}
										<IconButton
											variant="text"
											onClick={() =>
												edit(
													'Email',
													'Email Address',
													user.verifiedEmailAddress ?? user.emailAddress
												)
											}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Phone Number:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{user.verifiedPhoneNumber ?? user.phoneNumber}
										</Typography>
										{user.verifiedPhoneNumber && (
											<CheckCircleIcon color="green" className="h-4 w-4" />
										)}
										<IconButton
											variant="text"
											onClick={() =>
												edit(
													'Phone',
													'Phone Number',
													user.verifiedPhoneNumber ?? user.phoneNumber
												)
											}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Is Skip Multi-Factor Authentication:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{user.isSkipMultiFactorAuthentication ? 'Yes' : 'No'}
										</Typography>
										<IconButton
											variant="text"
											onClick={() =>
												edit(
													'SkipMfa',
													'Skip Multi-Factor Authentication',
													user.isSkipMultiFactorAuthentication
												)
											}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Create Pie Quota Remaining:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{new Intl.NumberFormat('en-US').format(user.pieCreateQuotaRemaining)}
										</Typography>
										<IconButton
											variant="text"
											onClick={() =>
												edit(
													'CreatePieQuotaRemaining',
													'Create Pie Quota Remaining',
													user.pieCreateQuotaRemaining
												)
											}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Remaining Invites:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											{new Intl.NumberFormat('en-US').format(user.userInvitesRemaining)}
										</Typography>
										<IconButton
											variant="text"
											onClick={() =>
												edit('RemainingInvites', 'Remaining Invites', user.userInvitesRemaining)
											}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Status:
										</Typography>
										<Button
											color={user.isDeleted ? 'red' : 'green'}
											onClick={() =>
												edit(
													user.isDeleted ? 'Recover' : 'Delete',
													`${user.isDeleted ? 'Recover' : 'Delete'} user`,
													null,
													`${user.isDeleted ? 'Recover' : 'Delete'} user?`
												)
											}
										>
											{user.isDeleted ? 'Deleted' : 'Active'}
										</Button>
									</li>
									<li className="flex items-center gap-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-semibold capitalize"
										>
											Password:
										</Typography>
										<Typography variant="small" className="font-normal text-blue-gray-500">
											*********
										</Typography>
										<IconButton
											variant="text"
											onClick={() => edit('Password', 'Password', '', 'Set Password')}
										>
											<PencilIcon className="h-4 w-4" />
										</IconButton>
									</li>
								</ul>
							</div>
						</div>
						<div>
							<Typography variant="h6" color="blue-gray" className="mb-3">
								Notification Subscriptions
							</Typography>
							<div className="flex flex-col gap-12">
								<ul className="flex flex-col gap-4 p-0">
									{(() => {
										return [
											{
												type: EmailSubscriptionType.Slice,
												label: 'Receive for slices',
												checked: isEmailTypeSubscribed(EmailSubscriptionType.Slice),
											},
											{
												type: EmailSubscriptionType.Reward,
												label: 'Receive for rewards',
												checked: isEmailTypeSubscribed(EmailSubscriptionType.Reward),
											},
											{
												type: EmailSubscriptionType.Achievement,
												label: 'Receive for achievements',
												checked: isEmailTypeSubscribed(EmailSubscriptionType.Achievement),
											},
											{
												type: EmailSubscriptionType.All,
												label: 'Unsubscribe to all',
												checked: isEmailTypeSubscribed(EmailSubscriptionType.All),
											},
										].map(x => (
											<li key={x.type} className="flex items-center gap-4">
												<Switch
													color="purple"
													checked={x.checked}
													disabled={updateEmailSubscriptionLoading}
													crossOrigin={undefined}
													ripple={true}
													className="h-full w-full"
													containerProps={{
														className: 'w-11 h-6',
													}}
													circleProps={{
														className: 'before:hidden left-0.5 border-none',
													}}
													onChange={e => {
														setEmailSubscriptionStatus(x.type, e.target.checked)
													}}
												/>
												<Typography
													variant="small"
													color="blue-gray"
													className="font-semibold capitalize"
													style={{ lineHeight: '40px' }}
												>
													{x.label}
												</Typography>
											</li>
										))
									})()}
								</ul>
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		</>
	)
}

export default User
