import { Reward, useAdminSearchRewardsQuery } from '@/graphql/generated/scheme'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { ArrowLeftIcon, ArrowRightIcon, EyeIcon, PencilIcon } from '@heroicons/react/24/solid'
import {
	Card,
	CardHeader,
	Input,
	Typography,
	CardBody,
	CardFooter,
	Avatar,
	IconButton,
	Spinner,
	Tooltip,
	Button,
} from '@material-tailwind/react'
import { format } from 'date-fns'
import isUrlHttp from 'is-url-http'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import RewardParticipantDetailsDialog from './reward-participants-details-dialog'

export function Rewards() {
	const [rewards, setRewards] = useState<any>([])
	const [pageInfo, setPageInfo] = useState<any>({
		total: 0,
		totalPage: 0,
		currentPage: 1,
		offset: 0,
		limit: 10,
	})

	const [keyword, setKeyword] = useState<any>(null)
	const [viewingReward, setViewingReward] = useState<string | null>(null)

	const {
		data: dataRewards,
		loading: loadingRewards,
		refetch: refetchRewards,
	} = useAdminSearchRewardsQuery({
		fetchPolicy: 'no-cache',
		variables: {
			limit: pageInfo.limit,
			offset: pageInfo.offset,
			keyword: keyword,
		},
	})

	useEffect(() => {
		const rewardsData = dataRewards?.admin?.reward?.search?.nodes
		const totalCount = dataRewards?.admin?.reward?.search?.totalCount

		if (rewardsData) {
			const pageInfoData = {
				...pageInfo,
				total: totalCount,
				totalPage: Math.ceil(totalCount / pageInfo.limit),
			}
			setRewards([...rewardsData])
			setPageInfo(pageInfoData)
		}
	}, [dataRewards])

	function resolveAvatarUrl(src: string) {
		let url = src
		if (!url) {
			url = '/img/default/avatar.png'
		} else {
			if (!isUrlHttp(url) && url.indexOf('data:') === -1) {
				url = `${process.env.REACT_APP_MEDIA_URL}/${src}`
			}
		}

		return url
	}

	function previousClick() {
		const offset = pageInfo.offset - pageInfo.limit
		const currPage = pageInfo.currentPage - 1
		setPageInfo({
			...pageInfo,
			offset: offset,
			currentPage: currPage,
		})
		refetchRewards({ keyword: keyword, limit: pageInfo.limit, offset: offset })
	}

	function nextClick() {
		const offset = pageInfo.offset + pageInfo.limit
		const currPage = pageInfo.currentPage + 1
		setPageInfo({
			...pageInfo,
			offset: offset,
			currentPage: currPage,
		})
		refetchRewards({ keyword: keyword, limit: pageInfo.limit, offset: offset })
	}

	function goToPage(pageNumber: number) {
		const offset = (pageNumber - 1) * pageInfo.limit
		const currPage = pageNumber
		setPageInfo({
			...pageInfo,
			offset: offset,
			currentPage: currPage,
		})
		refetchRewards({ keyword: keyword, limit: pageInfo.limit, offset: offset })
	}

	function search(kw: string) {
		if (kw === keyword) {
			return
		}

		setPageInfo({
			...pageInfo,
			offset: 0,
			currentPage: 1,
		})
		setKeyword(kw)
		refetchRewards({ keyword: kw, limit: pageInfo.limit, offset: 0 })
	}

	function onSearchKeydown(event: React.KeyboardEvent<HTMLInputElement>) {
		if (event.key === 'Enter') {
			search((event.target as HTMLInputElement).value)
		}
	}

	if (loadingRewards) {
		return (
			<div className="flex justify-center items-center py-4">
				<Spinner className="h-12 w-12" />
			</div>
		)
	}

	return (
		<>
			{viewingReward ? (
				<RewardParticipantDetailsDialog
					key={viewingReward}
					rewardId={viewingReward}
					onClose={() => setViewingReward(null)}
				></RewardParticipantDetailsDialog>
			) : null}
			<Card className="h-full w-full">
				<CardHeader floated={false} shadow={false} className="rounded-none">
					<div className="flex items-center justify-between gap-8">
						<div>
							<Typography variant="h5" color="blue-gray">
								Rewards list
							</Typography>
							<Typography color="gray" className="mt-1 font-normal">
								See information about all rewards
							</Typography>
							<div>
								<NavLink to={`/dashboard/rewards/new`}>
									<Button variant="outlined" className="flex items-center gap-3">
										Create new reward
									</Button>
								</NavLink>
							</div>
						</div>
						<div className="flex shrink-0 flex-col gap-2 sm:flex-row">
							<Input
								label="Search"
								icon={<MagnifyingGlassIcon className="h-5 w-5" />}
								crossOrigin={undefined}
								className="w-80"
								defaultValue={keyword}
								onKeyDown={onSearchKeydown}
							/>
						</div>
					</div>
				</CardHeader>
				<CardBody className="overflow-x-auto px-0">
					<table className="mt-4 w-full min-w-max table-auto text-left">
						<thead>
							<tr>
								{[
									{ display: 'Display Name', key: 'displayName' },
									{ display: 'Slug', key: 'slug' },
									{ display: 'Pie', key: 'pie' },
									{ display: 'Creator', key: 'creator' },
									{ display: 'Created At', key: 'createdAt' },
									{ display: 'Is Deleted', key: 'isDeleted' },
									{ display: '', key: 'actions' },
								].map(el => (
									<th key={el.key} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-normal leading-none opacity-70"
										>
											{el.display}
										</Typography>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{rewards.map((reward: Reward, index: number) => {
								const isLast = index === rewards.length - 1
								const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50'

								return (
									<tr key={reward.rewardId}>
										<td className={`${classes} max-w-[300px]`}>
											<div className="flex items-center gap-3">
												<Avatar
													src={resolveAvatarUrl(reward.avatarUrl ?? '')}
													alt={reward.displayName ?? ''}
													size="sm"
													variant="square"
												/>
												<div className="flex flex-col">
													<Typography
														variant="small"
														color="blue-gray"
														className="font-normal"
													>
														{reward.displayName ?? ''}
													</Typography>
												</div>
											</div>
										</td>
										<td className={`${classes} max-w-[300px]`}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{reward.slug}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{reward.pie?.displayName}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{reward.pie?.createdBy?.name}
											</Typography>
											<Typography
												variant="small"
												color="blue-gray"
												className="font-normal opacity-70"
											>
												@{reward.pie?.createdBy?.userName}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{reward.createdUtc
													? format(new Date(reward.createdUtc), 'dd MMM yyyy')
													: ''}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{reward.isDeleted ? 'Yes' : 'No'}
											</Typography>
										</td>
										<td className={classes}>
											<Tooltip content="View detail participants">
												<IconButton
													variant="text"
													onClick={() => setViewingReward(reward.rewardId)}
												>
													<EyeIcon className="h-4 w-4" />
												</IconButton>
											</Tooltip>
											<Tooltip content="Edit Reward">
												<NavLink to={`/dashboard/rewards/${reward.rewardId}`}>
													<IconButton variant="text">
														<PencilIcon className="h-4 w-4" />
													</IconButton>
												</NavLink>
											</Tooltip>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</CardBody>
				<CardFooter className="flex items-center justify-center border-t border-blue-gray-50 p-4">
					<div className="flex justify-center gap-8">
						<IconButton
							size="sm"
							variant="outlined"
							onClick={previousClick}
							disabled={pageInfo.currentPage === 1}
						>
							<ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
						</IconButton>
						<Typography color="gray" className="font-normal">
							Page{' '}
							<select
								value={pageInfo.currentPage}
								onChange={e => goToPage(Number.parseInt(e.target.value))}
								style={{ fontWeight: 'bold', border: '1px solid', padding: '2px 2px 2px 6px' }}
							>
								{Array.from({ length: pageInfo.totalPage }, (_, i) => i + 1).map(page => {
									return (
										<option key={page} value={page}>
											{page}
										</option>
									)
								})}
							</select>{' '}
							of <strong className="text-gray-900">{pageInfo.totalPage}</strong>
						</Typography>
						<IconButton
							size="sm"
							variant="outlined"
							onClick={nextClick}
							disabled={pageInfo.currentPage === pageInfo.totalPage}
						>
							<ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
						</IconButton>
					</div>
				</CardFooter>
			</Card>
		</>
	)
}

export default Rewards
