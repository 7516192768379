import { ExternalApplication, useGetExternalApplicationsQuery } from '@/graphql/generated/scheme'
import { ArrowDownIcon, PencilIcon } from '@heroicons/react/24/solid'
import {
	Card,
	CardHeader,
	Typography,
	CardBody,
	CardFooter,
	IconButton,
	Spinner,
	Tooltip,
	Button,
} from '@material-tailwind/react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

export function ExternalApplications() {
	const [apps, setApps] = useState<any>([])
	const [pageInfo, setPageInfo] = useState<any>({
		totalCount: 0,
		hasNextPage: false,
		endCursor: null,
		first: 10,
	})

	const {
		data: dataApps,
		loading: loadingApps,
		refetch: refetchApps,
	} = useGetExternalApplicationsQuery({
		fetchPolicy: 'no-cache',
		variables: {
			first: pageInfo.first,
			after: pageInfo.endCursor,
		},
	})

	useEffect(() => {
		const appsData = dataApps?.admin.externalApplication.list?.nodes
		const totalCount = dataApps?.admin.externalApplication.list?.totalCount
		const endCursor = dataApps?.admin.externalApplication.list?.pageInfo.endCursor
		const hasNextPage = dataApps?.admin.externalApplication.list?.pageInfo.hasNextPage

		if (appsData) {
			const pageInfoData = {
				...pageInfo,
				totalCount,
				endCursor,
				hasNextPage,
			}
			setApps([...apps, ...appsData])
			setPageInfo(pageInfoData)
		}
	}, [dataApps])

	function moreClick() {
		refetchApps({ first: pageInfo.limit, after: pageInfo.endCursor })
	}

	return (
		<>
			{loadingApps && (
				<div className="absolute inset-0 flex justify-center items-center z-10 bg-gray-400/75">
					<Spinner className="h-12 w-12" />
				</div>
			)}
			<Card className="h-full w-full">
				<CardHeader floated={false} shadow={false} className="rounded-none">
					<div className="flex items-center justify-between gap-8">
						<div>
							<Typography variant="h5" color="blue-gray">
								External application list
							</Typography>
							<Typography color="gray" className="mt-1 font-normal">
								See information about all external applications
							</Typography>
							<div>
								<NavLink to={`/dashboard/xapps/new`}>
									<Button variant="outlined" className="flex items-center gap-3">
										Create new External Application
									</Button>
								</NavLink>
							</div>
						</div>
					</div>
				</CardHeader>
				<CardBody className="overflow-x-auto px-0">
					<table className="mt-4 w-full min-w-max table-auto text-left">
						<thead>
							<tr>
								{[
									{ display: 'Name', key: 'name' },
									{ display: 'Description', key: 'description' },
									{ display: 'Is Disabled', key: 'isDisabled' },
									{ display: 'Created At', key: 'createdAt' },
									{ display: '', key: 'actions' },
								].map(el => (
									<th key={el.key} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
										<Typography
											variant="small"
											color="blue-gray"
											className="font-normal leading-none opacity-70"
										>
											{el.display}
										</Typography>
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{apps.map((app: ExternalApplication, index: number) => {
								const isLast = index === apps.length - 1
								const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50'

								return (
									<tr key={app.externalApplicationId}>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{app.name}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{app.description}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{app.isDisabled ? 'Yes' : 'No'}
											</Typography>
										</td>
										<td className={classes}>
											<Typography variant="small" color="blue-gray" className="font-normal">
												{app.createdAt ? format(new Date(app.createdAt), 'dd MMM yyyy') : ''}
											</Typography>
										</td>
										<td className={classes}>
											<Tooltip content="Edit Achievement">
												<NavLink to={`/dashboard/xapps/${app.externalApplicationId}`}>
													<IconButton variant="text">
														<PencilIcon className="h-4 w-4" />
													</IconButton>
												</NavLink>
											</Tooltip>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</CardBody>
				<CardFooter className="flex items-center justify-center border-t border-blue-gray-50 p-4">
					<div className="flex justify-center gap-8">
						<IconButton size="sm" variant="outlined" onClick={moreClick} disabled={!pageInfo.hasNextPage}>
							<ArrowDownIcon strokeWidth={2} className="h-4 w-4" />
						</IconButton>
					</div>
				</CardFooter>
			</Card>
		</>
	)
}

export default ExternalApplications
