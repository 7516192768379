import {
	ListPieWaitingListEdge,
	useAdminGetPieWaitingListUsersQuery,
	useAdminPassUserOverWaitingListMutation,
} from '@/graphql/generated/scheme'
import { ArrowDownIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/24/solid'
import {
	Card,
	CardHeader,
	Typography,
	CardBody,
	CardFooter,
	Avatar,
	IconButton,
	Spinner,
	Tooltip,
	Button,
	Alert,
} from '@material-tailwind/react'
import { format } from 'date-fns'
import isUrlHttp from 'is-url-http'
import { useEffect, useState } from 'react'

export function WaitingList() {
	const [users, setUsers] = useState<any>([])
	const [pageInfo, setPageInfo] = useState<any>(null)
	const [error, setError] = useState<string>('')
	const [success, setSuccess] = useState<string>('')

	const {
		data: dataUsers,
		loading: loadingUsers,
		refetch: refetchUsers,
	} = useAdminGetPieWaitingListUsersQuery({
		fetchPolicy: 'no-cache',
		variables: {
			first: 10,
			after: null,
		},
	})

	const [passUserOver, { loading: passingUserOver, error: passUserOverError, data: passUserOverData }] =
		useAdminPassUserOverWaitingListMutation()

	useEffect(() => {
		const usersData = dataUsers?.admin?.listPieWaitingList?.edges
		const pageInfoData = dataUsers?.admin?.listPieWaitingList?.pageInfo

		if (usersData) {
			setUsers([...users, ...usersData])
			setPageInfo(pageInfoData)
		}
	}, [dataUsers])

	useEffect(() => {
		if (passUserOverData?.admin.passedWaitingListUser) {
			setSuccess(`User is over waiting list`)
			setUsers([])
			refetchUsers({
				first: 10,
				after: null,
			})
		}
	}, [passUserOverData])

	useEffect(() => {
		if (passUserOverError) {
			setError(passUserOverError.message)
		}
	}, [passUserOverError])

	function resolveAvatarUrl(src: string) {
		let url = src
		if (!url) {
			url = '/img/default/avatar.png'
		} else {
			if (!isUrlHttp(url) && url.indexOf('data:') === -1) {
				url = `${process.env.REACT_APP_MEDIA_URL}/${src}`
			}
		}

		return url
	}

	if (loadingUsers || passingUserOver) {
		return (
			<div className="flex justify-center items-center py-4">
				<Spinner className="h-12 w-12" />
			</div>
		)
	}

	return (
		<Card className="h-full w-full">
			<CardHeader floated={false} shadow={false} className="rounded-none">
				<div className="flex items-center justify-between gap-8">
					<div>
						<Typography variant="h5" color="blue-gray">
							Waiting list
						</Typography>
						<Typography color="gray" className="mt-1 font-normal">
							See information about all users in waiting list
						</Typography>
					</div>
				</div>
			</CardHeader>
			<CardBody className="overflow-x-auto px-0">
				{(() => {
					if (error) {
						return (
							<div className="flex w-full flex-col gap-2 mb-2">
								<Alert color="red" onClose={() => setError('')}>
									{error}
								</Alert>
							</div>
						)
					}
					if (success) {
						return (
							<div className="flex w-full flex-col gap-2 mb-2">
								<Alert color="green" onClose={() => setSuccess('')}>
									{success}
								</Alert>
							</div>
						)
					}
				})()}
				<table className="mt-4 w-full min-w-max table-auto text-left">
					<thead>
						<tr>
							{[
								{ display: 'Name', key: 'name' },
								{ display: 'Username', key: 'username' },
								{ display: 'Gender', key: 'gender' },
								{ display: 'Phone Number', key: 'phone' },
								{ display: 'Is Verified', key: 'isVerified' },
								{ display: 'Tier', key: 'tier' },
								{ display: 'Wait From', key: 'addedAt' },
								{ display: 'Is Deleted', key: 'isDeleted' },
								{ display: '', key: 'actions' },
							].map(el => (
								<th key={el.key} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
									<Typography
										variant="small"
										color="blue-gray"
										className="font-normal leading-none opacity-70"
									>
										{el.display}
									</Typography>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{users.map((user: ListPieWaitingListEdge, index: number) => {
							const isLast = index === users.length - 1
							const classes = isLast ? 'p-4' : 'p-4 border-b border-blue-gray-50'

							return (
								<tr key={user.node?.user?.userId}>
									<td className={classes}>
										<div className="flex items-center gap-3">
											<Avatar
												src={resolveAvatarUrl(user.node?.user?.avatarUrl ?? '')}
												alt={user.node?.user?.name ?? ''}
												size="sm"
											/>
											<div className="flex flex-col">
												<Typography variant="small" color="blue-gray" className="font-normal">
													{user.node?.user?.name ?? ''}
												</Typography>
												<Typography
													variant="small"
													color="blue-gray"
													className="font-normal opacity-70"
												>
													{user.node?.user?.emailAddress ?? ''}
												</Typography>
											</div>
										</div>
									</td>
									<td className={classes}>
										<Typography variant="small" color="blue-gray" className="font-normal">
											{user.node?.user?.userName ? `@${user.node?.user?.userName}` : ''}
										</Typography>
									</td>
									<td className={classes}>
										<Typography variant="small" color="blue-gray" className="font-normal">
											{user.node?.user?.gender ?? ''}
										</Typography>
									</td>
									<td className={classes}>
										<Typography variant="small" color="blue-gray" className="font-normal">
											{user.node?.user?.phoneNumber ?? ''}
										</Typography>
									</td>
									<td className={classes}>
										<Typography variant="small" color="blue-gray" className="font-normal">
											{user.node?.user?.isVerified ? 'Yes' : 'No'}
										</Typography>
									</td>
									<td className={classes}>
										<Typography variant="small" color="blue-gray" className="font-normal">
											{user.node?.user?.tier ?? ''}
										</Typography>
									</td>
									<td className={classes}>
										<Typography variant="small" color="blue-gray" className="font-normal">
											{format(new Date(user.node?.addedAt), 'dd MMM yyyy')}
										</Typography>
									</td>
									<td className={classes}>
										<Typography variant="small" color="blue-gray" className="font-normal">
											{user.node?.user?.isDeleted ? 'Yes' : 'No'}
										</Typography>
									</td>
									<td className={classes}>
										<Tooltip content="Allow to create pie">
											<IconButton
												variant="text"
												onClick={() => {
													setSuccess('')
													setError('')
													passUserOver({
														variables: {
															userId: user.node?.user?.userId
														}
													})
												}}
											>
												<CheckIcon className="h-4 w-4" />
											</IconButton>
										</Tooltip>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</CardBody>
			<CardFooter className="flex items-center justify-center border-t border-blue-gray-50 p-4">
				<div className="flex justify-center gap-8">
					<Button
						variant="text"
						className="flex items-center gap-2"
						onClick={() => refetchUsers({ first: 10, after: pageInfo.endCursor })}
						disabled={!pageInfo?.hasNextPage}
					>
						Load more
						<ArrowDownIcon strokeWidth={2} className="h-4 w-4" />
					</Button>
				</div>
			</CardFooter>
		</Card>
	)
}

export default WaitingList
