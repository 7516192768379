import { ApolloError } from '@apollo/client'
import { ErrorCode, ERROR_MESSAGES } from './constants'

export const isErrorMessage = (errorCode: ErrorCode | string) => {
	return Object.keys(ERROR_MESSAGES).indexOf(errorCode) > -1
}

export const trueErrorMessage = (error: ErrorCode | string | ApolloError) => {
	let errorCode = ''
	if (error instanceof ApolloError) {
		if (error.message !== 'Response not successful: Received status code 500' && isErrorMessage(error.message)) {
			errorCode = error.message
		} else if ((error.networkError as any).result?.errors?.at(0)?.message) {
			return (error.networkError as any).result?.errors?.at(0)?.message
		} else {
			errorCode = error.message
		}
	}

	return isErrorMessage(errorCode) ? ERROR_MESSAGES[errorCode as ErrorCode] : errorCode
}
