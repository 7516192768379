import { LocationUnitLengthType } from '@/graphql/generated/scheme'
import { CountryRegionData } from 'react-country-region-selector'

export const ERROR_MESSAGES = {
	// User
	'400_User_000': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_User_001':
		'This user account combination does not exist. Please try logging in with a different account or trying a different password.',
	'400_User_002': 'This user account already exists. Please try logging in again or reset your password.',
	'400_User_003': 'This user account does not exist. Please try to log in again or sign up for a new account.',
	'400_User_004': 'Your log in information is not correct. Please try again or contact support.',
	'400_User_005':
		'Invalid username or password. Please try again. If you have forgotten your login credentials, please reset your password or contact support.',
	'400_User_006': "Looks like you've already verified your account. Please try logging in again.",
	'400_User_007': 'Your email could not be verified. Please enter a new email address',
	'400_User_008': 'Your amount could not be processed. Please enter a new amount.',
	'400_User_009': 'Insufficient funds in your wallet. Please add funds to your wallet.',
	'400_User_010': 'Your image does not meet our requirements. Please upload a picture with with a max size of 1MB.',
	'400_User_011': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_User_012': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_User_013': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_User_014': 'This user account does not exist. Please try to log in again or sign up for a new account.',
	'400_User_015':
		'Oops! Looks like something went wrong while uploading your photo. Please try again or contact support.',
	'400_User_016':
		'Hmmm... Looks like this account no longer exists. If you believe this message is in error please try again or contact support.',
	'400_User_017': 'Oops! Looks like something went wrong. Please try refreshing the page or contact support.',
	'400_User_018': 'Looks like this user already exists. Please choose a different user ID',
	'400_User_019': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_User_020': "Oops! Looks like the username doesn't exist. Please re-enter the user ID",
	'400_User_022': "Oops! It looks like you've already connect that account.",
	'400_User_023':
		'Looks like your email already used, please try to login with social network and set your new password or you can use Forgot password',
	'400_User_023_1': `Hmmm... Looks like you've already signed up with Facebook and you have not chosen a password yet. Please sign in again with Facebook and then set your password in your profile information.`,
	'400_User_023_2': `Hmmm... Looks like you've already signed up with Apple and you have not chosen a password yet. Please sign in again with Apple and then set your password in your profile information.`,
	'400_User_023_3': `Hmmm... Looks like you've already signed up with Google and you have not chosen a password yet. Please sign in again with Google and then set your password in your profile information.`,
	'400_User_023_4': `Hmmm... Looks like you've already signed up with Twitter and you have not chosen a password yet. Please sign in again with Twitter and then set your password in your profile information.`,
	'400_User_028':
		"It looks like you've click on your own invite link. Please try again with another link or contact support if you need further help.",
	'400_User_029':
		'It looks like your account is not active. Please try again or contact support if you need further help.',
	'400_User_030':
		'Looks like the invitation already accepted. Please try again with new invitation or contact support if you need further help.',
	'400_User_031':
		'Looks like the invitation already used. Please try again with new invitation or contact support if you need further help.',
	'400_User_032':
		'Looks like the invitation is expired. Please try again with new invitation or contact support if you need further help.',
	'400_User_033':
		'Looks like something went wrong with your invitation. Please try again with new invitation or contact support if you need further help.',
	'400_User_034': 'Looks like you have no invite. Please try again or contact support if you need further help.',
	'400_User_035': 'Looks like this user already exists. Please choose a different user email.',
	'401_User_000': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'401_User_001': 'This user account does not exist. Please try to log in again or sign up for a new account.',
	'401_User_002':
		'Invalid username or password, please try again. If you have forgotten your password you may reset your password by clicking on  “Forgot your password?”.',
	'401_User_003': "Looks like we didn't receive authorization from your social account. Please try logging in again.",
	'401_User_004': "Looks like you've been logged out. Please try logging in again.",
	'401_User_005': 'Your log in information is not correct. Please try logging in again or reseting your password.',
	'401_User_006': "Looks like you've been logged out. Please try logging in again.",
	'401_User_007': 'This account does not exist. Please try to log in again or sign up for w new account.',
	'403_User_000': 'Oops! Looks like something went wrong. Please try again or contact support.',
	// Pie
	'400_Pie_000': "Looks like you've been logged out. Please try logging in again.",
	'400_Pie_001': 'Looks like we cant locate this pie or this pie doesnt exist. Please try again or contact support.',
	'400_Pie_002': "Oops, looks like you've lost connection. Please refresh the page.",
	'400_Pie_003': 'Looks like we cant locate this pie or this pie doesnt exist. Please try again or contact support.',
	'400_Pie_004': 'Looks like this URL already is taken. Please try using a different URL.',
	'400_Pie_005': 'Looks like you have insufficient funds for this transaction. Please add funds in your wallet.',
	'400_Pie_006':
		"Looks like you don't own enough slices to access this reward. Please buy more slices to access this reward",
	'400_Pie_007': 'Looks your Pie slices for sell over 50%, please update it again as below 50%.',
	'400_Pie_008':
		'Looks like you havent entered an acceptable value of slices. Please enter how many slices you would like to make available for sale.',
	'400_Pie_009': 'Ooops, you must set a value greater than $0 as a price per slice.',
	'400_Pie_010': 'Oops, you must choose a number greater than 0 slices to make available for sale.',
	'400_Pie_011':
		'Hmmm... Looks like that Pie no longer exists. If you believe this message is in error please try again or contact support.',
	'400_Pie_012':
		'Hmmm... Looks like that Pie no longer exists. If you believe this message is in error please try again or contact support.',
	'400_Pie_013': 'Oops! Looks like something went wrong. Please try refreshing the page or contact support.',
	'400_Pie_014':
		'Oops! Looks like we ran into an error. Please try uploading your photo again. If the problem persists please contact support',
	'400_Pie_015':
		'Oops! Looks like we ran into an error. Please try again. If the problem persists please contact support',
	'400_Pie_016': 'Oops! Looks like something went wrong. Please try refreshing the page or contact support.',
	'400_Pie_017': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'403_Pie_000': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'403_Pie_001':
		'Hmmm... Looks like that Pie no longer exists. If you believe this message is in error please try again or contact support.',
	// Reward
	'400_Reward_000': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Reward_001': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Reward_002': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Reward_003': "Looks like we can't locate this reward. Try searching again or contacting support.",
	'400_Reward_004': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Reward_005':
		'Hmmm... Looks like that Reward no longer exists. If you believe this message is in error please try again or contact support.',
	'400_Reward_006':
		'Hmmm... Looks like something went wrong. If you believe this message is in error please try again or contact support.',
	'400_Reward_007':
		'Hmmm... Looks like that reward no longer exists. If you believe this message is in error please try again or contact support.',
	'400_Reward_008': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Reward_009':
		'Hmmm... Looks like that reward no longer exists. If you believe this message is in error please try again or contact support.',
	'400_Reward_010': "Hmmm... Looks like that username doesn't exists. Please try a differnt username ",
	'400_Reward_011': 'Hmm. Looks like you are not the owner of this reward. Please contact support for assitance.',
	'400_Reward_012': 'Looks like you already have this reward. Plese refresh the page or contact support',
	'403_Reward_000': 'Oops! Looks like something went wrong. Please try again or contact support.',
	// Achievement
	'400_Achievement_000': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Achievement_001': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Achievement_002': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Achievement_003': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Achievement_004':
		'Hmmm... Looks like that Achievement no longer exists. If you believe this message is in error please try again or contact support.',
	'400_Achievement_005':
		'Hmmm... Looks like that Achievement no longer exists. If you believe this message is in error please try again or contact support.',
	'400_Achievement_006': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'403_Achievement_000': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'403_Achievement_001':
		'Oops! Looks like we ran into an error. Please try again. If the problem persists please contact support',
	'403_Achievement_002':
		'Hmm , it looks like you are not the owner of this achievment. Please contact support for assistance',
	// Wallet
	'400_Wallet_000': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Wallet_001': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Wallet_002': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Wallet_003': 'Looks like there is an error. Try refreshing your page.',
	'400_Wallet_004': 'Looks like you have insufficient funds. Try adding more funds to your wallet',
	// Payment
	'400_Payment_000': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_001': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_002': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_003': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_004': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_005':
		'Oops! Looks like something went wrong. Please try entering a correct user ID or contact support.',
	'400_Payment_006':
		'Hmmm... Looks like that Pie no longer exists. If you believe this message is in error please try again or contact support.',
	'400_Payment_007': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_008': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_009': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_010': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_011': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_012': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_013': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_014': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_015': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_016': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_017': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_018':
		'Looks like that Order has been processing. If you believe this message is in error please try again or contact support.',
	'400_Payment_019': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_020': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_021': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_022':
		'Hmmm... Looks like that Order no longer exists. If you believe this message is in error please try again or contact support.',
	'400_Payment_023':
		'Hmmm... Looks like that Order has been cancelled. If you believe this message is in error please try again or contact support.',
	'400_Payment_024': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payment_025': 'Oops! Looks like something went wrong. Please try again or contact support.',
	// Payout
	'400_Payout_000': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'400_Payout_001': 'Hmm. Looks like your transaction failed. Please try the transaction again or contact support.',
	'400_Payout_002': 'Hmm. Looks like your transaction failed. Please try the transaction again or contact support.',
	'400_Payout_003': 'Hmm. Looks like your transaction failed. Please try the transaction again or contact support.',
	// Transaction
	'400_Transaction_000':
		'Hmm. Looks like your transaction failed. Please try the transaction again or contact support.',
	'400_Transaction_001':
		'Hmm. Looks like your transaction failed. Please try the transaction again or contact support.',
	// System
	'500_000': 'Looks like we are having some technical difficulties. Please try logging out and signing in again.',
	// Global
	'400_Global_000': 'Oops! Looks like something went wrong. Please try again or contact support.',
	'Response not successful: Received status code 500':
		'Oops! Looks like something went wrong. Please try again. If the problem persists please contact support.',
}

export type ErrorCode = keyof typeof ERROR_MESSAGES

export const DEFAULT_INFORMATION = {
	avatarImageUrl: '/img/default/avatar.png',
	name: 'Unknown',
	rank: 0,
	iconPie: '/img/common/pie-card/image-pie-1.jpg',
	iconAssets: [
		'/img/common/pie-image/icon-1.svg',
		'/img/common/pie-image/icon-2.svg',
		'/img/common/pie-image/icon-3.svg',
		'/img/common/pie-image/icon-4.svg',
		'/img/common/pie-image/icon-5.svg',
		'/img/common/pie-image/icon-6.svg',
		'/img/common/pie-image/icon-7.svg',
	],
	rewardImageUrl: '/img/common/reward-1.jpg',
	pieImageUrl: '/img/default/pie.png',
}

export const DEFAULT_EXTENDED_MINUTES = 15

export const EXCLUDE_COUNTRIES = ['kp', 'cu', 'ir', 'sy', 've', 'ru']

export const COUNTRY_DATA = CountryRegionData.filter(
	(item: any) => !EXCLUDE_COUNTRIES.includes(item[1].toLowerCase())
).map(item => ({ name: item[0], value: item[1] }))

export const MEDIA_FOLDER = {
	rewardAvatars: 'reward-avatars',
	achievementAvatars: 'achievement-avatars',
}

export const DEFAULT_MAP_COORDS = {
	lat: 34.052235,
	lng: -118.243683,
}

export const MEASURING_LENGTH_UNITS = [
	{ name: 'mi', value: LocationUnitLengthType.Miles },
	{ name: 'km', value: LocationUnitLengthType.Kilometers },
]