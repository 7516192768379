import { trueErrorMessage } from '@/utils/error'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
	Alert,
	Button,
	Card,
	CardBody,
	Input,
	Spinner,
	Typography,
	Textarea,
	IconButton,
	Dialog,
	DialogBody,
	DialogFooter,
	DialogHeader,
	Checkbox,
} from '@material-tailwind/react'
import { ErrorMessage } from '@hookform/error-message'
import {
	ExternalApplication,
	useDisableExternalApplicationMutation,
	useEnableExternalApplicationMutation,
	useGenerateExternalAppTokenMutation,
	useGetExternalApplicationByIdLazyQuery,
	useRemoveExternalAppTokenMutation,
	useUpsertExternalAppMutation,
} from '@/graphql/generated/scheme'
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid'
import DateTimePicker from 'react-datetime-picker'
import { format } from 'date-fns'

export default function ExternalApplicationPage() {
	//#region setup
	const xAppSchema = Yup.object().shape({
		name: Yup.string().required('Name is required.'),
		description: Yup.string().required('Description is required.'),
	})

	const tokenSchema = Yup.object().shape({
		scopes: Yup.array().of(Yup.string()).required('Scope is required').min(1, 'Please select at least one scope'),
		expiresAt: Yup.date().required('Expires at is required.').min(new Date(), 'Expires at must be in future'),
	})

	const SCOPE_OPTIONS = [{ name: 'Read', value: 'read' }]
	//#endregion setup

	//#region hooks
	const [app, setApp] = useState<ExternalApplication>()
	const [appId, setAppId] = useState<string | undefined>()
	const [updating, setUpdating] = useState<boolean>(false)
	const [confirmingStatus, setConfirmingStatus] = useState<boolean>(false)
	const [confirmingDeleteToken, setConfirmingDeleteToken] = useState<boolean>(false)
	const [deletingToken, setDeletingToken] = useState<string>('')
	const [addingNewToken, setAddingNewToken] = useState<boolean>(false)
	const [error, setError] = useState<string>('')
	const [success, setSuccess] = useState<string>('')
	const navigate = useNavigate()

	const {
		control: controlApp,
		formState: { errors, isValid: isValidForm },
		reset: resetApp,
		watch: watchApp,
	} = useForm({
		resolver: yupResolver(xAppSchema),
		mode: 'onChange',
	})

	const {
		control: controlToken,
		formState: { errors: tokenErrors, isValid: isValidTokenForm },
		reset: resetToken,
		watch: watchToken,
	} = useForm({
		resolver: yupResolver(tokenSchema),
		mode: 'onChange',
	})

	const params = useParams()
	const aid = params.id
	let isNew = false
	if (aid == undefined) {
		isNew = true
	} else {
		useEffect(() => {
			setAppId(aid)
		}, [])
	}

	const [upsertApp, {}] = useUpsertExternalAppMutation({
		onCompleted: () => {
			toast.success(`External app has been saved.`)
			backToExternalAppsList()
		},
		onError: error => {
			toast.error(trueErrorMessage(error))
			setUpdating(false)
		},
	})

	const [disableApp, { loading: loadingDisable }] = useDisableExternalApplicationMutation({
		onCompleted: () => {
			toast.success('External app has been disabled')
			refetchApp()
		},
		onError: error => {
			toast.error(trueErrorMessage(error))
		},
	})

	const [enableApp, { loading: loadingEnable }] = useEnableExternalApplicationMutation({
		onCompleted: () => {
			toast.success('External app has been enabled')
			refetchApp()
		},
		onError: error => {
			toast.error(trueErrorMessage(error))
		},
	})

	const [generateToken, { loading: loadingGenerateToken }] = useGenerateExternalAppTokenMutation({
		onCompleted: () => {
			toast.success('New token generated')
			refetchApp()
		},
		onError: error => {
			toast.error(trueErrorMessage(error))
		},
	})

	const [removeToken, { loading: loadingRemoveToken }] = useRemoveExternalAppTokenMutation({
		onCompleted: () => {
			toast.success('Token was removed')
			refetchApp()
		},
		onError: error => {
			toast.error(trueErrorMessage(error))
		},
	})

	const [getAppQuery, { data: dataApp, loading: loadingApp, error: loadAppError, refetch: refetchApp }] =
		useGetExternalApplicationByIdLazyQuery()

	useEffect(() => {
		if (appId) {
			getAppQuery({
				variables: {
					id: appId,
				},
			})
		}
	}, [appId])

	useEffect(() => {
		const appData = dataApp?.admin?.externalApplication?.byId

		if (appData) {
			setApp({ ...appData } as ExternalApplication)
			resetApp({
				name: appData.name,
				description: appData.description ?? '',
			})
		} else if (!loadingApp === false) {
			backToExternalAppsList()
		}
	}, [dataApp])
	//#endregion hooks

	//#region functions
	const backToExternalAppsList = () => {
		navigate('/dashboard/xapps')
	}

	const save = () => {
		setError('')
		setSuccess('')

		if (!isValidForm || JSON.stringify(errors) !== '{}') {
			setError('Please solve all validation errors')
			return
		}

		upsertApp({
			variables: {
				id: appId,
				args: {
					name: watchApp('name'),
					description: watchApp('description'),
				},
			},
		})

		setUpdating(true)
	}

	const confirmedStatusChange = () => {
		if (app?.isDisabled) {
			enableApp({
				variables: {
					id: appId,
				},
			})
		} else {
			disableApp({
				variables: {
					id: appId,
				},
			})
		}
	}

	const confirmGenerateToken = () => {
		if (isValidTokenForm) {
			generateToken({
				variables: {
					id: appId,
					args: {
						expiresAt: watchToken('expiresAt'),
						scopes: watchToken('scopes') as string[],
					},
				},
			})
		}
	}

	const confirmDeleteToken = () => {
		if (deletingToken) {
			removeToken({
				variables: {
					id: appId,
					token: deletingToken,
				},
			})
			setDeletingToken('')
		}
	}

	//#endregion functions
	if (!isNew && (aid == undefined || !aid.length)) {
		backToExternalAppsList()
	}

	if (loadAppError) {
		return (
			<div className="py-4">
				<div className="flex justify-center items-center py-4">
					<Typography color="red">
						Could not load external app information due to server error. Please try again.
					</Typography>
				</div>
				<div className="flex justify-center items-center py-4">
					<NavLink to={'/dashboard/xapps'}>
						<Button variant="outlined" className="flex items-center gap-3">
							Back to External Apps list
						</Button>
					</NavLink>
				</div>
			</div>
		)
	}

	return (
		<>
			{(loadingApp ||
				(!app && !isNew) ||
				updating ||
				loadingDisable ||
				loadingEnable ||
				loadingGenerateToken ||
				loadingRemoveToken) && (
				<div className="absolute inset-0 flex justify-center items-center z-10 bg-gray-400/75">
					<Spinner className="h-12 w-12" />
				</div>
			)}
			<Dialog open={confirmingStatus || addingNewToken || confirmingDeleteToken} handler={() => {}}>
				<DialogHeader>{confirmingStatus ? 'Confirm status change' : 'Add new token'}</DialogHeader>
				<DialogBody divider>
					{confirmingStatus && (
						<Typography variant="h5" color="blue-gray">
							Are you sure to {app?.isDisabled ? 'ENABLE' : 'DISABLE'} this external application?
						</Typography>
					)}
					{confirmingDeleteToken && (
						<Typography variant="h5" color="blue-gray">
							Are you sure to delete the token?
						</Typography>
					)}
					{addingNewToken && (
						<div className="flex flex-col gap-6">
							<Typography variant="h6" color="blue-gray" className="-mb-3 flex flex-row">
								Scopes
							</Typography>
							<Controller
								control={controlToken}
								name="scopes"
								render={({ field }) => (
									<div>
										{SCOPE_OPTIONS.map(opt => (
											<Checkbox
												key={opt.value}
												label={opt.name}
												color="blue"
												defaultChecked={field.value?.some(v => v === opt.value)}
												onChange={event => {
													if (event.target.checked) {
														field.onChange([...(field.value ?? []), opt.value])
													} else {
														field.onChange([
															...(field.value ?? []).filter(s => s !== opt.value),
														])
													}
												}}
												crossOrigin={undefined}
											/>
										))}
									</div>
								)}
							/>
							<ErrorMessage
								errors={tokenErrors}
								name="scopes"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3 flex flex-row">
								Expires at
							</Typography>
							<Controller
								control={controlToken}
								name="expiresAt"
								render={({ field }) => (
									<div className="custom-react-datetime-picker">
										<DateTimePicker
											className="px-4 py-3 peer w-full h-10 bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border text-sm rounded-[7px] border-blue-gray-200 placeholder-white-50 placeholder:italic placeholder:font-light flex items-center"
											value={field.value}
											onChange={field.onChange}
											format="MM/dd/yyyy h:mm aa"
											monthPlaceholder="mm"
											dayPlaceholder="dd"
											yearPlaceholder="yyyy"
											hourPlaceholder="h"
											minutePlaceholder="mm"
											calendarIcon={null}
											clearIcon={null}
											disableClock={true}
											calendarType="gregory"
										/>
									</div>
								)}
							/>
							<ErrorMessage
								errors={tokenErrors}
								name="expiresAt"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
						</div>
					)}
				</DialogBody>
				<DialogFooter>
					<Button
						variant="text"
						color="red"
						onClick={() => {
							setConfirmingStatus(false)
							setAddingNewToken(false)
						}}
						className="mr-1"
					>
						<span>Cancel</span>
					</Button>
					<Button
						variant="gradient"
						color="green"
						disabled={confirmingStatus || confirmingDeleteToken ? false : !isValidTokenForm}
						onClick={() => {
							if (confirmingStatus) {
								confirmedStatusChange()
								setConfirmingStatus(false)
							} else if (addingNewToken) {
								confirmGenerateToken()
								setAddingNewToken(false)
							} else if (confirmingDeleteToken) {
								confirmDeleteToken()
								setConfirmingDeleteToken(false)
							}
						}}
					>
						<span>Confirm</span>
					</Button>
				</DialogFooter>
			</Dialog>
			<Card className="mb-6">
				<CardBody className="p-4">
					{(() => {
						if (error) {
							return (
								<div className="flex w-full flex-col gap-2 mb-2">
									<Alert color="red" onClose={() => setError('')}>
										{error}
									</Alert>
								</div>
							)
						}
						if (success) {
							return (
								<div className="flex w-full flex-col gap-2 mb-2">
									<Alert color="green" onClose={() => setSuccess('')}>
										{success}
									</Alert>
								</div>
							)
						}
					})()}
					<form className="mt-2 mb-2 w-80 max-w-screen-lg sm:w-96">
						<div className="mb-1 flex flex-col gap-6">
							<Typography variant="h5" color="blue-gray" className="-mb-3">
								External App Details
							</Typography>
							<Typography variant="h6" color="blue-gray" className="-mb-3 flex flex-row">
								Name
							</Typography>
							<Controller
								control={controlApp}
								name="name"
								render={({ field }) => (
									<Input
										crossOrigin={undefined}
										size="lg"
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										onChange={field.onChange}
										value={field.value ?? ''}
									/>
								)}
							/>
							<ErrorMessage
								errors={errors}
								name="name"
								render={({ message }) => (
									<Typography variant="small" color="red" className="-mt-3 -mb-3">
										{message}
									</Typography>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Description
							</Typography>
							<Controller
								control={controlApp}
								name="description"
								render={({ field }) => (
									<Textarea
										className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
										labelProps={{
											className: 'before:content-none after:content-none',
										}}
										value={field.value}
										onChange={field.onChange}
									/>
								)}
							/>
							<Typography variant="h6" color="blue-gray" className="-mb-3">
								Status
							</Typography>
							<div>
								<Button
									variant="filled"
									color={app?.isDisabled ? 'amber' : 'green'}
									onClick={() => setConfirmingStatus(true)}
								>
									{app?.isDisabled ? 'DISABLED' : 'ENABLED'}
								</Button>
							</div>
							{appId && (
								<Typography variant="h5" color="blue-gray" className="-mb-3">
									<span>Tokens</span>
									<IconButton
										variant="filled"
										className="rounded-full ml-5"
										disabled={app?.tokens.length == 10}
										onClick={() => {
											resetToken()
											setAddingNewToken(true)
										}}
									>
										<PlusIcon className="h-4 w-4" />
									</IconButton>
								</Typography>
							)}
							{app?.tokens.map((token, index) => (
								<div key={index} className="flex flex-col gap-6">
									{index === 0 && <hr />}
									<div className="flex flex-row">
										<Input
											crossOrigin={undefined}
											size="lg"
											className="!bg-gray-100"
											label="Access Token"
											readOnly={true}
											value={token.value.token}
										/>
										<IconButton
											color="deep-orange"
											variant="filled"
											className="ml-2 h-11 max-h-11 max-w-[44px] w-[51px]"
											onClick={() => {
												setDeletingToken(token.key)
												setConfirmingDeleteToken(true)
											}}
										>
											<MinusIcon className="h-5 w-5" />
										</IconButton>
									</div>

									<Input
										crossOrigin={undefined}
										size="lg"
										className="!bg-gray-100"
										label="Scopes"
										readOnly={true}
										value={token.value.scopes.join(', ')}
									/>
									<Input
										crossOrigin={undefined}
										size="lg"
										className="!bg-gray-100"
										label="Expires at"
										readOnly={true}
										value={format(new Date(token.value.expiresAt), 'dd/MM/yyyy HH:mm:ss')}
									/>
									<hr />
								</div>
							))}
						</div>
						<Button
							className="mt-6"
							fullWidth
							onClick={save}
							disabled={!isValidForm || JSON.stringify(errors) !== '{}'}
						>
							Save
						</Button>
					</form>
				</CardBody>
			</Card>
		</>
	)
}
